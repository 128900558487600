import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import i_icon from "../../Assets/images/i-icon.svg";
import clock_icon from "../../Assets/images/clock.svg";
import truck_icon from "../../Assets/images/truck-icon.svg";
import arrow from "../../Assets/images/arrow-up-icon.svg";
import LandingNavbar from "../../Components/LandingNavbar";
import LandingFooter from "../../Components/LandingFooter";

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const getFaqs = () => {
      axios
        .get(`${backendServerBaseURL}/landing`)
        .then((data) => {
          setFaqs(data.data.payload.FAQ);
        })
        .catch((error) => console.log(error));
    };

    getFaqs();
  }, []);
  console.log(faqs);

  return (
    <div>
      {/* header section start */}
      <LandingNavbar />
      {/* header section end */}
      {/* contact section start */}
      <section className="contact-section pa-y4">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h6>FAQs</h6>
                <h2 style={{ fontSize: "3.2rem" }}>
                  Frequently Asked Questions
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas quis nulla quis nibh laoreet dapibus.
                </p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="faq-ask-wrap">
                {faqs.length &&
                  faqs
                    .filter((item) => item.type === "General Information")
                    .map((item) => (
                      <>
                        <div className="faq-info">
                          <h6>
                            <img src={i_icon} alt="a" className="img-fluid" />{" "}
                            General Information
                          </h6>
                        </div>
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingOne"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                {item.question}
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                {faqs.length &&
                  faqs
                    .filter((item) => item.type === "Pricing")
                    .map((item) => (
                      <>
                        <div className="faq-info">
                          <h6>
                            <img
                              src={clock_icon}
                              alt="a"
                              className="img-fluid"
                            />{" "}
                            Pricing
                          </h6>
                        </div>
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingThrees"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThrees"
                                aria-expanded="false"
                                aria-controls="flush-collapseThrees"
                              >
                                {item.question}
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThrees"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingThrees"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                {faqs.length &&
                  faqs
                    .filter((item) => item.type === "Proxy Delivery")
                    .map((item) => (
                      <>
                        <div className="faq-info">
                          <h6>
                            <img
                              src={truck_icon}
                              alt="a"
                              className="img-fluid"
                            />
                            Proxy Delivery
                          </h6>
                        </div>
                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExamplea"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="flush-headingThreesas"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThreesas"
                                aria-expanded="false"
                                aria-controls="flush-collapseThreesas"
                              >
                                {item.question}
                              </button>
                            </h2>
                            <div
                              id="flush-collapseThreesas"
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingThreesas"
                              data-bs-parent="#accordionFlushExamplea"
                            >
                              <div className="accordion-body">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact section end */}
      {/* get start section start */}
      <section className="get-start py-4 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 col-sm-11 col-12">
              <div className="get-start-bg">
                <div className="common-heading mb-0">
                  <h2>Got Questions? Let’s Talk</h2>
                  <p>
                    In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                    odio ultricies eget. Praesent laoreet libero ac lorem dictum
                    viverra. Donec porta lectus mi, sed maximus orci pulvinar
                    vel.
                  </p>
                  <Link to="/contact-us">
                    Contact Us <img src={arrow} alt="a" className="img-fluid" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get start section end */}
      {/* footer section start */}
      <LandingFooter />
      {/* footer section end */}
    </div>
  );
};

export default Faqs;
