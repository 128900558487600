import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  deleteAccount,
  getAllAccounts,
  selectAccounts,
  selectAccountsPager,
  updateAccountSearch,
} from "../../../redux/slices/adminSlice";
import { formatTime } from "../../../utils/formatTime";
import Sidebar from "../../../Components/sidebar/Sidebar";
import NavbarComponent from "../../../Components/navbar/Navbar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import LoadingButton from "../../../Components/common/LoadingButton";
import DeleteAccountDialog from "./DeleteAccountDialog";

const Accounts = () => {
  const allAccounts = useSelector(selectAccounts);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pager = useSelector(selectAccountsPager);
  const [accountToDelete, setaccountToDelete] = useState(0);

  useEffect(() => {
    dispatch(getAllAccounts({ page: 1 }));
    dispatch(updateAccountSearch(""));
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <div className="my-4 mx-3 w-100" style={{ background: "#027EF80D" }}>
          <div className="card w-100">
            <div className=" table-responsive datatable-custom">
              <div
                className="col-12 d-flex align-items-center justify-content-between"
                style={{ padding: "1.5rem", paddingBottom: 0 }}
              >
                <div className="col-6 d-flex align-items-center">
                  <h1 style={{ marginTop: 8 }}>All Accounts</h1>
                </div>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    navigate("/admin/accounts/new");
                  }}
                >
                  Create New Account
                </button>
              </div>

              <div style={{ padding: 16 }}>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Search by ID, email, username"
                  onChange={(e) => {
                    dispatch(updateAccountSearch(e.target.value));
                    dispatch(getAllAccounts({ page: 1 }));
                  }}
                />
              </div>

              <table
                id="datatable"
                className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
              >
                <thead className="thead-light">
                  <tr>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Balance</th>
                    <th style={{ whiteSpace: "nowrap" }}>Total Spent</th>
                    <th>Role</th>
                    <th>2FA</th>
                    <th style={{ whiteSpace: "nowrap" }}>Registered On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allAccounts.map((account) => {
                    return (
                      <tr key={account._id}>
                        <td>{account.username}</td>
                        <td>{account.email}</td>
                        <td className="text-success">
                          $
                          {account.balance
                            ? Math.round(account.balance * 100) / 100
                            : 0}
                        </td>
                        <td className="text-success">
                          $
                          {account.totalSpent
                            ? Math.round(account.totalSpent * 100) / 100
                            : 0}
                        </td>
                        <td>
                          {account.role === "Administrator" && (
                            <>
                              <span className="legend-indicator bg-success" />{" "}
                              <span className="text-success">
                                Administrator
                              </span>
                            </>
                          )}
                          {account.role === "Manager" && (
                            <>
                              <span className="legend-indicator bg-info" />{" "}
                              <span className="text-info">Manager</span>
                            </>
                          )}
                          {account.role === "User" &&
                            account.reseller === false && (
                              <>
                                <span className="legend-indicator bg-primary" />{" "}
                                <span className="text-primary">User</span>
                              </>
                            )}
                          {account.role === "User" &&
                            account.reseller === true && (
                              <>
                                <span className="legend-indicator bg-primary" />{" "}
                                <span className="text-primary">Reseller</span>
                              </>
                            )}
                        </td>
                        <td>
                          {account.twoFactorEnabled ? (
                            <span className="legend-indicator bg-success" />
                          ) : (
                            <span className="legend-indicator bg-secondary" />
                          )}
                          {account.twoFactorEnabled ? "Enabled" : "Disabled"}
                        </td>

                        <td>{formatTime(account.createdAt)}</td>

                        <td>
                          <i
                            data-tip
                            data-for="viewBtn"
                            className="bi bi-eye-fill icon-button text-primary"
                            style={{
                              marginLeft: 0,
                              marginRight: "0.7rem",
                              cursor: "pointer",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => {
                              navigate(`/admin/accounts/${account._id}`);
                            }}
                          ></i>
                          <ReactTooltip
                            id="viewBtn"
                            effect="float"
                            delayShow={400}
                          >
                            <span>View</span>
                          </ReactTooltip>

                          <i
                            data-tip
                            data-for="editBtn"
                            className="bi bi-pencil-fill icon-button text-warning"
                            style={{
                              marginRight: "0.7rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(`/admin/accounts/${account._id}/edit`);
                            }}
                          ></i>
                          <ReactTooltip
                            id="editBtn"
                            effect="float"
                            delayShow={400}
                          >
                            <span>Edit</span>
                          </ReactTooltip>

                          <i
                            data-tip
                            data-for="balanceBtn"
                            className="bi bi-currency-exchange icon-button text-success"
                            style={{
                              marginRight: "0.7rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(
                                `/admin/accounts/${account._id}/balance`
                              );
                            }}
                          ></i>
                          <ReactTooltip
                            id="balanceBtn"
                            effect="float"
                            delayShow={400}
                          >
                            <span>Balance</span>
                          </ReactTooltip>

                          <i
                            data-tip
                            data-for="deleteBtn"
                            className="bi bi-trash-fill icon-button text-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#deletePlanModal"
                            onClick={() => {
                              setaccountToDelete(account);
                            }}
                          ></i>
                          <ReactTooltip
                            id="deleteBtn"
                            effect="float"
                            delayShow={400}
                          >
                            <span>Delete</span>
                          </ReactTooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* {pager?.total > 1 && ( */}
              <div
                className="container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 20,
                  border: "solid",
                  borderWidth: "0px",
                  borderTopWidth: "0.1px",
                  borderColor: "var(--bs-gray-300)",
                }}
              >
                <nav>
                  <ul className="pagination" style={{ margin: 0 }}>
                    <li
                      className="page-item"
                      style={{
                        margin: "0px",
                        border: "solid",
                        paddingTop: "2px",
                        borderWidth: "0.2px",
                        borderColor: "var(--bs-gray-400)",
                        borderTopLeftRadius: 6,
                        borderBottomLeftRadius: 6,
                      }}
                      // onClick={() => {
                      //   dispatch(
                      //     getAllAccounts({
                      //       page: pager.page <= 1 ? 1 : pager.page - 1,
                      //     })
                      //   );
                      // }}
                    >
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true" style={{ fontSize: "1.3rem" }}>
                          &laquo;
                        </span>
                      </a>
                    </li>

                    <li
                      className="page-item"
                      style={{
                        padding: 15,
                        display: "flex",
                        alignItems: "center",
                        margin: "0px",
                        border: "solid",
                        borderWidth: "0px",
                        borderBottomWidth: "0.2px",
                        borderTopWidth: "0.2px",
                        borderColor: "var(--bs-gray-400)",
                      }}
                    >
                      1{/* {pager?.page} of {pager?.total} */}
                    </li>

                    <li
                      className="page-item"
                      style={{
                        margin: "0px",
                        border: "solid",
                        paddingTop: "2px",
                        borderWidth: "0.2px",
                        borderColor: "var(--bs-gray-400)",
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                      // onClick={() => {
                      //   dispatch(
                      //     getAllAccounts({
                      //       page:
                      //         pager.page >= pager.total
                      //           ? pager.total
                      //           : pager.page + 1,
                      //     })
                      //   );
                      // }}
                    >
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true" style={{ fontSize: "1.3rem" }}>
                          &raquo;
                        </span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <DeleteAccountDialog accountToDelete={accountToDelete} />{" "}
    </div>
  );
};

export default Accounts;
