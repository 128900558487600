import { Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as Yup from "yup";
import AlertCard from "../../Components/AlertCard";
import LoadingButton from "../../Components/common/LoadingButton";
import NavbarComponent from "../../Components/navbar/Navbar";
import { getAllSettings, selectPlanToEdit, selectSettings, updateSettings } from "../../redux/slices/adminSlice";
import Sidebar from "../../Components/sidebar/Sidebar";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Settings() {
  const initialValue = [];
  const notify = () =>
    toast.success("Settings updated successfully", {
      theme: "dark",
    });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const planToEdit = useSelector(selectPlanToEdit);
  const settings = useSelector(selectSettings);
  let [settingsNamesArr, setSettingsNamesArr] = useState([]);
  const [valuesLoaded, setvaluesLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllSettings());
  }, []);

  const RegisterSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required"),
    Description: Yup.string().required("Description is required"),
    Copyright: Yup.string().required("Copyright is required"),

    TOS: Yup.string().required("Terms Of Service is required"),
    Privacy: Yup.string().required("Privacy is required"),
    Suggestions: Yup.string().required("Suggestions is required"),

    FAQ: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required(),
        answer: Yup.string().required(),
      })
    ),

    Status: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(),
        value: Yup.string().default("Operational").required(),
      })
    ),

    BannerEnabled: Yup.boolean().required("Banner Enabled is required"),
    BannerTitle: Yup.string().required("Banner Title is required"),
    BannerBody: Yup.string(),
    BannerStyle: Yup.string().required("Banner Style is required"),

    PopupEnabled: Yup.boolean().required("Popup Enabled is required"),
    PopupBody: Yup.string().required("Popup Body is required"),

    CustomEnable: Yup.boolean().required("Custom Enabled is required"),
    CustomThreadsMax: Yup.number().required("Custom Threads Max is required"),
    CustomThreadsMin: Yup.number().required("Custom Threads Min is required"),
    CustomPrices: Yup.array().of(
      Yup.object().shape({
        from: Yup.number().required(),
        to: Yup.number().required(),
        price: Yup.number().required(),
      })
    ),
    CustomBandwidthMin: Yup.number().required("Custom Bandwidth Min is required"),
    CustomBandwidthMax: Yup.number().required("Custom Bandwidth Max is required"),
    CustomBandwidthPrices: Yup.array().of(
      Yup.object().shape({
        from: Yup.number().required(),
        to: Yup.number().required(),
        price: Yup.number().required(),
      })
    ),
    CustomResidentialDays: Yup.number().required("Custom Residential Days is required"),
    ResidentialDescription: Yup.string().required("Residential Description is required"),
    DatacenterDescription: Yup.string().required("Datacenter Description is required"),

    minRenewDays: Yup.string().required("Minimum renew days are required"),
    maxRenewDays: Yup.string().required("Maximum renew days are required"),

    RenewThreadsMin: Yup.number().required("Minimum threads are required"),
    RenewThreadsMax: Yup.number().required("Maximum threads are required"),
    RenewThreadsPrices: Yup.array().of(
      Yup.object().shape({
        from: Yup.number().required(),
        to: Yup.number().required(),
        price: Yup.number().required(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      Description: "",
      Copyright: "",

      TOS: "",
      Privacy: "",
      Suggestions: "",

      FAQ: [""],

      Status: [""],

      BannerEnabled: false,
      BannerTitle: "",
      BannerBody: "",
      BannerStyle: "success",

      PopupEnabled: false,
      PopupBody: "",

      CustomEnable: false,
      CustomThreadsMax: 0,
      CustomThreadsMin: 0,
      CustomPrices: [""],
      CustomBandwidthMin: 0,
      CustomBandwidthMax: 0,
      CustomBandwidthPrices: [""],
      CustomResidentialDays: 0,
      ResidentialDescription: "",
      DatacenterDescription: "",

      minRenewDays: 0,
      maxRenewDays: 0,

      RenewThreadsMin: 0,
      RenewThreadsMax: 0,
      RenewThreadsPrices: [],
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);

      setSubmitting(true);
      await dispatch(updateSettings({ ...values, notify }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues, setFieldValue } = formik;

  useEffect(() => {
    if (settings !== null) {
      setSettingsNamesArr(settings.map((set) => set.name));
    }
  }, [settings]);

  useEffect(() => {
    if (settingsNamesArr.length !== 0) {
      setValues({
        Name: settings[settingsNamesArr.indexOf("Name")].value,
        Description: settings[settingsNamesArr.indexOf("Description")].value,
        Copyright: settings[settingsNamesArr.indexOf("Copyright")].value,

        TOS: settings[settingsNamesArr.indexOf("TOS")].value,
        Privacy: settings[settingsNamesArr.indexOf("Privacy")].value,
        Suggestions: settings[settingsNamesArr.indexOf("Suggestions")].value,

        FAQ: settings[settingsNamesArr.indexOf("FAQ")].value,

        Status: settings[settingsNamesArr.indexOf("Status")].value,

        BannerEnabled: settings[settingsNamesArr.indexOf("BannerEnabled")].value,
        BannerTitle: settings[settingsNamesArr.indexOf("BannerTitle")].value,
        BannerBody: settings[settingsNamesArr.indexOf("BannerBody")].value,
        BannerStyle: settings[settingsNamesArr.indexOf("BannerStyle")].value,

        PopupEnabled: settings[settingsNamesArr.indexOf("PopupEnabled")].value,
        PopupBody: settings[settingsNamesArr.indexOf("PopupBody")].value,

        CustomEnable: settings[settingsNamesArr.indexOf("CustomEnable")].value,
        CustomThreadsMax: settings[settingsNamesArr.indexOf("CustomThreadsMax")].value,
        CustomThreadsMin: settings[settingsNamesArr.indexOf("CustomThreadsMin")].value,
        CustomPrices: settings[settingsNamesArr.indexOf("CustomPrices")].value,
        CustomBandwidthMin: settings[settingsNamesArr.indexOf("CustomBandwidthMin")].value,
        CustomBandwidthMax: settings[settingsNamesArr.indexOf("CustomBandwidthMax")].value,
        CustomBandwidthPrices: settings[settingsNamesArr.indexOf("CustomBandwidthPrices")].value,
        CustomResidentialDays: settings[settingsNamesArr.indexOf("CustomResidentialDays")].value,
        ResidentialDescription: settings[settingsNamesArr.indexOf("ResidentialDescription")].value,
        DatacenterDescription: settings[settingsNamesArr.indexOf("DatacenterDescription")].value,

        minRenewDays: settings[settingsNamesArr.indexOf("minRenewDays")].value,
        maxRenewDays: settings[settingsNamesArr.indexOf("maxRenewDays")].value,

        RenewThreadsMin: settings[settingsNamesArr.indexOf("RenewThreadsMin")].value,
        RenewThreadsMax: settings[settingsNamesArr.indexOf("RenewThreadsMax")].value,
        RenewThreadsPrices: settings[settingsNamesArr.indexOf("RenewThreadsPrices")].value,
      });

      setvaluesLoaded(true);
    }
  }, [settingsNamesArr]);

  return (
    <>
      <div className="d-flex ">
        <Sidebar items={dashboardItems} />
        <div className="w-100">
          <NavbarComponent />
          <AlertCard />
          <ToastContainer />

          <Helmet>
            <title>Admin Settings</title>
          </Helmet>

          {/* Page Header */}
          {/* <Toolbar heading="Settings" /> */}
          {/* End Page Header */}

          {/* Content */}
          {!valuesLoaded && (
            <div style={{ minWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center", minHeight: "10rem" }}>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {settings && valuesLoaded && (
            <div className="page-content">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div className="container-lg">
                    {/* Appearance */}
                    <div className="card mb-3">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Appearance</h2>
                      </div>

                      <div className="card-body">
                        {/* Name */}
                        <div className="mb-4">
                          <label className="form-label">Name</label>
                          <input {...getFieldProps("Name")} className="form-control form-control-lg" placeholder="Enter Plan Name" />
                          <span className="invalid-feedback" style={{ display: Boolean(touched.Name && errors.Name) ? "block" : "none" }}>
                            {errors.Name}
                          </span>
                        </div>

                        {/* Description */}
                        <div className="mb-4">
                          <label className="form-label">Description</label>
                          <textarea
                            rows={10}
                            {...getFieldProps("Description")}
                            className="form-control form-control-lg"
                            placeholder="Plan Description"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.Description && errors.Description) ? "block" : "none" }}
                          >
                            {errors.Description}
                          </span>
                        </div>

                        {/* Copyright */}
                        <div className="mb-4">
                          <label className="form-label">Copyright</label>
                          <input {...getFieldProps("Copyright")} className="form-control form-control-lg" placeholder="Copyright" />
                          <span className="invalid-feedback" style={{ display: Boolean(touched.Copyright && errors.Copyright) ? "block" : "none" }}>
                            {errors.Copyright}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Content */}
                    <div className="card mb-3">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Content</h2>
                      </div>

                      <div className="card-body">
                        {/* Terms Of Service */}
                        <div className="mb-4">
                          <label className="form-label">Terms Of Service</label>
                          <span className="invalid-feedback" style={{ display: Boolean(errors.TOS) ? "block" : "none" }}>
                            {errors.TOS}
                          </span>

                          <SunEditor
                            height="20rem"
                            spellcheck="false"
                            onChange={(value) => {
                              setFieldValue("TOS", value);
                            }}
                            defaultValue={values.TOS}
                            value={values.TOS}
                            setDefaultStyle="font-family: Inter, Public Sans, sans-serif; font-size: 16px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle", "blockquote"],
                                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                ["fontColor", "hiliteColor", "textStyle"],
                                ["removeFormat"],

                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"],
                              ],
                            }}
                          />
                        </div>

                        {/* Privacy Policy */}
                        <div className="mb-4">
                          <label className="form-label">Privacy Policy</label>
                          <span className="invalid-feedback" style={{ display: Boolean(errors.Privacy) ? "block" : "none" }}>
                            {errors.Privacy}
                          </span>

                          <SunEditor
                            height="20rem"
                            spellcheck="false"
                            onChange={(value) => {
                              setFieldValue("Privacy", value);
                            }}
                            defaultValue={values.Privacy}
                            value={values.Privacy}
                            setDefaultStyle="font-family: Inter, Public Sans, sans-serif; font-size: 16px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle", "blockquote"],
                                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                ["fontColor", "hiliteColor", "textStyle"],
                                ["removeFormat"],

                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"],
                              ],
                            }}
                          />
                        </div>

                        {/* Suggestions */}
                        <div className="mb-4">
                          <label className="form-label">Suggestions</label>
                          <input rows={10} {...getFieldProps("Suggestions")} className="form-control form-control-lg" placeholder="Suggestions" />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.Suggestions && errors.Suggestions) ? "block" : "none" }}
                          >
                            {errors.Suggestions}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* FAQ */}
                    <div className="card mb-3">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">FAQ</h2>
                      </div>

                      <div className="card-body">
                        {/* FAQ */}
                        <div className="mb-4">
                          <label className="form-label">FAQs</label>
                          <FieldArray
                            name="FAQ"
                            {...getFieldProps("FAQ")}
                            render={(arrayHelpers) => (
                              <div>
                                {typeof values.FAQ !== "string" &&
                                  values.FAQ.map((option, index) => (
                                    <div key={index}>
                                      <div className="row mb-3" mb={2}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`Question`}
                                                name={`FAQ.${index}.question`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("FAQ") &&
                                                    errors[`FAQ`]?.length != 0 &&
                                                    errors[`FAQ`][index]?.question
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Question is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field placeholder={`Answer`} name={`FAQ.${index}.answer`} className="form-control form-control-lg" />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("FAQ") && errors[`FAQ`]?.length != 0 && errors[`FAQ`][index]?.answer
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Answer is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <select
                                                name={`FAQ.${index}.type`}
                                                {...getFieldProps(`FAQ.${index}.type`)}
                                                className="form-select form-control form-control-lg"
                                                placeholder="Type"
                                              >
                                                <option value="General Information">General Information</option>
                                                <option value="Pricing">Pricing</option>
                                                <option value="Proxy Delivery">Proxy Delivery</option>
                                              </select>
                                              <span
                                                className="invalid-feedback"
                                                style={{ display: Boolean(touched.type && errors.type) ? "block" : "none" }}
                                              >
                                                {errors.value}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex align-items-center">
                                          <i
                                            class="bi bi-trash3-fill text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {errors?.FAQ && <p className="text-danger">Something goes wrong</p>}

                                <button onClick={() => arrayHelpers.push("")} className="btn btn-primary">
                                  Add Extra Option
                                </button>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Status */}
                    <div className="card mb-3">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Status</h2>
                      </div>

                      <div className="card-body">
                        {/* Status */}
                        <div className="mb-4">
                          <label className="form-label">Status</label>

                          <FieldArray
                            name="Status"
                            {...getFieldProps("Status")}
                            render={(arrayHelpers) => (
                              <div>
                                {typeof values.Status !== "string" &&
                                  values.Status.map((option, index) => (
                                    <div key={index}>
                                      <div className="row mb-3" mb={2}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`Enter status title`}
                                                name={`Status.${index}.title`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("Status") &&
                                                    errors[`Status`]?.length != 0 &&
                                                    errors[`Status`][index]?.title
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Title is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <select
                                                name={`Status.${index}.value`}
                                                {...getFieldProps(`Status.${index}.value`)}
                                                className="form-select form-control form-control-lg"
                                                placeholder="Type"
                                              >
                                                <option value="Operational">Operational</option>
                                                <option value="Maintenance">Maintenance</option>
                                                <option value="Down">Down</option>
                                              </select>
                                              <span
                                                className="invalid-feedback"
                                                style={{ display: Boolean(touched.type && errors.type) ? "block" : "none" }}
                                              >
                                                {errors.value}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex align-items-center">
                                          <i
                                            class="bi bi-trash3-fill text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {errors?.Status && <p className="text-danger">Something goes wrong</p>}

                                <button onClick={() => arrayHelpers.push({ title: "", value: "Operational" })} className="btn btn-primary">
                                  Add Extra Option
                                </button>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Global Banner */}
                    <div className="card mb-3">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Global Banner</h2>
                      </div>

                      <div className="card-body">
                        {/* Banner Enabled */}
                        <div className="mb-4">
                          <div className="form-check form-switch">
                            <input
                              {...getFieldProps("BannerEnabled")}
                              checked={values.BannerEnabled}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                              Banner Enabled
                            </label>
                          </div>
                        </div>

                        {/* Banner Title */}
                        <div className="mb-4">
                          <label className="form-label">Banner Title</label>
                          <input {...getFieldProps("BannerTitle")} className="form-control form-control-lg" placeholder="Enter Banner Title" />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.BannerTitle && errors.BannerTitle) ? "block" : "none" }}
                          >
                            {errors.BannerTitle}
                          </span>
                        </div>

                        {/* Banner Body */}
                        <div className="mb-4">
                          <label className="form-label">Banner Body</label>
                          <textarea
                            rows={10}
                            {...getFieldProps("BannerBody")}
                            className="form-control form-control-lg"
                            placeholder="Enter Banner Body"
                          />
                          <span className="invalid-feedback" style={{ display: Boolean(errors.BannerBody) ? "block" : "none" }}>
                            {errors.BannerBody}
                          </span>
                        </div>

                        {/* Banner Style */}
                        <div className="mb-4">
                          <label className="form-label">Type</label>
                          <select {...getFieldProps("BannerStyle")} className="form-select form-control form-control-lg" placeholder="Banner Style">
                            <option className="bg-primary text-white" value="primary">
                              Primary
                            </option>
                            <option className="bg-secondary text-white" value="secondary">
                              Secondary
                            </option>
                            <option className="bg-success text-white" value="success">
                              Success
                            </option>
                            <option className="bg-danger text-white" value="danger">
                              Danger
                            </option>
                            <option className="bg-warning text-white" value="warning">
                              Warning
                            </option>
                            <option className="bg-info text-white" value="info">
                              Info
                            </option>
                            <option className="bg-light" value="light">
                              Light
                            </option>
                            <option className="bg-dark text-white" value="dark">
                              Dark
                            </option>
                          </select>
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.BannerStyle && errors.BannerStyle) ? "block" : "none" }}
                          >
                            {errors.BannerStyle}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Popup Announcement */}
                    <div className="card mb-3">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Popup Announcement</h2>
                      </div>

                      <div className="card-body">
                        {/* Popup Enabled */}
                        <div className="mb-4">
                          <div className="form-check form-switch">
                            <input
                              {...getFieldProps("PopupEnabled")}
                              checked={values.PopupEnabled}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                              Popup Enabled
                            </label>
                          </div>
                        </div>

                        {/* Popup Body */}
                        <div className="mb-4">
                          <label className="form-label">Popup Body</label>
                          <span className="invalid-feedback" style={{ display: Boolean(errors.PopupBody) ? "block" : "none" }}>
                            {errors.PopupBody}
                          </span>

                          <SunEditor
                            height="20rem"
                            spellcheck="false"
                            onChange={(value) => {
                              setFieldValue("PopupBody", value);
                            }}
                            defaultValue={values.PopupBody}
                            value={values.PopupBody}
                            setDefaultStyle="font-family: Inter, Public Sans, sans-serif; font-size: 16px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle", "blockquote"],
                                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                ["fontColor", "hiliteColor", "textStyle"],
                                ["removeFormat"],

                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"],
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Custom Plans */}
                    <div className="card mb-3 d-none">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Custom Plans</h2>
                      </div>

                      <div className="row card-body">
                        {/* Custom plan enabled */}
                        <div className="mb-4 col-12">
                          <div className="form-check form-switch">
                            <input
                              {...getFieldProps("CustomEnable")}
                              checked={values.CustomEnable}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault3"
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault3">
                              Custom Plan Enabled
                            </label>
                          </div>
                        </div>

                        {/* Min Threads */}
                        <div className="mb-4  col-lg-6 col-12">
                          <label className="form-label">Custom Threads Min</label>
                          <input {...getFieldProps("CustomThreadsMin")} className="form-control form-control-lg" placeholder="Custom Threads Min" />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.CustomThreadsMin && errors.CustomThreadsMin) ? "block" : "none" }}
                          >
                            {errors.CustomThreadsMin}
                          </span>
                        </div>

                        {/* Max Threads */}
                        <div className="mb-4  col-lg-6 col-12">
                          <label className="form-label">Custom Threads Max</label>
                          <input {...getFieldProps("CustomThreadsMax")} className="form-control form-control-lg" placeholder="Custom Threads Max" />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.CustomThreadsMax && errors.CustomThreadsMax) ? "block" : "none" }}
                          >
                            {errors.CustomThreadsMax}
                          </span>
                        </div>

                        {/* Minimum Renew Days */}
                        <div className="mb-4  col-lg-6 col-12">
                          <label className="form-label">Minimum Renew Days</label>
                          <input
                            type="number"
                            {...getFieldProps("minRenewDays")}
                            className="form-control form-control-lg"
                            placeholder="Minimum Renew Days"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.minRenewDays && errors.minRenewDays) ? "block" : "none" }}
                          >
                            {errors.minRenewDays}
                          </span>
                        </div>

                        {/* Maximum Renew Days */}
                        <div className="mb-4  col-lg-6 col-12">
                          <label className="form-label">Maximum Renew Days</label>
                          <input
                            type="number"
                            {...getFieldProps("maxRenewDays")}
                            className="form-control form-control-lg"
                            placeholder="Maximum Renew Days"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.maxRenewDays && errors.maxRenewDays) ? "block" : "none" }}
                          >
                            {errors.maxRenewDays}
                          </span>
                        </div>

                        {/* Price */}
                        <div className="mb-4 col-12">
                          <label className="form-label">Price (Thread price per day)</label>

                          <FieldArray
                            name="CustomPrices"
                            {...getFieldProps("CustomPrices")}
                            render={(arrayHelpers) => (
                              <div>
                                {typeof values.CustomPrices !== "string" &&
                                  values.CustomPrices.map((option, index) => (
                                    <div key={index}>
                                      <div className="row mb-3" mb={2}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`From`}
                                                name={`CustomPrices.${index}.from`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("CustomPrices") &&
                                                    errors[`CustomPrices`]?.length != 0 &&
                                                    errors[`CustomPrices`][index]?.from
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                From is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field placeholder={`To`} name={`CustomPrices.${index}.to`} className="form-control form-control-lg" />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("CustomPrices") &&
                                                    errors[`CustomPrices`]?.length != 0 &&
                                                    errors[`CustomPrices`][index]?.to
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                To is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field
                                                placeholder={`Price`}
                                                name={`CustomPrices.${index}.price`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("CustomPrices") &&
                                                    errors[`CustomPrices`]?.length != 0 &&
                                                    errors[`CustomPrices`][index]?.price
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Price is required
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex align-items-center">
                                          <i
                                            class="bi bi-trash3-fill text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {errors?.CustomPrices && <p className="text-danger">Something goes wrong</p>}

                                <button onClick={() => arrayHelpers.push("")} className="btn btn-primary">
                                  Add Extra Option
                                </button>
                              </div>
                            )}
                          />
                        </div>

                        {/* Bandwidth min */}
                        <div className="mb-4  col-lg-6 col-12">
                          <label className="form-label">Custom Bandwidth Min</label>
                          <input
                            {...getFieldProps("CustomBandwidthMin")}
                            className="form-control form-control-lg"
                            placeholder="Custom Bandwidth Min"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.CustomBandwidthMin && errors.CustomBandwidthMin) ? "block" : "none" }}
                          >
                            {errors.CustomBandwidthMin}
                          </span>
                        </div>

                        {/* Bandwidth max */}
                        <div className="mb-4  col-lg-6 col-12">
                          <label className="form-label">Custom Bandwidth Max</label>
                          <input
                            {...getFieldProps("CustomBandwidthMax")}
                            className="form-control form-control-lg"
                            placeholder="Custom Bandwidth Max"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.CustomBandwidthMax && errors.CustomBandwidthMax) ? "block" : "none" }}
                          >
                            {errors.CustomBandwidthMax}
                          </span>
                        </div>

                        {/* Custom Residential Days */}
                        <div className="mb-4  col-12">
                          <label className="form-label">Custom Residential Days</label>
                          <input
                            {...getFieldProps("CustomResidentialDays")}
                            className="form-control form-control-lg"
                            placeholder="Custom Residential Days"
                          />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.CustomResidentialDays && errors.CustomResidentialDays) ? "block" : "none" }}
                          >
                            {errors.CustomResidentialDays}
                          </span>
                        </div>

                        {/* Bandwidth Prices */}
                        <div className="mb-4 col-12">
                          <label className="form-label">Bandwidth Price (per GB)</label>

                          <FieldArray
                            name="CustomBandwidthPrices"
                            {...getFieldProps("CustomBandwidthPrices")}
                            render={(arrayHelpers) => (
                              <div>
                                {typeof values.CustomBandwidthPrices !== "string" &&
                                  values.CustomBandwidthPrices.map((option, index) => (
                                    <div key={index}>
                                      <div className="row mb-3" mb={2}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`From`}
                                                name={`CustomBandwidthPrices.${index}.from`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("CustomBandwidthPrices") &&
                                                    errors[`CustomBandwidthPrices`]?.length != 0 &&
                                                    errors[`CustomBandwidthPrices`][index]?.from
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                From is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field
                                                placeholder={`To`}
                                                name={`CustomBandwidthPrices.${index}.to`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("CustomBandwidthPrices") &&
                                                    errors[`CustomBandwidthPrices`]?.length != 0 &&
                                                    errors[`CustomBandwidthPrices`][index]?.to
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                To is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field
                                                placeholder={`Price`}
                                                name={`CustomBandwidthPrices.${index}.price`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("CustomBandwidthPrices") &&
                                                    errors[`CustomBandwidthPrices`]?.length != 0 &&
                                                    errors[`CustomBandwidthPrices`][index]?.price
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Price is required
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex align-items-center">
                                          <i
                                            class="bi bi-trash3-fill text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {errors?.CustomBandwidthPrices && <p className="text-danger">Something goes wrong</p>}

                                <button onClick={() => arrayHelpers.push("")} className="btn btn-primary">
                                  Add Extra Option
                                </button>
                              </div>
                            )}
                          />
                        </div>

                        {/* Residential Description */}
                        <div className="mb-4">
                          <label className="form-label">Residential Description</label>
                          <span className="invalid-feedback" style={{ display: Boolean(errors.ResidentialDescription) ? "block" : "none" }}>
                            {errors.ResidentialDescription}
                          </span>

                          <SunEditor
                            height="20rem"
                            spellcheck="false"
                            onChange={(value) => {
                              setFieldValue("ResidentialDescription", value);
                            }}
                            defaultValue={values.ResidentialDescription}
                            value={values.ResidentialDescription}
                            setDefaultStyle="font-family: Inter, Public Sans, sans-serif; font-size: 16px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle", "blockquote"],
                                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                ["fontColor", "hiliteColor", "textStyle"],
                                ["removeFormat"],

                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"],
                              ],
                            }}
                          />
                        </div>

                        {/* Datacenter Description */}
                        <div className="mb-4">
                          <label className="form-label">Datacenter Description</label>
                          <span className="invalid-feedback" style={{ display: Boolean(errors.DatacenterDescription) ? "block" : "none" }}>
                            {errors.DatacenterDescription}
                          </span>

                          <SunEditor
                            height="20rem"
                            spellcheck="false"
                            onChange={(value) => {
                              setFieldValue("DatacenterDescription", value);
                            }}
                            defaultValue={values.DatacenterDescription}
                            value={values.DatacenterDescription}
                            setDefaultStyle="font-family: Inter, Public Sans, sans-serif; font-size: 16px;"
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle", "blockquote"],
                                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                ["fontColor", "hiliteColor", "textStyle"],
                                ["removeFormat"],

                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"],
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Add Threads */}
                    <div className="card mb-3  d-none">
                      <div className="card-header">
                        <h2 className="card-title h4 text-primary">Add Threads</h2>
                      </div>

                      <div className="row card-body">
                        {/* Min Threads */}
                        <div className="mb-4 col-lg-6 col-6">
                          <label className="form-label">Threads Min</label>
                          <input {...getFieldProps("RenewThreadsMin")} className="form-control form-control-lg" placeholder="Enter Threads Min" />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.RenewThreadsMin && errors.RenewThreadsMin) ? "block" : "none" }}
                          >
                            {errors.RenewThreadsMin}
                          </span>
                        </div>

                        {/* Max Threads */}
                        <div className="mb-4  col-lg-6 col-6">
                          <label className="form-label">Threads Max</label>
                          <input {...getFieldProps("RenewThreadsMax")} className="form-control form-control-lg" placeholder="Enter Threads Max" />
                          <span
                            className="invalid-feedback"
                            style={{ display: Boolean(touched.RenewThreadsMax && errors.RenewThreadsMax) ? "block" : "none" }}
                          >
                            {errors.RenewThreadsMax}
                          </span>
                        </div>

                        {/* Price */}
                        <div className="mb-4 col-12">
                          <label className="form-label">Price (per thread per day)</label>

                          <FieldArray
                            name="RenewThreadsPrices"
                            {...getFieldProps("RenewThreadsPrices")}
                            render={(arrayHelpers) => (
                              <div>
                                {typeof values.RenewThreadsPrices !== "string" &&
                                  values.RenewThreadsPrices?.map((option, index) => (
                                    <div key={index}>
                                      <div className="row mb-3" mb={2}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`From`}
                                                name={`RenewThreadsPrices.${index}.from`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("RenewThreadsPrices") &&
                                                    errors[`RenewThreadsPrices`]?.length != 0 &&
                                                    errors[`RenewThreadsPrices`][index]?.from
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                From is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field
                                                placeholder={`To`}
                                                name={`RenewThreadsPrices.${index}.to`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("RenewThreadsPrices") &&
                                                    errors[`RenewThreadsPrices`]?.length != 0 &&
                                                    errors[`RenewThreadsPrices`][index]?.to
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                To is required
                                              </span>
                                            </div>

                                            <div className="col-4">
                                              <Field
                                                placeholder={`Price`}
                                                name={`RenewThreadsPrices.${index}.price`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("RenewThreadsPrices") &&
                                                    errors[`RenewThreadsPrices`]?.length != 0 &&
                                                    errors[`RenewThreadsPrices`][index]?.price
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Price is required
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2 d-flex align-items-center">
                                          <i
                                            class="bi bi-trash3-fill text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                {errors?.RenewThreadsPrices && <p className="text-danger">Something goes wrong</p>}

                                <button onClick={() => arrayHelpers.push("")} className="btn btn-primary">
                                  Add Extra Option
                                </button>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Errors from server */}
                    {errors.afterSubmit && (
                      <div className="alert alert-danger" role="alert">
                        {errors.afterSubmit}
                      </div>
                    )}

                    {/* Sign in */}
                    <div className="d-flex justify-content-center">
                      <LoadingButton loading={isSubmitting} type="submit" className="btn btn-primary ">
                        Update Settings
                      </LoadingButton>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Settings;
