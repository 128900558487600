import React from "react";

import SidebarNavSection from "./SidebarNavSection";

const SidebarNav = ({ items }) => {
  return (
    <div>
      <ul className="text-start ps-0 customScrollBarDark">
        {/* style={{ maxHeight: "calc(100vh - 330px)", overflowX: "auto" }} */}
        {items &&
          items.map((item) => (
            <SidebarNavSection
              key={item.title}
              pages={item.pages}
              title={item.title}
            />
          ))}
      </ul>
    </div>
  );
};

export default SidebarNav;
