import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Card, Row, Button } from "react-bootstrap";
import { Form, FormikProvider, useFormik } from "formik";
import ReactTooltip from "react-tooltip";
import NavbarComponent from "../../Components/navbar/Navbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import { Line } from "react-chartjs-2";
import DoughnutChart from "./DoughnutChart";
import { json, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addWhitelistedIp, getProxyInfo, removeWhitelistedIp, selectGeneratorInfo, updateProxyConfig } from "../../redux/slices/publicSlice";
import { formatTime } from "../../utils/formatTime";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PlanGenerator = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generatorInfo = useSelector(selectGeneratorInfo);
  const [loading, setloading] = useState(true);
  const [myIpLoading, setmyIpLoading] = useState(false);
  const [successfullyUpdatedProxyConfig, setsuccessfullyUpdatedProxyConfig] = useState(false);
  const [errorProxyConfig, setErrorProxyConfig] = useState(null);
  const [usageSeriessData, setusageSeriessData] = useState(null);
  const [rewardReferralLabels, setrewardReferralLabels] = useState(null);
  const [RewardReferralCurrentTab, setRewardReferralCurrentTab] = useState(0);
  const notify = (msg) => toast.info(msg);
  const whitelistIpInpuRef = useRef();

  function ValidateIPaddress(ipaddress) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }
    return false;
  }

  useEffect(async () => {
    setloading(true);
    await dispatch(getProxyInfo({ subscriptionId: params.subscriptionId, navigate }));
    setloading(false);
  }, []);

  const RegisterSchema = Yup.object().shape({
    geoRegion: Yup.string().required("Geo Region is required"),
  });

  const formik = useFormik({
    initialValues: {
      geoRegion: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);

      await dispatch(
        updateProxyConfig({
          ...values,
          subscriptionId: params.subscriptionId,
          setsuccessfullyUpdatedProxyConfig,
          setErrorProxyConfig,
          notify,
        })
      );

      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues } = formik;
  let options = {
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          // color:
          // themeContextValue.selectedTheme === "dark" ? "#adbbc7" : "#97a4af",
          usePointStyle: true,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      yAxes: {
        gridLines: {
          color: "#e7eaf3",
          drawBorder: false,
          zeroLineColor: "#e7eaf3",
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          fontSize: 12,
          // color:
          // themeContextValue.selectedTheme === "dark" ? "#adbbc7" : "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 10,
          postfix: "k",
        },
      },
      xAxes: {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          fontSize: 12,
          // color:
          // themeContextValue.selectedTheme === "dark" ? "#adbbc7" : "#97a4af",
          fontFamily: "Open Sans, sans-serif",
          padding: 5,
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };
  useEffect(() => {
    if (generatorInfo) {
      setValues({
        geoRegion: generatorInfo?.targetSubscription.serverGeo,
      });
    }
  }, [generatorInfo]);

  return (
    <div className="d-flex">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <div className="px-4 mt-4">
          <Row>
            <Col md="6" xl className="d-flex">
              {" "}
              <div className="flex-fill w-100 card p-4" style={{ backgroundColor: "#027ef80d" }}>
                <div className="row mb-2">
                  <div className="col-12 d-flex">
                    <div className="text-start">
                      <h3 style={{ color: "var(--bs-blue)" }}>Bandwidth usage</h3>

                      {/* <div className="hstack gap-3 pb-3">
                        <div className="text-center">
                          <label className="form-label">Today</label>
                          <h5>
                            $
                            {Math.round(
                              generatorInfo?.bandwidthusageData?.usageSeries
                                ?.allTime
                            )}
                          </h5>
                        </div>
                        <div className="text-center">
                          <label className="form-label">Week</label>
                          <h5>
                            $
                            {Math.round(
                              generatorInfo?.bandwidthusageData?.usageSeries
                                ?.week
                            )}
                          </h5>
                        </div>
                        <div className="text-center">
                          <label className="form-label">Month</label>
                          <h5>
                            $
                            {Math.round(
                              generatorInfo?.bandwidthusageData?.usageSeries
                                ?.month
                            )}
                          </h5>
                        </div>
                        <div className="text-center">
                          <label className="form-label">All Time</label>
                          <h5>
                            $
                            {Math.round(
                              generatorInfo?.bandwidthusageData?.usageSeries
                                ?.allTime
                            )}
                          </h5>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <Line
                  data={{
                    labels: rewardReferralLabels,
                    datasets: [
                      {
                        data: usageSeriessData,
                        label: "Bandwidth used",
                        backgroundColor: "transparent",
                        borderColor: "#377dff",
                        borderWidth: 2,
                        lineTension: 0.4,
                        pointRadius: 0,
                        hoverBorderColor: "#377dff",
                        pointBackgroundColor: "#377dff",
                        pointBorderColor: "#fff",
                        pointHoverRadius: 0,
                      },
                    ],
                  }}
                  options={options}
                />

                {/* Data Range Switcher */}
                <div style={{ width: "100%" }} className="d-flex justify-content-center mt-4">
                  <nav class="nav nav-pills flex-column flex-sm-row">
                    <a
                      class={RewardReferralCurrentTab === 0 ? "flex-sm-fill text-sm-center nav-link active" : "flex-sm-fill text-sm-center nav-link"}
                      aria-current="page"
                      onClick={() => {
                        setRewardReferralCurrentTab(0);
                        setrewardReferralLabels(generatorInfo?.bandwidthusageData?.usageSeries?.week?.labels);
                        setusageSeriessData(generatorInfo?.bandwidthusageData?.usageSeries?.week?.data);
                      }}
                    >
                      7 Days
                    </a>
                    <a
                      class={RewardReferralCurrentTab === 1 ? "flex-sm-fill text-sm-center nav-link active" : "flex-sm-fill text-sm-center nav-link"}
                      aria-current="page"
                      onClick={() => {
                        setRewardReferralCurrentTab(1);
                        setrewardReferralLabels(generatorInfo?.bandwidthusageData?.usageSeries?.month?.labels);
                        setusageSeriessData(generatorInfo?.bandwidthusageData?.usageSeries?.month?.data);
                      }}
                    >
                      Month
                    </a>
                    <a
                      class={RewardReferralCurrentTab === 2 ? "flex-sm-fill text-sm-center nav-link active" : "flex-sm-fill text-sm-center nav-link"}
                      aria-current="page"
                      onClick={() => {
                        setRewardReferralCurrentTab(2);
                        setrewardReferralLabels(generatorInfo?.bandwidthusageData?.usageSeries?.allTime?.labels);
                        setusageSeriessData(generatorInfo?.bandwidthusageData?.usageSeries?.allTime?.data);
                      }}
                    >
                      All Time
                    </a>
                  </nav>
                </div>
              </div>
            </Col>
            <Col md="6" xl className="d-flex">
              <DoughnutChart remaining={generatorInfo?.targetSubscription?.bandwidthLeft} total={generatorInfo?.targetSubscription?.bandwidth} />
            </Col>
          </Row>
          <Row>
            <div className="col-lg-6 col-12 text-start">
              <div>
                {" "}
                <div className="card card-body py-4">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 text-start">
                      <h3 className="mb-2">{generatorInfo?.targetSubscription?.planName}</h3>
                      <p className="mb-2">{generatorInfo?.targetSubscription?.provider == "netnut" ? "Residential" : "Sneaker"}</p>
                      <p className="mb-2">{generatorInfo?.targetSubscription?._id}</p>
                      <p className="mb-2">
                        Ending on: {generatorInfo?.targetSubscription?.renewalDate || formatTime(generatorInfo?.targetSubscription?.renewalDate)}
                      </p>
                      <Button
                        onClick={() => {
                          console.log(`/plans/renew/bandwidth/${generatorInfo?.targetSubscription?._id}`);
                          navigate(`/plans/renew/bandwidth/${generatorInfo?.targetSubscription?._id}`);
                        }}
                      >
                        Add Bandwidth
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 text-start">
              <div className="row p-0 m-0">
                <div className="col-12 p-0">
                  <FormikProvider value={formik}>
                    <Form action="#" autoComplete="off" noValidate onSubmit={handleSubmit}>
                      {generatorInfo?.targetSubscription?.provider == "kerio" && (
                        <div className="card card-body">
                          <h3 className="mb-3">Whitelist</h3>
                          <div className="row mb-3">
                            <div className="d-flex mb-2">
                              {generatorInfo?.targetSubscription?.authIPs?.map((singleIP) => {
                                return (
                                  <div className="border rounded p-1 bg-primary text-white" style={{ whiteSpace: "nowrap" }}>
                                    <span style={{ marginLeft: "0.5rem" }}>{singleIP}</span>
                                    <i
                                      class="bi bi-x-circle"
                                      style={{
                                        marginLeft: "0.5rem",
                                        marginRight: "0.3rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          removeWhitelistedIp({
                                            notify,
                                            subscriptionId: generatorInfo?.targetSubscription?._id,
                                            ip: singleIP,
                                            whitelistIpInpuRef,
                                          })
                                        );
                                      }}
                                    ></i>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="col-12">
                              <label className="form-label">Whitelist IP</label>
                              <input
                                ref={whitelistIpInpuRef}
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter IP address here"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Button
                                type="submit"
                                className="btn btn-primary px-3"
                                onClick={() => {
                                  if (ValidateIPaddress(whitelistIpInpuRef.current.value)) {
                                    dispatch(
                                      addWhitelistedIp({
                                        notify,
                                        subscriptionId: generatorInfo?.targetSubscription?._id,
                                        ip: whitelistIpInpuRef.current.value,
                                        whitelistIpInpuRef,
                                      })
                                    );
                                  } else {
                                    notify("You have entered an invalid IP address!");
                                  }
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}

                      {generatorInfo?.targetSubscription?.provider != "kerio" && (
                        <div className="card card-body">
                          <h3 className="mb-3">Proxy Configurations</h3>
                          <div className="row mb-3">
                            <div className="col-12">
                              <label className="form-label">Geo Region</label>
                              <select
                                className="form-select form-control form-control-lg"
                                {...getFieldProps("geoRegion")}
                                placeholder="Select Country"
                                style={{
                                  maxHeight: "15rem",
                                  overflowX: "hidden",
                                  overflowY: "scroll",
                                  width: "100%",
                                }}
                              >
                                <option value={"any"}>Worldwide Mix</option>

                                {generatorInfo &&
                                  generatorInfo?.proxy?.countries.map((country) => {
                                    return <option value={country}>{country}</option>;
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6">
                              <label className="form-label">Username</label>
                              <input
                                name="username"
                                type="text"
                                className="form-control form-control-lg"
                                value={`${generatorInfo?.targetSubscription?.authUser}-res-${generatorInfo?.targetSubscription?.serverGeo}`}
                              />
                              <span className="invalid-feedback" style={{ display: "none" }} />
                            </div>
                            <div className="col-6">
                              <label className="form-label">Password</label>
                              <input
                                name="password"
                                type="text"
                                className="form-control form-control-lg"
                                value={generatorInfo?.targetSubscription?.authPass}
                              />
                              <span className="invalid-feedback" style={{ display: "none" }} />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12">
                              <Button
                                type="submit"
                                className="btn btn-primary px-3"
                                onClick={() => {
                                  setsuccessfullyUpdatedProxyConfig(false);
                                }}
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="card card-body">
                        <h3 className=" mb-3">Proxy</h3>
                        {generatorInfo?.targetSubscription?.provider != "kerio" && (
                          <div className="row mb-3">
                            <div className="col-8">
                              <label className="form-label">Host</label>
                              <input type="text" className="form-control form-control-lg" value={generatorInfo?.proxy?.host} />
                            </div>
                            <div className="col-4">
                              <label className="form-label">Port</label>
                              <input type="text" className="form-control form-control-lg" value={generatorInfo?.proxy?.port} />
                            </div>
                          </div>
                        )}

                        <div className="row">
                          <div className="col-12">
                            {generatorInfo?.targetSubscription?.provider != "kerio" && (
                              <>
                                <div className="d-flex align-items-center mb-2 mt-3">
                                  <p className="p-0 m-0">Rotating proxy</p>

                                  {/* <i
                              data-tip
                              data-for="r3"
                              class="bi bi-info-circle-fill text-primary"
                              style={{ marginLeft: "0.5rem" }}
                            ></i>
                            <ReactTooltip
                              id="r3"
                              effect="float"
                              delayShow={10}
                              place="right"
                            >
                              <h5 className="text-primary">Rotating Proxies</h5>
                              <span>
                                Rotating proxies rotate with every request i.e.
                                every time your tool will try to use the proxy,
                                a different proxy from the pool will be used.
                                It's recommended for targets that require unique
                                proxies in consecutive uses. To use them, copy
                                the proxy and save it in a txt file and import
                                wherever needed. These proxies are not a list
                                but behave in the same way.
                              </span>
                            </ReactTooltip> */}
                                </div>

                                <input
                                  type="text"
                                  onFocus={(event) => {
                                    event.target.select();
                                    window.navigator.clipboard.writeText(generatorInfo?.proxy?.rotatingProxy);
                                  }}
                                  value={generatorInfo?.proxy?.rotatingProxy}
                                  className="form-control form-control-lg"
                                />
                              </>
                            )}

                            <>
                              <div className="d-flex align-items-center mb-2 mt-3">
                                <p className="p-0 m-0">Sticky Sessions</p>
                              </div>

                              <textarea
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                                className="w-100 card"
                                rows={7}
                                style={{
                                  maxHeight: "15rem",
                                  overflowY: "scroll",
                                  paddingLeft: 12,
                                  paddingTop: 12,
                                  paddingBottom: 12,
                                  color: "currentColor",
                                  overflowX: "hidden",
                                }}
                                onFocus={(event) => {
                                  event.target.select();
                                  window.navigator.clipboard.writeText(generatorInfo?.proxy?.proxies);
                                }}
                                value={generatorInfo?.proxy?.proxies}
                              ></textarea>
                            </>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default PlanGenerator;
