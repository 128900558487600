import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import LoadingButton from "../../Components/common/LoadingButton";
import {
  deleteSubscription,
  getAllSubscriptions,
  resolveSubscription,
  selectSubscriptions,
  selectSubscriptionsPager,
  updateSubscriptionsSearch,
} from "../../redux/slices/adminSlice";
import { formatTime } from "../../utils/formatTime";
import Sidebar from "../../Components/sidebar/Sidebar";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import AlertCard from "../../Components/AlertCard";
import NavbarComponent from "../../Components/navbar/Navbar";

export default function Subscriptions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allSubscriptions = useSelector(selectSubscriptions);
  const pager = useSelector(selectSubscriptionsPager);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(0);
  const [subscriptionToResolve, setsubscriptionToResolve] = useState(null);
  const [resolveSubscriptionLoading, setresolveSubscriptionLoading] =
    useState(false);
  const [searchParams] = useSearchParams();
  const searchInput = useRef();

  useEffect(() => {
    if (searchParams.get("search") !== undefined) {
      searchInput.current.value = searchParams.get("search");
      dispatch(updateSubscriptionsSearch(searchParams.get("search")));
      dispatch(getAllSubscriptions({ page: 1 }));
    } else {
      dispatch(getAllSubscriptions({ page: 1 }));
    }
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />

        <>
          <Helmet>
            <title>Admin Subscriptions - KocerRoxy</title>
          </Helmet>

          {/* Content */}
          <div className="page-content px-4 ">
            <div class="card">
              <div className=" table-responsive datatable-custom">
                <div style={{ padding: 16 }}>
                  <input
                    ref={searchInput}
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Search by Subscription Id, Username, Plan Name"
                    onChange={(e) => {
                      dispatch(updateSubscriptionsSearch(e.target.value));
                      dispatch(getAllSubscriptions({ page: 1 }));
                    }}
                  />
                </div>

                <table
                  id="datatable"
                  className="table table-borderless table-thead-bordered table-align-middle card-table"
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Subscription Id</th>
                      <th>Username</th>
                      <th>Plan Name</th>
                      <th>Status</th>
                      <th>Price</th>
                      <th>Bandwidth</th>
                      <th>Processor</th>
                      <th>Payment</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSubscriptions.map((subscription, index) => {
                      return (
                        <tr>
                          <td>{subscription._id}</td>
                          <td>
                            <a
                              className="text-primary"
                              href={`/admin/accounts/${subscription.user?._id}`}
                            >
                              {subscription.user?.username}
                            </a>
                          </td>
                          <td className="text-primary">
                            {subscription.trial === true && "[Trial] "}
                            {subscription.planName}
                          </td>
                          <td>
                            {/* Toptup */}
                            {subscription.topup === true && (
                              <>
                                {subscription.paid && (
                                  <span className="badge badge-bg-succecss">
                                    Paid
                                  </span>
                                )}
                                {!subscription.paid &&
                                  !subscription.pending && (
                                    <span className="badge badge-bg-danger">
                                      Unpaid
                                    </span>
                                  )}
                                {!subscription.paid && subscription.pending && (
                                  <span className="badge badge-bg-info">
                                    Pending
                                  </span>
                                )}
                              </>
                            )}

                            {/* Renewal */}
                            {subscription.renew === true && (
                              <>
                                {subscription.paid && (
                                  <span className="badge badge-bg-succecss">
                                    Renewed
                                  </span>
                                )}
                                {!subscription.paid &&
                                  !subscription.pending && (
                                    <span className="badge badge-bg-danger">
                                      Unpaid
                                    </span>
                                  )}
                                {!subscription.paid && subscription.pending && (
                                  <span className="badge badge-bg-info">
                                    Pending
                                  </span>
                                )}
                              </>
                            )}

                            {/* Subscription with renewal data */}
                            {subscription.renew === false &&
                              subscription.topup === false &&
                              Object.keys(subscription).includes(
                                "renewalDate"
                              ) &&
                              subscription.renewalDate && (
                                <>
                                  {moment(subscription?.renewalDate).diff(
                                    moment().utc(),
                                    "seconds"
                                  ) > 0 ? (
                                    <span className="badge badge-bg-primary">
                                      Ends in{" "}
                                      {moment
                                        .duration(
                                          moment(
                                            subscription?.renewalDate
                                          ).diff(moment().utc(), "seconds") *
                                            1000
                                        )
                                        .humanize()}
                                    </span>
                                  ) : (
                                    <span className="badge badge-bg-info">
                                      Expired
                                    </span>
                                  )}
                                </>
                              )}

                            {/* Subscription without renewal data */}
                            {subscription.renew === false &&
                              subscription.topup === false &&
                              !Object.keys(subscription).includes(
                                "renewalDate"
                              ) && (
                                <>
                                  {subscription.paid && (
                                    <span className="badge badge-bg-danger">
                                      Not Activated
                                    </span>
                                  )}
                                  {!subscription.paid &&
                                    !subscription.pending &&
                                    subscription.unresolvedStatus == null && (
                                      <>
                                        <span className="badge badge-bg-danger">
                                          Unpaid
                                        </span>
                                      </>
                                    )}
                                  {!subscription.paid &&
                                    !subscription.pending &&
                                    subscription.unresolvedStatus != null && (
                                      <>
                                        <span
                                          className="badge badge-bg-warning"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {subscription.unresolvedStatus.toLowerCase()}
                                        </span>
                                      </>
                                    )}
                                  {!subscription.paid &&
                                    subscription.pending && (
                                      <span className="badge badge-bg-info">
                                        Pending
                                      </span>
                                    )}
                                </>
                              )}
                          </td>

                          <td className="text-success">
                            ${subscription.price}
                          </td>
                          <td>
                            {subscription.topup === false
                              ? subscription.bandwidth === 0
                                ? "Unlimited"
                                : !subscription.renew
                                ? `${
                                    subscription.bandwidthLeft > 0
                                      ? subscription.bandwidthLeft
                                      : 0
                                  } GB / ${subscription.bandwidth} GB`
                                : `${
                                    subscription.bandwidthLeft
                                      ? subscription.bandwidthLeft
                                      : "0"
                                  } GB / ${subscription.bandwidth} GB`
                              : "-"}{" "}
                          </td>
                          <td style={{ color: "var(--bs-cyan)" }}>
                            {subscription.paymentProcessor.toUpperCase()}
                          </td>
                          <td>
                            {subscription.paid && (
                              <>
                                <span className="badge badge-bg-succecss">
                                  Paid
                                </span>
                              </>
                            )}
                            {!subscription.paid &&
                              !subscription.pending &&
                              subscription.unresolvedStatus == null && (
                                <>
                                  <span className="badge badge-bg-danger">
                                    Unpaid
                                  </span>
                                </>
                              )}
                            {!subscription.paid &&
                              !subscription.pending &&
                              subscription.unresolvedStatus != null && (
                                <>
                                  <span
                                    className="badge badge-bg-warning"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {subscription.unresolvedStatus.toLowerCase()}
                                  </span>
                                </>
                              )}
                            {!subscription.paid && subscription.pending && (
                              <>
                                <span className="badge badge-bg-info">
                                  Pending
                                </span>
                              </>
                            )}
                          </td>
                          <td>{formatTime(subscription.createdAt)}</td>

                          <td style={{ minWidth: "5rem" }}>
                            <i
                              data-tip
                              data-for="viewBtn"
                              class="bi bi-eye-fill icon-button text-primary"
                              style={{
                                marginLeft: 0,
                                marginRight: "0.7rem",
                                cursor: "pointer",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              onClick={() => {
                                setSelectedSubscription(subscription);
                              }}
                            ></i>
                            <ReactTooltip
                              id="viewBtn"
                              effect="float"
                              delayShow={500}
                            >
                              <span>View</span>
                            </ReactTooltip>

                            {subscription.invoiceLink && (
                              <>
                                <i
                                  data-tip
                                  style={{
                                    marginLeft: 0,
                                    marginRight: "0.7rem",
                                    cursor: "pointer",
                                  }}
                                  data-for="invoicesbtn"
                                  class="bi bi-link-45deg icon-button text-info"
                                  onClick={() => {
                                    window.open(
                                      subscription.invoiceLink,
                                      "_blank"
                                    );
                                  }}
                                ></i>
                                <ReactTooltip
                                  id="invoicesbtn"
                                  effect="float"
                                  delayShow={500}
                                >
                                  <span>Invoice</span>
                                </ReactTooltip>
                              </>
                            )}

                            {subscription.paid === false && (
                              <>
                                <i
                                  data-tip
                                  style={{
                                    marginLeft: 0,
                                    marginRight: "0.7rem",
                                    cursor: "pointer",
                                  }}
                                  data-for="resolveBtn"
                                  class="bi bi-check icon-button text-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#markAsPaidModal"
                                  onClick={() => {
                                    setsubscriptionToResolve(subscription);
                                  }}
                                ></i>
                                <ReactTooltip
                                  id="resolveBtn"
                                  effect="float"
                                  delayShow={500}
                                >
                                  <span>Mark As Paid</span>
                                </ReactTooltip>
                              </>
                            )}

                            {subscription.topup === false && (
                              <>
                                {subscription.active === true && (
                                  <>
                                    <i
                                      data-tip
                                      style={{
                                        marginLeft: 0,
                                        marginRight: "0.7rem",
                                        cursor: "pointer",
                                      }}
                                      data-for="editBtn"
                                      class="bi bi-pencil-fill icon-button text-warning"
                                      onClick={() => {
                                        navigate(
                                          `/admin/subscriptions/edit/${subscription._id}`
                                        );
                                      }}
                                    ></i>
                                    <ReactTooltip
                                      id="editBtn"
                                      effect="float"
                                      delayShow={500}
                                    >
                                      <span>Edit</span>
                                    </ReactTooltip>
                                  </>
                                )}
                              </>
                            )}

                            <i
                              data-tip
                              data-for="deleteBtn"
                              style={{
                                marginLeft: 0,
                                marginRight: "0.7rem",
                                cursor: "pointer",
                              }}
                              class="bi bi-trash-fill icon-button text-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#deletePlanModal"
                              onClick={() => {
                                setSubscriptionToDelete(subscription);
                              }}
                            ></i>
                            <ReactTooltip
                              id="deleteBtn"
                              effect="float"
                              delayShow={500}
                            >
                              <span>Delete</span>
                            </ReactTooltip>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {pager?.total > 1 && (
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 20,
                      border: "solid",
                      borderWidth: "0px",
                      borderTopWidth: "0.1px",
                      borderColor: "var(--bs-gray-300)",
                    }}
                  >
                    <nav>
                      <ul class="pagination" style={{ margin: 0 }}>
                        <li
                          class="page-item"
                          style={{
                            margin: "0px",
                            border: "solid",
                            paddingTop: "2px",
                            borderWidth: "0.2px",
                            borderColor: "var(--bs-gray-400)",
                            borderTopLeftRadius: 6,
                            borderBottomLeftRadius: 6,
                          }}
                          onClick={() => {
                            dispatch(
                              getAllSubscriptions({
                                page: pager.page <= 1 ? 1 : pager.page - 1,
                              })
                            );
                          }}
                        >
                          <a class="page-link" href="#" aria-label="Previous">
                            <span
                              aria-hidden="true"
                              style={{ fontSize: "1.3rem" }}
                            >
                              &laquo;
                            </span>
                          </a>
                        </li>

                        <li
                          class="page-item"
                          style={{
                            padding: 15,
                            display: "flex",
                            alignItems: "center",
                            margin: "0px",
                            border: "solid",
                            borderWidth: "0px",
                            borderBottomWidth: "0.2px",
                            borderTopWidth: "0.2px",
                            borderColor: "var(--bs-gray-400)",
                          }}
                        >
                          {pager?.page} of {pager?.total}
                        </li>

                        <li
                          class="page-item"
                          style={{
                            margin: "0px",
                            border: "solid",
                            paddingTop: "2px",
                            borderWidth: "0.2px",
                            borderColor: "var(--bs-gray-400)",
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                          onClick={() => {
                            dispatch(
                              getAllSubscriptions({
                                page:
                                  pager.page >= pager.total
                                    ? pager.total
                                    : pager.page + 1,
                              })
                            );
                          }}
                        >
                          <a class="page-link" href="#" aria-label="Next">
                            <span
                              aria-hidden="true"
                              style={{ fontSize: "1.3rem" }}
                            >
                              &raquo;
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* End Content */}

          {/* View Plan Modal */}
          <div>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="staticBackdropLabel">
                      View {selectedSubscription?._id}
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>ID: </span>{" "}
                      {selectedSubscription?._id}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Renewing Subscription:{" "}
                      </span>{" "}
                      {selectedSubscription?.renewingSubscription?._id
                        ? selectedSubscription?.renewingSubscription?._id
                        : "-"}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Plan Name: </span>{" "}
                      <span className="text-priamry">
                        {selectedSubscription?.planName}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Type: </span>{" "}
                      {selectedSubscription?.readableType}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Payment: </span>{" "}
                      <>
                        {selectedSubscription?.paid ? (
                          <span className="badge badge-bg-succecss">Paid</span>
                        ) : (
                          <span className="badge badge-bg-danger">Unpaid</span>
                        )}
                      </>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Payment Processor:{" "}
                      </span>{" "}
                      <span style={{ color: "var(--bs-cyan)" }}>
                        {selectedSubscription?.paymentProcessor.toUpperCase()}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Status: </span>

                      {selectedSubscription?.topup === false &&
                        selectedSubscription?.renew === false && (
                          <>
                            {selectedSubscription?.renewalDate ? (
                              <>
                                {moment(selectedSubscription?.renewalDate).diff(
                                  moment().utc(),
                                  "seconds"
                                ) > 0 ? (
                                  <span className="badge badge-bg-primary">
                                    Ends in{" "}
                                    {moment
                                      .duration(
                                        moment(
                                          selectedSubscription?.renewalDate
                                        ).diff(moment().utc(), "seconds") * 1000
                                      )
                                      .humanize()}
                                  </span>
                                ) : (
                                  <span className="badge badge-bg-danger">
                                    Expired
                                  </span>
                                )}
                              </>
                            ) : !selectedSubscription?.paid ? (
                              <span className="badge badge-bg-danger">
                                Unpaid
                              </span>
                            ) : (
                              <span className="badge badge-bg-danger">
                                Not Activated
                              </span>
                            )}
                          </>
                        )}

                      {selectedSubscription?.topup === true && (
                        <>
                          {selectedSubscription?.paid ? (
                            <span className="badge badge-bg-succecss">
                              Paid
                            </span>
                          ) : (
                            <span className="badge badge-bg-danger">
                              Unpaid
                            </span>
                          )}
                        </>
                      )}

                      {selectedSubscription?.renew === true && (
                        <>
                          {selectedSubscription?.paid ? (
                            <span className="badge badge-bg-succecss">
                              Paid
                            </span>
                          ) : (
                            <span className="badge badge-bg-danger">
                              Unpaid
                            </span>
                          )}
                        </>
                      )}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>Created At: </span>{" "}
                      {formatTime(selectedSubscription?.createdAt)}
                    </p>
                  </div>
                  <div className="modal-footer">
                    {selectedSubscription?.invoiceLink && (
                      <>
                        <button
                          className="btn btn-info"
                          data-bs-dismiss="modal"
                          style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
                          onClick={() => {
                            window.open(
                              selectedSubscription?.invoiceLink,
                              "_blank"
                            );
                          }}
                        >
                          <i
                            class="bi bi-eye-fill text-white"
                            style={{ marginRight: "0.3rem" }}
                          ></i>
                          <b>Invoice</b>
                        </button>
                      </>
                    )}

                    {selectedSubscription?.paid === false && (
                      <>
                        <button
                          className="btn btn-success"
                          style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
                          data-bs-toggle="modal"
                          data-bs-target="#markAsPaidModal"
                          onClick={() => {
                            setsubscriptionToResolve(selectedSubscription);
                          }}
                        >
                          <i
                            class="bi bi-check text-white"
                            style={{ marginRight: "0.3rem" }}
                          ></i>
                          <b>Mark As Paid</b>
                        </button>
                      </>
                    )}

                    {selectedSubscription?.topup === false && (
                      <>
                        {selectedSubscription?.active === true && (
                          <>
                            <button
                              className="btn"
                              data-bs-dismiss="modal"
                              style={{
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                                backgroundColor: "var(--bs-yellow)",
                              }}
                              onClick={() => {
                                navigate(
                                  `/admin/subscriptions/edit/${selectedSubscription?._id}`
                                );
                              }}
                            >
                              <i
                                class="bi bi-pencil-fill text-white"
                                style={{ marginRight: "0.3rem" }}
                              ></i>
                              <b className="text-white">Edit</b>
                            </button>
                          </>
                        )}

                        {/* {selectedSubscription?.paid === true && (
                          <>
                            <button
                              className="btn"
                              data-bs-dismiss="modal"
                              style={{
                                paddingRight: "1rem",
                                paddingLeft: "1rem",
                                backgroundColor: "var(--bs-yellow)",
                                borderColor: "var(--bs-yellow)",
                              }}
                              onClick={() => {
                                Object.keys(selectedSubscription).includes(
                                  "renewingSubscription"
                                ) &&
                                selectedSubscription?.renewingSubscription !==
                                  null
                                  ? navigate(
                                      `/admin/checker?subid=${selectedSubscription?.renewingSubscription.serverID}&type=${selectedSubscription?.type}`
                                    )
                                  : navigate(
                                      `/admin/checker?subid=${selectedSubscription?.serverID}&type=${selectedSubscription?.type}`
                                    );
                              }}
                            >
                              <i
                                class="bi bi-check-all text-white"
                                style={{ marginRight: "0.3rem" }}
                              ></i>
                              <b className="text-white">Checker</b>
                            </button>
                          </>
                        )} */}
                      </>
                    )}

                    <button
                      className="btn btn-danger"
                      style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
                      data-bs-toggle="modal"
                      data-bs-target="#deletePlanModal"
                      onClick={() => {
                        setSubscriptionToDelete(selectedSubscription);
                      }}
                    >
                      <i
                        class="bi bi-trash-fill text-white"
                        style={{ marginRight: "0.3rem" }}
                      ></i>
                      <b>Delete</b>
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Delete Plan Modal */}
          <div>
            <div
              className="modal fade"
              id="deletePlanModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3
                      className="modal-title text-danger"
                      id="staticBackdropLabel"
                    >
                      You are permanently deleting this subscription
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>ID: </span>{" "}
                      {subscriptionToDelete?._id}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Plan Name: </span>{" "}
                      {subscriptionToDelete?.planName}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Paid: </span>{" "}
                      {subscriptionToDelete?.paid ? "Paid" : "Unpaid"}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Payment Processor:{" "}
                      </span>{" "}
                      {subscriptionToDelete?.paymentProcessor?.toUpperCase()}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Created At: </span>{" "}
                      {formatTime(subscriptionToDelete?.createdAt)}
                    </p>
                  </div>
                  <div className="modal-footer">
                    {subscriptionToDelete?.renew && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          dispatch(
                            deleteSubscription({
                              subscriptionId: subscriptionToDelete?._id,
                              deleteOnlyRenew: true,
                            })
                          );
                        }}
                      >
                        Delete Only Renew
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        dispatch(
                          deleteSubscription({
                            subscriptionId: subscriptionToDelete?._id,
                            deleteOnlyRenew: false,
                          })
                        );
                      }}
                    >
                      Delete This Subscription
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Resolve unpaid subscription */}
          <div>
            <div
              className="modal fade"
              id="markAsPaidModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3
                      className="modal-title text-danger"
                      id="staticBackdropLabel"
                    >
                      Mark As Paid
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>ID: </span>{" "}
                      {subscriptionToResolve?._id}
                    </p>
                  </div>
                  <div className="modal-footer">
                    <LoadingButton
                      loading={resolveSubscriptionLoading}
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={async () => {
                        setresolveSubscriptionLoading(true);
                        await dispatch(
                          resolveSubscription({
                            subscriptionId: subscriptionToResolve._id,
                          })
                        );
                        setresolveSubscriptionLoading(false);

                        window.document
                          .getElementById("markAsPaidModal")
                          .modal("hide");
                      }}
                    >
                      Mark As Paid
                    </LoadingButton>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
