import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../Components/sidebar/Sidebar";
import NavbarComponent from "../../Components/navbar/Navbar";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import { deleteSuggestion, getAllSuggestions, selectSuggestions, selectSuggestionsPager, updateAccountSearch } from "../../redux/slices/adminSlice";
import { formatTime } from "../../utils/formatTime";
import LoadingButton from "../../Components/common/LoadingButton";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet-async";

const AdminSuggestions = () => {
  const allSuggestions = useSelector(selectSuggestions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pager = useSelector(selectSuggestionsPager);
  const [suggestionToDelete, setSuggestionToDelete] = useState(0);
  const [suggestionToView, setSuggestionToView] = useState(0);

  useEffect(() => {
    dispatch(getAllSuggestions({ page: 1 }));
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <div className="p-4">
          <h2 className="mb-3">Suggestions</h2>
          <Helmet>
            <title>Admin Suggestions</title>
          </Helmet>

          {/* View Modal */}
          <div>
            <div className="modal fade" id="deletePlanModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="staticBackdropLabel">
                      Suggestion Details
                    </h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>Username: </span> {suggestionToView?.user?.username}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Email: </span> {suggestionToView?.user?.email}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Created At: </span> {formatTime(suggestionToView?.createdAt)}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Suggestion: </span> {suggestionToView?.suggestion}
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Delete Modal */}
          <div>
            <div className="modal fade" id="deletePlanModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title text-danger" id="staticBackdropLabel">
                      You are permanently deleting this suggestion
                    </h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>Suggestion: </span> {suggestionToDelete?.suggestion}
                      <br />
                      <span style={{ fontWeight: "bold" }}>Username: </span> {suggestionToDelete?.user?.username}
                      <br />
                      <span style={{ fontWeight: "bold" }}>Email: </span> {suggestionToDelete?.user?.email}
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        dispatch(
                          deleteSuggestion({
                            suggestionId: suggestionToDelete._id,
                          })
                        );
                      }}
                    >
                      Yes, Delete This Suggestion
                    </button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="page-content container p-0">
            <div class="card">
              <div className="table-responsive datatable-custom">
                <table id="datatable" className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Suggestion</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSuggestions.map((suggestion) => {
                      return (
                        <tr>
                          <td>{suggestion.user.username}</td>
                          <td>{suggestion.user.email}</td>
                          <td>{suggestion?.suggestion?.slice(0, 50) + (suggestion?.suggestion?.length > 50 ? "..." : "")}</td>
                          <td>{formatTime(suggestion.createdAt)}</td>
                          <td>
                            <i
                              data-tip
                              data-for="viewBtn"
                              class="bi bi-eye-fill icon-button text-primary"
                              style={{ marginLeft: 0 }}
                              data-bs-toggle="modal"
                              data-bs-target="#deletePlanModal2"
                              onClick={() => {
                                setSuggestionToView(suggestion);
                              }}
                            ></i>
                            <ReactTooltip id="viewBtn" effect="float" delayShow={400}>
                              <span>View</span>
                            </ReactTooltip>

                            <i
                              data-tip
                              data-for="deleteBtn"
                              class="bi bi-trash-fill icon-button text-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#deletePlanModal"
                              onClick={() => {
                                setSuggestionToDelete(suggestion);
                              }}
                            ></i>
                            <ReactTooltip id="deleteBtn" effect="float" delayShow={400}>
                              <span>Delete</span>
                            </ReactTooltip>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {pager?.total > 1 && (
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 20,
                      border: "solid",
                      borderWidth: "0px",
                      borderTopWidth: "0.1px",
                      borderColor: "var(--bs-gray-300)",
                    }}
                  >
                    <nav>
                      <ul class="pagination" style={{ margin: 0 }}>
                        <li
                          class="page-item"
                          style={{
                            margin: "0px",
                            border: "solid",
                            paddingTop: "2px",
                            borderWidth: "0.2px",
                            borderColor: "var(--bs-gray-400)",
                            borderTopLeftRadius: 6,
                            borderBottomLeftRadius: 6,
                          }}
                          onClick={() => {
                            dispatch(
                              getAllSuggestions({
                                page: pager.page <= 1 ? 1 : pager.page - 1,
                              })
                            );
                          }}
                        >
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true" style={{ fontSize: "1.3rem" }}>
                              &laquo;
                            </span>
                          </a>
                        </li>

                        <li
                          class="page-item"
                          style={{
                            padding: 15,
                            display: "flex",
                            alignItems: "center",
                            margin: "0px",
                            border: "solid",
                            borderWidth: "0px",
                            borderBottomWidth: "0.2px",
                            borderTopWidth: "0.2px",
                            borderColor: "var(--bs-gray-400)",
                          }}
                        >
                          {pager?.page} of {pager?.total}
                        </li>

                        <li
                          class="page-item"
                          style={{
                            margin: "0px",
                            border: "solid",
                            paddingTop: "2px",
                            borderWidth: "0.2px",
                            borderColor: "var(--bs-gray-400)",
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                          onClick={() => {
                            dispatch(
                              getAllSuggestions({
                                page: pager.page >= pager.total ? pager.total : pager.page + 1,
                              })
                            );
                          }}
                        >
                          <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true" style={{ fontSize: "1.3rem" }}>
                              &raquo;
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* End Content */}
        </div>
      </div>
    </div>
  );
};

export default AdminSuggestions;
