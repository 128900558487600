import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Card } from "react-bootstrap";

import SignIn from "../../Components/auth/SignIn";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";
import { Link } from "react-router-dom";

const Signin = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          defer
          async
        ></script>
      </Helmet>
      <Container className="d-flex flex-column justify-content-center align-items-center h-100">
        {/* <Helmet title="Sign In" /> */}
        <div className="text-center mt-4">
          <h2>Welcome to ProxySide</h2>
          <p className="lead">Sign in to your account to continue</p>
        </div>

        <Card className="col-sm-12 col-md-7 col-lg-5 m-4 p-4 text-start">
          <Card.Body>
            <div className="mx-sm-4 my-sm-2">
              <div className="d-flex flex-column justify-content-center align-items-center card-logo mb-2">
                <Logo />
              </div>
              <SignIn />
            </div>
          </Card.Body>
        </Card>

        <p className="signin-sub-text">
          Don't have an account yet?
          <Link className="link-to-signup" to="/signup">
            {" "}
            Sign up here
          </Link>{" "}
        </p>
      </Container>
    </>
  );
};

export default Signin;
