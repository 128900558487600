import {
  Bell,
  BookOpen,
  Calendar,
  CheckSquare,
  Grid,
  Heart,
  Layout,
  List,
  PieChart,
  Sliders,
  MapPin,
  MessageCircle,
  Share,
  FileText,
  AlertCircle,
} from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/plans",
    icon: Grid,
    title: "Purchase Plan",
  },
  {
    href: "/invoices",
    icon: FileText,
    title: "Invoices",
  },
  {
    href: "/topup-balance",
    icon: BookOpen,
    title: "Topup Balance",
  },
  {
    href: "/faq",
    icon: AlertCircle,
    title: "FAQ",
  },
  {
    href: "/terms-of-service",
    icon: CheckSquare,
    title: "TOS",
  },
  {
    href: "/suggestion",
    icon: MessageCircle,
    title: "Suggestion",
  },
];

export let adminItems = {
  href: "/admin",
  icon: Sliders,
  title: "Admin",
  // badge: "7",
  children: [
    {
      href: "/admin/settings",
      title: "Settings",
    },
    {
      href: "/admin/accounts",
      title: "Accounts",
    },
    {
      href: "/admin/plans",
      title: "Plans",
    },
    {
      href: "/admin/subscriptions",
      title: "Subscriptions",
    },
    // {
    //   href: "/admin/statistics",
    //   title: "Statistics",
    // },
    {
      href: "/admin/coupons",
      title: "Coupons",
    },
    {
      href: "/admin/suggestions",
      title: "Suggestions",
    },
  ],
};

export default pagesSection;
