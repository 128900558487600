import { Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../../../Components/common/LoadingButton";
import {
  createNewCoupon,
  editCoupon,
  getSingleCoupon,
  selectCouponToEdit,
} from "../../../redux/slices/adminSlice";
import AlertCard from "../../../Components/AlertCard";
import NavbarComponent from "../../../Components/navbar/Navbar";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";

export default function CreateNewCoupon({ type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const couponToEdit = useSelector(selectCouponToEdit);
  const couponId = params.couponId;

  useEffect(() => {
    if (type === "edit") {
      dispatch(
        getSingleCoupon({
          couponId: params.couponId,
        })
      );
    }
  }, []);

  const RegisterSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
    discount: Yup.number().required("Discount is required"),
    usages: Yup.number().required("Usage is required"),
    paymentProcessor: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      discount: "",
      usages: "",
      paymentProcessor: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);

      if (type === "edit") {
        setSubmitting(true);
        await dispatch(editCoupon({ ...values, navigate, couponId }));
        setSubmitting(false);
      } else {
        setSubmitting(true);
        await dispatch(createNewCoupon({ ...values, navigate }));
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setValues,
  } = formik;

  useEffect(() => {
    if (type === "edit" && couponToEdit !== null) {
      setValues({
        code: couponToEdit.code,
        discount: couponToEdit.discount,
        usages: couponToEdit.usages,
        paymentProcessor: couponToEdit.paymentProcessor,
      });
    }
  }, [couponToEdit]);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />

        <>
          {/* Content */}
          <div className="page-content container px-4">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-sm mb-2 mb-sm-0">
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-primary my-3"
                    style={{ fontSize: "1rem", width: "85px" }}
                  >
                    <i className="bi-chevron-left" />
                    Back
                  </button>
                  <h1 className="page-header-title">
                    {" "}
                    {type === "edit" ? "Edit" : "Create New"} Coupon
                  </h1>
                </div>
              </div>
              {/* End Row */}
            </div>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container-lg p-0">
                  {/* ID */}
                  {couponToEdit && (
                    <div className="mb-4">
                      <label className="form-label">ID</label>
                      <input
                        className="form-control form-control-lg"
                        placeholder="ID"
                        value={couponToEdit?._id}
                        disabled
                      />
                    </div>
                  )}

                  {/* code */}
                  <div className="mb-4">
                    <label className="form-label">Code</label>
                    <input
                      {...getFieldProps("code")}
                      className="form-control form-control-lg"
                      placeholder="code"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.code && errors.code)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.code}
                    </span>
                  </div>

                  {/* discount */}
                  <div className="mb-4">
                    <label className="form-label">Discount (%)</label>
                    <input
                      type="number"
                      {...getFieldProps("discount")}
                      className="form-control form-control-lg"
                      placeholder="discount"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.discount && errors.discount)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.discount}
                    </span>
                  </div>

                  {/* usages */}
                  <div className="mb-4">
                    <label className="form-label">Usages</label>
                    <input
                      type="number"
                      {...getFieldProps("usages")}
                      className="form-control form-control-lg"
                      placeholder="usages"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.usages && errors.usages)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.usages}
                    </span>
                  </div>

                  {/* paymentProcessor */}
                  <div className="mb-4">
                    <label className="form-label">Payment Processor</label>
                    <select
                      {...getFieldProps("paymentProcessor")}
                      className="form-select form-control form-control-lg"
                      placeholder="paymentProcessor"
                    >
                      <option value="" selected="selected" disabled="">
                        Select payment Processor
                      </option>
                      <option value="cryptocurrency">Cryptocurrency</option>
                      <option value="balance">Balance</option>
                    </select>
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.type && errors.type)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.paymentProcessor}
                    </span>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  {/* Sign in */}
                  <div>
                    <LoadingButton type="submit" className="btn btn-primary ">
                      Save
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </>
      </div>
    </div>
  );
}
