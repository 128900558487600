// TODO: change on deploy
let tempbackendServerBaseURL = "http://localhost:7400/api/v1";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("dev");
} else {
  // TODO: deploy update
  // TODO: update to target domain name
  tempbackendServerBaseURL = "https://socks5.cloud/api/v1";
}

export let backendServerBaseURL = tempbackendServerBaseURL;
