import { useNavigate } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";

export default function PasswordChangedSuccessfully() {
  const navigate = useNavigate();

  return (
    <>
      {/* ========== MAIN CONTENT ========== */}
      <Container className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
        <div
          className="position-fixed top-0 end-0 start-0 bg-img-start"
          style={{
            height: "32rem",
            backgroundImage: "url(/static/svg/components/card-6.svg)",
          }}
        ></div>
        {/* Content */}
        <a className="d-flex justify-content-center mb-5" href="/">
          <Logo />
        </a>
        {/* Card */}
        <Card className="col-sm-12 col-md-7 col-lg-5 m-4 p-4 text-center">
          <Card.Body>
            <h1 className="display-5">
              Password Changed <br /> Successfully
            </h1>
            <p className="mb-1">We've updated your password</p>

            <div className="mt-4 mb-3">
              <a className="btn btn-primary" href="/signin">
                Login
              </a>
            </div>
          </Card.Body>
        </Card>
        {/* End Card */}
        {/* End Content */}
      </Container>
      {/* ========== END MAIN CONTENT ========== */}
    </>
  );
}
