import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { getMe } from "./generalSlice";

const initialState = {
  allPlans: [],
  planToEdit: null,

  settings: null,

  accounts: [],
  accountsPager: null,
  accountSearch: "",
  viewAccountInfo: null,
  singleAccountSessions: [],
  singleAccountSessionsPager: [],
  singleAccountRewards: [],
  singleAccountRewardsPager: [],

  suggestions: [],
  suggestionsPager: null,

  coupons: [],
  couponsPager: null,
  couponToEdit: null,

  // Subscriptions
  subscriptions: [],
  subscriptionsPager: null,
  subscriptionsSearch: "",
  subscriptionToEdit: null,

  // Statistics
  statistics: null,
};

// Plans
export const getSinglePlan = createAsyncThunk("admin/getSinglePlan", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/plans/${payload.planId}`, config);

  if (response.status === 200 && response.data.message === "Plan") {
    thunkAPI.dispatch(updatePlanToEdit(response.data.payload));
  }
});

export const getAllPlans = createAsyncThunk("admin/getAllPlans", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/plans`, config);

  if (response.status === 200 && response.data.message === "Plans list") {
    thunkAPI.dispatch(updateAllPlans(response.data.payload.plans));
  }
});

export const createPlan = createAsyncThunk("admin/createPlan", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/admin/plans`,
    {
      title: payload.title,
      description: payload.description,
      type: payload.type,
      provider: payload.provider,
      price: payload.price,
      bandwidthPrices: JSON.stringify(payload.bandwidthPrices),
      threadPrices: JSON.stringify(payload.threadPrices),
      minRenewDays: payload.minRenewDays,
      maxRenewDays: payload.maxRenewDays,
      duration: payload.duration,
      bandwidth: payload.bandwidth,
      threads: payload.threads,
      enabled: payload.status,
      trial: payload.trial,
      order: payload.order,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Plan created successfully") {
    payload.navigate("/admin/plans");
  }

  if (response.status === 200 && response.data.message === "error") {
    // payload.setErrors({ afterSubmit: response.data.errors[0] });
  }
});

export const editPlan = createAsyncThunk("admin/editPlan", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.patch(
    `${backendServerBaseURL}/admin/plans/${payload.planId}`,
    {
      title: payload.title,
      description: payload.description,
      type: payload.type,
      provider: payload.provider,
      price: payload.price,
      bandwidthPrices: JSON.stringify(payload.bandwidthPrices),
      threadPrices: JSON.stringify(payload.threadPrices),
      minRenewDays: payload.minRenewDays,
      maxRenewDays: payload.maxRenewDays,
      duration: payload.duration,
      bandwidth: payload.bandwidth,
      threads: payload.threads,
      enabled: payload.status,
      trial: payload.trial,
      order: payload.order,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Plan updated successfully") {
    payload.navigate("/admin/plans");
  }

  if (response.status === 200 && response.data.message === "error") {
    // payload.setErrors({ afterSubmit: response.data.errors[0] });
  }
});

export const deletePlan = createAsyncThunk("admin/deletePlan", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.delete(`${backendServerBaseURL}/admin/plans/${payload.planId}`, config);

  if (response.status === 200 && response.data.message === "Plan deleted successfully") {
    thunkAPI.dispatch(getAllPlans());
    // payload.reset();
  }

  if (response.status === 200 && response.data.message === "error") {
    // payload.setErrors({ afterSubmit: response.data.errors[0] });
  }
});

// Settings
export const getAllSettings = createAsyncThunk("admin/getAllSettings", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/settings`, config);

  if (response.status === 200 && response.data.message === "Settings list") {
    thunkAPI.dispatch(updateAllSettings(response.data.payload.settings));
  }
});

export const updateSettings = createAsyncThunk("admin/updateSettings", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let body = payload;
  if (typeof payload["FAQ"] !== "string") {
    body["FAQ"] = JSON.stringify(payload["FAQ"]);
    body["Status"] = JSON.stringify(payload["Status"]);
    body["CustomPrices"] = JSON.stringify(payload["CustomPrices"]);
    body["CustomBandwidthPrices"] = JSON.stringify(payload["CustomBandwidthPrices"]);
    body["RenewThreadsPrices"] = JSON.stringify(payload["RenewThreadsPrices"]);
    body["CountriesAndPorts"] = JSON.stringify(payload["CountriesAndPorts"]);
    body["RewardRates"] = JSON.stringify(payload["RewardRates"]);
    body["ResellerRequestQuestions"] = JSON.stringify(payload["ResellerRequestQuestions"]);
    body["ResellerThreadsPrices"] = JSON.stringify(payload["ResellerThreadsPrices"]);
    body["ResellerBandwidthPrices"] = JSON.stringify(payload["ResellerBandwidthPrices"]);
  }

  let response = await axios.put(`${backendServerBaseURL}/admin/settings`, body, config);

  if (response.status === 200 && response.data.message === "Setting updated successfully") {
    thunkAPI.dispatch(updateAllSettings(response.data.payload.settings));
    payload.notify();
  }

  if (response.status === 200 && response.data.message === "error") {
    // payload.setErrors({ afterSubmit: response.data.errors[0] });
  }
});

// Accounts
export const getAllAccounts = createAsyncThunk("admin/getAllAccounts", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
      search: thunkAPI.getState().admin.accountSearch,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/accounts`, config);

  if (response.status === 200 && response.data.message === "Accounts list") {
    thunkAPI.dispatch(updateAccounts(response.data.payload.accounts));
    thunkAPI.dispatch(updateAccountsPager(response.data.pager));
  }
});

export const getSingleAccount = createAsyncThunk("admin/getSingleAccount", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/accounts/${payload.userId}`, config);

  if (response.status === 200 && response.data.message === "Account") {
    thunkAPI.dispatch(updateViewAccountInfo(response.data.payload));
  }
});

export const createNewAccount = createAsyncThunk("admin/createNewAccount", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/admin/accounts`,
    {
      username: payload.username,
      email: payload.email,
      password: payload.password,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Account created successfully") {
    payload.navigate("/admin/accounts");
  }
});

export const editAccount = createAsyncThunk("admin/editAccount", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let body = {
    username: payload.username,
    email: payload.email,
    password: payload.password,
    manager: payload.manager,
    reseller: payload.reseller,
    cryptoPaymentProcessorEnabled: payload.cryptoPaymentProcessorEnabled,
    balancePaymentProcessorEnabled: payload.balancePaymentProcessorEnabled,
  };

  let response = await axios.patch(`${backendServerBaseURL}/admin/accounts/${payload.userId}`, body, config);

  if (response.status === 200 && response.data.message === "Account updated successfully") {
    payload.navigate("/admin/accounts");
    thunkAPI.dispatch(getMe());
  }
});

export const deleteAccount = createAsyncThunk("admin/deleteAccount", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.delete(`${backendServerBaseURL}/admin/accounts/${payload.userId}`, config);

  if (response.status === 200 && response.data.message === "Account deleted successfully") {
    thunkAPI.dispatch(getAllAccounts({ page: thunkAPI.getState().admin.accountsPager.page }));
  }
});

export const updateAccountBalance = createAsyncThunk("admin/updateAccountBalance", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.patch(
    `${backendServerBaseURL}/admin/accounts/${payload.userId}/balance`,
    {
      balanceToAddOrSub: payload.balanceToAddOrSub,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Account balance updated successfully") {
    payload.navigate(`/admin/accounts/${response.data.payload._id}`);
  }
});

export const addNewSubscriptionToAccount = createAsyncThunk("admin/addNewSubscriptionToAccount", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/admin/accounts/${payload.userId}/add-subscription`,
    {
      plan: payload.plan,
      region: payload.region,
      threads: payload.threads,
      bandwidth: payload.bandwidth,
      days: payload.days,
    },
    config
  );

  if (response.status === 200 && response.data.message === "New subscription created successfully") {
    payload.navigate(`/admin/subscriptions`);
  }
});

export const getSingleAccountSessions = createAsyncThunk("admin/getSingleAccountSessions", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/accounts/${payload.userId}/sessions`, config);

  if (response.status === 200 && response.data.message === "Sessions list") {
    thunkAPI.dispatch(updateSingleAccountSessions(response.data.payload.sessions));
    thunkAPI.dispatch(updateSingleAccountSessionsPager(response.data.pager));
  }
});

export const getSingleAccountRewards = createAsyncThunk("admin/getSingleAccountRewards", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/accounts/${payload.userId}/loyalty-rewards`, config);

  if (response.status === 200 && response.data.message === "Rewards list") {
    thunkAPI.dispatch(updatesingleAccountRewards(response.data.payload.rewards));
    thunkAPI.dispatch(updatesingleAccountRewardsPager(response.data.pager));
  }
});

export const disable2FA = createAsyncThunk("admin/deleteAccount", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.delete(`${backendServerBaseURL}/admin/accounts/${payload.userId}/disable-2fa`, config);

  if (response.status === 200 && response.data.message === "2FA disabled successfully") {
    window.location.reload();
  }
});

// Suggestions
export const getAllSuggestions = createAsyncThunk("admin/getAllSuggestions", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/suggestions`, config);

  if (response.status === 200 && response.data.message === "Suggestions list") {
    thunkAPI.dispatch(updateSuggestions(response.data.payload.suggestions));
    thunkAPI.dispatch(updateSuggestionsPager(response.data.pager));
  }
});

export const deleteSuggestion = createAsyncThunk("admin/deleteSuggestion", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.delete(`${backendServerBaseURL}/admin/suggestions/${payload.suggestionId}`, config);

  if (response.status === 200 && response.data.message === "Suggestion deleted successfully") {
    thunkAPI.dispatch(getAllSuggestions({ page: thunkAPI.getState().admin.suggestionsPager.page }));
  }
});

// Coupons
export const getSingleCoupon = createAsyncThunk("admin/getSingleCoupon", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/coupons/${payload.couponId}`, config);

  if (response.status === 200 && response.data.message === "Coupon") {
    thunkAPI.dispatch(updateCouponToEdit(response.data.payload));
  }
});

export const createNewCoupon = createAsyncThunk("admin/createNewCoupon", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/admin/coupons`,
    {
      code: payload.code,
      usages: payload.usages,
      discount: payload.discount,
      paymentProcessor: payload.paymentProcessor,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Coupon created successfully") {
    payload.navigate("/admin/coupons");
  }
});

export const editCoupon = createAsyncThunk("admin/editCoupon", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let body = {
    code: payload.code,
    usages: payload.usages,
    discount: payload.discount,
    paymentProcessor: payload.paymentProcessor,
  };

  let response = await axios.patch(`${backendServerBaseURL}/admin/coupons/${payload.couponId}`, body, config);

  if (response.status === 200 && response.data.message === "Coupon updated successfully") {
    payload.navigate("/admin/coupons");
  }
});

export const getAllCoupons = createAsyncThunk("admin/getAllCoupons", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/coupons`, config);

  if (response.status === 200 && response.data.message === "Coupons list") {
    thunkAPI.dispatch(updateCoupons(response.data.payload.coupons));
    thunkAPI.dispatch(updateCouponsPager(response.data.pager));
  }
});

export const deleteCoupon = createAsyncThunk("admin/deleteCoupon", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.delete(`${backendServerBaseURL}/admin/coupons/${payload.couponId}`, config);

  if (response.status === 200 && response.data.message === "Coupon deleted successfully") {
    thunkAPI.dispatch(getAllCoupons({ page: thunkAPI.getState().admin.couponsPager.page }));
  }
});

// Subscriptions
export const getSingleSubscription = createAsyncThunk("admin/getSingleSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}`, config);

  if (response.status === 200 && response.data.message === "Subscription") {
    thunkAPI.dispatch(updateSubscriptionToEdit(response.data.payload.subscription));
  }
});

export const editSubscription = createAsyncThunk("admin/editSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let body = {
    duration: payload.duration,
  };

  let response = await axios.patch(`${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}`, body, config);

  if (response.status === 200 && response.data.message === "Subscription updated successfully") {
    payload.navigate("/admin/subscriptions");
  }
});

export const getAllSubscriptions = createAsyncThunk("admin/getAllSubscriptions", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
      search: thunkAPI.getState().admin.subscriptionsSearch,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/subscriptions`, config);

  if (response.status === 200 && response.data.message === "Subscriptions list") {
    thunkAPI.dispatch(updateSubscriptions(response.data.payload.subscriptions));
    thunkAPI.dispatch(updateSubscriptionsPager(response.data.pager));
  }
});

export const deleteSubscription = createAsyncThunk("admin/deleteSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      deleteOnlyRenew: payload.deleteOnlyRenew,
    },
  };

  let response = await axios.delete(`${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}`, config);

  if (response.status === 200 && response.data.message === "Subscription deleted successfully") {
    thunkAPI.dispatch(getAllSubscriptions({ page: thunkAPI.getState().admin.subscriptionsPager.page }));
    thunkAPI.dispatch(getMe());
  }
});

export const resolveSubscription = createAsyncThunk("admin/resolveSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let body = {};

  let response = await axios.patch(`${backendServerBaseURL}/admin/subscriptions/${payload.subscriptionId}/resolve`, body, config);

  if (response.status === 200 && response.data.message === "Subscription updated successfully") {
    thunkAPI.dispatch(getAllSubscriptions({ page: thunkAPI.getState().admin.subscriptionsPager.page }));
    thunkAPI.dispatch(getMe());
  }
});

// Statistics
export const getStatistics = createAsyncThunk("admin/getStatistics", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/statistics`, config);

  if (response.status === 200 && response.data.message === "Statistics") {
    thunkAPI.dispatch(updateStatistics(response.data.payload.statistics));
  }
});

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateAllPlans: (state, action) => {
      state.allPlans = action.payload;
    },
    updatePlanToEdit: (state, action) => {
      state.planToEdit = action.payload;
    },

    updateAllSettings: (state, action) => {
      state.settings = action.payload;
    },

    updateAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    updateAccountsPager: (state, action) => {
      state.accountsPager = action.payload;
    },
    updateAccountSearch: (state, action) => {
      state.accountSearch = action.payload;
    },
    updateViewAccountInfo: (state, action) => {
      state.viewAccountInfo = action.payload;
    },
    updateSingleAccountSessions: (state, action) => {
      state.singleAccountSessions = action.payload;
    },
    updateSingleAccountSessionsPager: (state, action) => {
      state.singleAccountSessionsPager = action.payload;
    },
    updatesingleAccountRewards: (state, action) => {
      state.singleAccountRewards = action.payload;
    },
    updatesingleAccountRewardsPager: (state, action) => {
      state.singleAccountRewardsPager = action.payload;
    },

    updateSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    updateSuggestionsPager: (state, action) => {
      state.suggestionsPager = action.payload;
    },

    updateCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    updateCouponsPager: (state, action) => {
      state.couponsPager = action.payload;
    },
    updateCouponToEdit: (state, action) => {
      state.couponToEdit = action.payload;
    },

    // Subscriptions
    updateSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    updateSubscriptionsPager: (state, action) => {
      state.subscriptionsPager = action.payload;
    },
    updateSubscriptionsSearch: (state, action) => {
      state.subscriptionsSearch = action.payload;
    },
    updateSubscriptionToEdit: (state, action) => {
      state.subscriptionToEdit = action.payload;
    },

    // Statistics
    updateStatistics: (state, action) => {
      state.statistics = action.payload;
    },
  },
});

export const {
  updateAllPlans,
  updatePlanToEdit,

  updateAllSettings,

  updateAccounts,
  updateAccountsPager,
  updateAccountSearch,
  updateViewAccountInfo,
  updateSingleAccountSessions,
  updateSingleAccountSessionsPager,
  updatesingleAccountRewards,
  updatesingleAccountRewardsPager,

  updateSuggestions,
  updateSuggestionsPager,

  updateCoupons,
  updateCouponsPager,
  updateCouponToEdit,

  updateSubscriptions,
  updateSubscriptionsPager,
  updateSubscriptionsSearch,
  updateSubscriptionToEdit,

  updateStatistics,
} = adminSlice.actions;

export const selectAllPlans = (state) => state.admin.allPlans;
export const selectPlanToEdit = (state) => state.admin.planToEdit;

export const selectSettings = (state) => state.admin.settings;

export const selectAccounts = (state) => state.admin.accounts;
export const selectAccountsPager = (state) => state.admin.accountsPager;
export const selectAccountSearch = (state) => state.admin.accountSearch;
export const selectViewAccountInfo = (state) => state.admin.viewAccountInfo;
export const selectSingleAccountSessions = (state) => state.admin.singleAccountSessions;
export const selectSingleAccountSessionsPager = (state) => state.admin.singleAccountSessionsPager;
export const selectsingleAccountRewards = (state) => state.admin.singleAccountRewards;
export const selectsingleAccountRewardsPager = (state) => state.admin.singleAccountRewardsPager;

export const selectSuggestions = (state) => state.admin.suggestions;
export const selectSuggestionsPager = (state) => state.admin.suggestionsPager;

export const selectCoupons = (state) => state.admin.coupons;
export const selectCouponsPager = (state) => state.admin.couponsPager;
export const selectCouponToEdit = (state) => state.admin.couponToEdit;

export const selectCheckerInfo = (state) => state.admin.checkerInfo;
export const selectAuditLogs = (state) => state.admin.auditLogs;
export const selectAuditLogsPager = (state) => state.admin.auditLogsPager;
export const selectAuditsSearch = (state) => state.admin.auditsSearch;

// Subscriptions
export const selectSubscriptions = (state) => state.admin.subscriptions;
export const selectSubscriptionsPager = (state) => state.admin.subscriptionsPager;
export const selectSubscriptionsSearch = (state) => state.admin.subscriptionsSearch;
export const selectSubscriptionToEdit = (state) => state.admin.subscriptionToEdit;

// Statistics
export const selectStatistics = (state) => state.admin.statistics;

export default adminSlice.reducer;
