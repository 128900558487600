import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertCard from "../Components/AlertCard";
import NavbarComponent from "../Components/navbar/Navbar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import Sidebar from "../Components/sidebar/Sidebar";
import {
  getAllInvoices,
  selectAllInvoices,
  selectInvoicesPager,
  updateInvoicesSearch,
} from "../redux/slices/publicSlice";
import { formatTime } from "../utils/formatTime";

const Invoices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allInvoices = useSelector(selectAllInvoices);
  const pager = useSelector(selectInvoicesPager);
  const [loading, setloading] = useState(true);
  const onSearchChangeHandler = useCallback(
    debounce((e) => {
      dispatch(updateInvoicesSearch(e.target.value));
      dispatch(getAllInvoices({ page: 1 }));
    }, 500),
    []
  );

  useEffect(async () => {
    setloading(true);
    await dispatch(getAllInvoices({ page: 1 }));
    setloading(false);
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />

        <>
          <Helmet>
            <title>Invoices - KocerRoxy</title>
          </Helmet>

          {loading && (
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-center"
              style={{ height: "80%" }}
            >
              <div class="spinner-border text-primary mb-3" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p>Please Wait</p>
            </div>
          )}

          {!loading && (
            <>
              {/* Content */}
              <div className="page-content container">
                <div class="card">
                  <div className="table-responsive datatable-custom">
                    <div style={{ padding: 16 }}>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Search by Invoice ID or Plan Name"
                        onChange={(e) => {
                          onSearchChangeHandler(e);
                        }}
                      />
                    </div>

                    <table
                      id="datatable"
                      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Invoice Id</th>
                          <th>Plan Name</th>
                          <th>Status</th>
                          <th>Type</th>
                          <th>Payment Status</th>
                          <th>Price</th>
                          <th>Bandwidth</th>
                          <th>Processor</th>
                          <th>Created At</th>
                          <th>Invoice Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allInvoices.map((invoice, index) => {
                          return (
                            <tr>
                              <td>{invoice._id}</td>
                              <td>
                                {invoice.trial && "[Trial]"} {invoice.planName}
                              </td>
                              <td>
                                {/* Toptup */}
                                {invoice.topup === true && (
                                  <>
                                    {invoice.unresolvedStatus == null &&
                                      invoice.paid && (
                                        <span
                                          style={{ color: "var(--bs-green)" }}
                                        >
                                          Paid
                                        </span>
                                      )}
                                    {invoice.unresolvedStatus == null &&
                                      !invoice.paid &&
                                      !invoice.pending && (
                                        <span
                                          style={{ color: "var(--bs-danger)" }}
                                        >
                                          Unpaid
                                        </span>
                                      )}
                                    {invoice.unresolvedStatus == null &&
                                      !invoice.paid &&
                                      invoice.pending && (
                                        <span
                                          style={{ color: "var(--bs-info)" }}
                                        >
                                          Pending
                                        </span>
                                      )}
                                    {!invoice.paid &&
                                      !invoice.pending &&
                                      invoice.unresolvedStatus != null && (
                                        <>
                                          <span
                                            style={{
                                              color: "var(--bs-warning)",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {invoice.unresolvedStatus.toLowerCase()}
                                          </span>
                                        </>
                                      )}
                                  </>
                                )}

                                {/* Renewal */}
                                {invoice.renew === true && (
                                  <>
                                    {invoice.unresolvedStatus == null &&
                                      invoice.paid && (
                                        <span
                                          style={{ color: "var(--bs-green)" }}
                                        >
                                          Renewed
                                        </span>
                                      )}
                                    {invoice.unresolvedStatus == null &&
                                      !invoice.paid &&
                                      !invoice.pending && (
                                        <span
                                          style={{ color: "var(--bs-danger)" }}
                                        >
                                          Unpaid
                                        </span>
                                      )}
                                    {invoice.unresolvedStatus == null &&
                                      !invoice.paid &&
                                      invoice.pending && (
                                        <span
                                          style={{ color: "var(--bs-info)" }}
                                        >
                                          Pending
                                        </span>
                                      )}
                                    {!invoice.paid &&
                                      !invoice.pending &&
                                      invoice.unresolvedStatus != null && (
                                        <>
                                          <span
                                            style={{
                                              color: "var(--bs-warning)",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {invoice.unresolvedStatus.toLowerCase()}
                                          </span>
                                        </>
                                      )}
                                  </>
                                )}

                                {/* Subscription with renewal data */}
                                {invoice.renew === false &&
                                  invoice.topup === false &&
                                  Object.keys(invoice).includes(
                                    "renewalDate"
                                  ) &&
                                  invoice.renewalDate && (
                                    <>
                                      {moment(invoice?.renewalDate).diff(
                                        moment().utc(),
                                        "seconds"
                                      ) > 0 ? (
                                        <span
                                          style={{ color: "var(--bs-blue)" }}
                                        >
                                          Ends in{" "}
                                          {moment
                                            .duration(
                                              moment(invoice?.renewalDate).diff(
                                                moment().utc(),
                                                "seconds"
                                              ) * 1000
                                            )
                                            .humanize()}
                                        </span>
                                      ) : (
                                        <span
                                          style={{ color: "var(--bs-info)" }}
                                        >
                                          Expired
                                        </span>
                                      )}
                                    </>
                                  )}

                                {/* Subscription without renewal data */}
                                {invoice.renew === false &&
                                  invoice.topup === false &&
                                  !Object.keys(invoice).includes(
                                    "renewalDate"
                                  ) && (
                                    <>
                                      {invoice.unresolvedStatus == null &&
                                        invoice.paid && (
                                          <span
                                            style={{
                                              color: "var(--bs-danger)",
                                            }}
                                          >
                                            Not Activated
                                          </span>
                                        )}
                                      {invoice.unresolvedStatus == null &&
                                        !invoice.paid &&
                                        !invoice.pending && (
                                          <span
                                            style={{
                                              color: "var(--bs-danger)",
                                            }}
                                          >
                                            Unpaid
                                          </span>
                                        )}
                                      {invoice.unresolvedStatus == null &&
                                        !invoice.paid &&
                                        invoice.pending && (
                                          <span
                                            style={{ color: "var(--bs-info)" }}
                                          >
                                            Pending
                                          </span>
                                        )}
                                      {!invoice.paid &&
                                        !invoice.pending &&
                                        invoice.unresolvedStatus != null && (
                                          <>
                                            <span
                                              style={{
                                                color: "var(--bs-warning)",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {invoice.unresolvedStatus.toLowerCase()}
                                            </span>
                                          </>
                                        )}
                                    </>
                                  )}
                              </td>
                              <td>{invoice.type}</td>
                              <td>
                                {invoice.unresolvedStatus == null &&
                                  invoice.paid && (
                                    <>
                                      <span className="legend-indicator bg-success" />
                                      <span
                                        style={{ color: "var(--bs-green)" }}
                                      >
                                        Paid
                                      </span>
                                    </>
                                  )}
                                {invoice.unresolvedStatus == null &&
                                  !invoice.paid &&
                                  !invoice.pending && (
                                    <>
                                      <span className="legend-indicator bg-danger" />
                                      <span
                                        style={{ color: "var(--bs-danger)" }}
                                      >
                                        Unpaid
                                      </span>
                                    </>
                                  )}
                                {invoice.unresolvedStatus == null &&
                                  !invoice.paid &&
                                  invoice.pending && (
                                    <>
                                      <span className="legend-indicator bg-info" />
                                      <span style={{ color: "var(--bs-info)" }}>
                                        Pending
                                      </span>
                                    </>
                                  )}
                                {!invoice.paid &&
                                  !invoice.pending &&
                                  invoice.unresolvedStatus != null && (
                                    <>
                                      <span className="legend-indicator bg-warning" />
                                      <span
                                        style={{
                                          color: "var(--bs-warning)",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {invoice.unresolvedStatus.toLowerCase()}
                                      </span>
                                    </>
                                  )}
                              </td>
                              <td className="text-success">${invoice.price}</td>
                              <td>
                                {invoice.topup === false &&
                                  (invoice.bandwidth !== 0
                                    ? `${invoice.bandwidth} GB`
                                    : "Unlimited")}
                                {invoice.topup === true && "-"}
                              </td>
                              <td style={{ color: "var(--bs-cyan)" }}>
                                {invoice.paymentProcessor.toUpperCase()}
                              </td>
                              <td>{formatTime(invoice.createdAt)}</td>
                              <td>
                                {invoice.invoiceLink ? (
                                  <a
                                    onClick={() => {
                                      window.open(
                                        invoice.invoiceLink,
                                        "_blank"
                                      );
                                    }}
                                    className="link-primary"
                                    style={{ cursor: "pointer" }}
                                  >
                                    View Invoice
                                  </a>
                                ) : (
                                  <>-</>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {pager?.total > 1 && (
                      <div
                        className="container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: 20,
                          border: "solid",
                          borderWidth: "0px",
                          borderTopWidth: "0.1px",
                          borderColor: "var(--bs-gray-300)",
                        }}
                      >
                        <nav>
                          <ul class="pagination" style={{ margin: 0 }}>
                            <li
                              class="page-item"
                              style={{
                                margin: "0px",
                                border: "solid",
                                paddingTop: "2px",
                                borderWidth: "0.2px",
                                borderColor: "var(--bs-gray-400)",
                                borderTopLeftRadius: 6,
                                borderBottomLeftRadius: 6,
                              }}
                              onClick={() => {
                                dispatch(
                                  getAllInvoices({
                                    page: pager.page <= 1 ? 1 : pager.page - 1,
                                  })
                                );
                              }}
                            >
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                              >
                                <span
                                  aria-hidden="true"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  &laquo;
                                </span>
                              </a>
                            </li>

                            <li
                              class="page-item"
                              style={{
                                padding: 15,
                                display: "flex",
                                alignItems: "center",
                                margin: "0px",
                                border: "solid",
                                borderWidth: "0px",
                                borderBottomWidth: "0.2px",
                                borderTopWidth: "0.2px",
                                borderColor: "var(--bs-gray-400)",
                              }}
                            >
                              {pager?.page} of {pager?.total}
                            </li>

                            <li
                              class="page-item"
                              style={{
                                margin: "0px",
                                border: "solid",
                                paddingTop: "2px",
                                borderWidth: "0.2px",
                                borderColor: "var(--bs-gray-400)",
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6,
                              }}
                              onClick={() => {
                                dispatch(
                                  getAllInvoices({
                                    page:
                                      pager.page >= pager.total
                                        ? pager.total
                                        : pager.page + 1,
                                  })
                                );
                              }}
                            >
                              <a class="page-link" href="#" aria-label="Next">
                                <span
                                  aria-hidden="true"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  &raquo;
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* End Content */}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Invoices;
