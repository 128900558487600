import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import axios from "axios";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";

const ProcessVerifyEmail = () => {
  const params = useParams();
  const verifyToken = params.verifyToken;
  const [serverError, setserverError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    sendVerificationLink();
  }, []);

  const sendVerificationLink = async () => {
    setserverError(null);

    await axios
      .post(`${backendServerBaseURL}/auth/verify-email`, {
        verifyToken: verifyToken,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.message === "Email verified successfully") {
          // Link sent successfully
        }
      })
      .catch((error) => {
        try {
          console.log(error);
          if (error.response.status === 400) {
            setserverError(error.response.data.message);
          }
        } catch (e) {
          console.log(e);
        }
      });
  };

  return (
    <div>
      {" "}
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 end-0 start-0 bg-img-start"
          style={{
            height: "32rem",
            backgroundImage: "url(/static/svg/components/card-6.svg)",
          }}
        ></div>
        {/* Content */}
        <div className="container py-5 py-sm-7">
          <a className="d-flex justify-content-center mb-5" href="/">
            <Logo />
          </a>
          <div className="mx-auto" style={{ maxWidth: "30rem" }}>
            {/* Card */}
            <div className="card card-lg mb-5">
              <div className="card-body text-center">
                {/* Errors from server */}
                {serverError && (
                  <div className="alert alert-danger" role="alert">
                    {serverError}
                  </div>
                )}

                <h1 className="display-5">Your Email Is Verified Successfully</h1>
                <p className="mb-1">We've verified your email address</p>
                <p>Please log in to your account</p>

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  Login
                </button>
              </div>
            </div>
            {/* End Card */}
          </div>
        </div>
        {/* End Content */}
      </main>
    </div>
  );
};

export default ProcessVerifyEmail;
