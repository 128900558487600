import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";

const VerifyEmail = () => {
  const params = useParams();

  return (
    <div>
      {" "}
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 end-0 start-0"
          //   style={{
          //     height: "32rem",
          //     backgroundImage: "url(/static/svg/components/card-6.svg)",
          //   }}
        >
          {/* Shape */}
          {/* <div className="shape shape-bottom zi-1">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1921 273"
            >
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </div> */}
          {/* End Shape */}
        </div>
        {/* Content */}
        <div className="container py-5 py-sm-7">
          <a className="d-flex justify-content-center mb-5" href="/">
            {/* <img
              className="zi-2"
              src="/static/svg/logos/logo.svg"
              alt="Image Description"
              style={{ width: "8rem" }}
            /> */}
            <Logo />
          </a>
          <div className="mx-auto" style={{ maxWidth: "30rem" }}>
            {/* Card */}
            <div className="card card-lg mb-5">
              <div className="card-body text-center">
                <div className="mb-4">
                  {/* <img
                    className="avatar avatar-xxl avatar-4x3"
                    src={
                      themeContextValue.selectedTheme === "dark"
                        ? "/static/svg/illustrations-light/oc-email-verification.svg"
                        : "/static/svg/illustrations/oc-email-verification.svg"
                    }
                    alt="Image Description"
                    data-hs-theme-appearance="default"
                  /> */}
                </div>
                <h1 className="display-5">Verify your email</h1>
                <p className="mb-1">We've sent a link to your email address</p>
                <p>Please follow the link inside to continue.</p>

                {/* <p>
                  Didn't receive an email?
                  <a
                    onClick={sendVerificationLink}
                    style={{ fontWeight: "bold" }}
                  >
                    Primary link
                  </a>
                </p> */}

                {/* Errors from server */}
                {/* {serverError && (
                  <div className="alert alert-danger" role="alert">
                    {serverError}
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
        {/* End Content */}
      </main>
    </div>
  );
};

export default VerifyEmail;
