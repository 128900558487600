import React from "react";
import { useDispatch } from "react-redux";
import { deleteAccount } from "../../../redux/slices/adminSlice";

function DeleteAccountDialog({ accountToDelete }) {
  const dispatch = useDispatch();

  return (
    <div>
      <div
        className="modal fade"
        id="deletePlanModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-danger" id="staticBackdropLabel">
                You are permanently deleting this account
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p>
                <span style={{ fontWeight: "bold" }}>ID: </span>{" "}
                {accountToDelete?._id}
                <br />
                <span style={{ fontWeight: "bold" }}>Username: </span>{" "}
                {accountToDelete?.username}
                <br />
                <span style={{ fontWeight: "bold" }}>Email: </span>{" "}
                {accountToDelete?.email}
                <br />
                <span style={{ fontWeight: "bold" }}>Manager: </span>
                {accountToDelete.manager ? (
                  <span className="legend-indicator bg-success" />
                ) : (
                  <span className="legend-indicator bg-primary" />
                )}
                {accountToDelete.manager ? "Manager" : "User"}
                <br />
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  dispatch(deleteAccount({ userId: accountToDelete._id }));
                }}
              >
                Yes, Delete This Account
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccountDialog;
