import "./App.css";
import "./Assets/css/dark.css";
import MainRoutes from "./Pages/MainRoutes";
// import { HelmetProvider, Helmet } from "react-helmet-async";
import { SidebarProvider } from "./Contexts/SidebarContext";
import { ThemeProvider } from "./Contexts/ThemeContext";
import { AuthProvider } from "./Contexts/JWTContext";

function App() {
  return (
    <div className="App">
      {/* <HelmetProvider>
        <Helmet
          titleTemplate="%s | AppStack - React Admin & Dashboard Template"
          defaultTitle="AppStack - React Admin & Dashboard Template"
        /> */}
      <ThemeProvider>
        <SidebarProvider>
          <AuthProvider>
            <MainRoutes />
          </AuthProvider>
        </SidebarProvider>
      </ThemeProvider>
      {/* </HelmetProvider> */}
    </div>
  );
}

export default App;
