import React from "react";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";

const ForgotPassworkLinkSuccess = () => {
  return (
    <>
      {" "}
      <main id="content" role="main" className="main">
        <div
          className="position-fixed top-0 end-0 start-0 bg-img-start"
          style={{
            height: "32rem",
            backgroundImage: "url(/static/svg/components/card-6.svg)",
          }}
        ></div>
        {/* Content */}
        <div className="container py-5 py-sm-7">
          <a className="d-flex justify-content-center mb-5" href="/">
            <Logo />
          </a>
          <div className="mx-auto" style={{ maxWidth: "30rem" }}>
            {/* Card */}
            <div className="card card-lg mb-5">
              <div className="card-body text-center">
                <div className="mb-4">
                  {/* <img
                    className="avatar avatar-xxl avatar-4x3"
                    src={
                      themeContextValue.selectedTheme === "dark"
                        ? "/static/svg/illustrations-light/oc-email-verification.svg"
                        : "/static/svg/illustrations/oc-email-verification.svg"
                    }
                    alt="Image Description"
                    data-hs-theme-appearance="default"
                  /> */}
                </div>
                <h1 className="display-5">
                  Reset Password Link Sent Successfully
                </h1>
                <p className="mb-1">
                  We've sent a reset password link to your email address
                </p>
              </div>
            </div>
            {/* End Card */}
          </div>
        </div>
        {/* End Content */}
      </main>
    </>
  );
};

export default ForgotPassworkLinkSuccess;
