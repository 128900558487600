import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { selectViewAccountInfo, getSingleAccount, editAccount } from "../../../redux/slices/adminSlice";
import { useSelector } from "react-redux";
import LoadingButton from "../../../Components/common/LoadingButton";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import NavbarComponent from "../../../Components/navbar/Navbar";

export default function EditAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const selectAccountInfo = useSelector(selectViewAccountInfo);

  useEffect(() => {
    dispatch(getSingleAccount({ userId: params.userId }));
  }, []);

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    password: Yup.string(),
    manager: Yup.boolean().required("Manager is required"),
    reseller: Yup.boolean().required("Reseller is required"),
    cryptoPaymentProcessorEnabled: Yup.boolean().required("Crypto Payments is required"),
    balancePaymentProcessorEnabled: Yup.boolean().required("Balance Payments is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: selectAccountInfo?.username,
      email: selectAccountInfo?.email,
      password: "",
      manager: selectAccountInfo?.manager,
      reseller: selectAccountInfo?.reseller,
      cryptoPaymentProcessorEnabled: selectAccountInfo?.cryptoPaymentProcessorEnabled,
      balancePaymentProcessorEnabled: selectAccountInfo?.balancePaymentProcessorEnabled,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      await dispatch(editAccount({ ...values, navigate, userId: params.userId }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues } = formik;

  useEffect(() => {
    if (selectAccountInfo) {
      setValues({
        username: selectAccountInfo?.username,
        email: selectAccountInfo?.email,
        password: "",
        manager: selectAccountInfo?.manager,
        reseller: selectAccountInfo?.reseller,
        cryptoPaymentProcessorEnabled: selectAccountInfo?.cryptoPaymentProcessorEnabled,
        balancePaymentProcessorEnabled: selectAccountInfo?.balancePaymentProcessorEnabled,
      });
    }
  }, [selectAccountInfo]);
  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />

        <div className="px-4">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-primary my-2"
            style={{ fontSize: "1rem", width: "85px" }}
          >
            <i className="bi-chevron-left" />
            Back
          </button>{" "}
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title my-3">Edit Account</h1>
              </div>
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}
          {/* Content */}
          <div className="page-content">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container-lg p-0 m-0">
                  {/* ID */}
                  <div className="mb-4">
                    <label className="form-label">ID</label>
                    <input className="form-control form-control-lg" placeholder="ID" value={params.userId} disabled />
                  </div>

                  {/* Username */}
                  <div className="mb-4">
                    <label className="form-label">Username</label>
                    <input {...getFieldProps("username")} className="form-control form-control-lg" placeholder="Username" />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.username && errors.username) ? "block" : "none",
                      }}
                    >
                      {errors.username}
                    </span>
                  </div>

                  {/* Email */}
                  <div className="mb-4">
                    <label className="form-label">Email</label>
                    <input {...getFieldProps("email")} className="form-control form-control-lg" placeholder="Email" />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.email && errors.email) ? "block" : "none",
                      }}
                    >
                      {errors.email}
                    </span>
                  </div>

                  {/* Password */}
                  <div className="mb-4">
                    <label className="form-label">Password (leave empty to keep the same password)</label>
                    <input {...getFieldProps("password")} className="form-control form-control-lg" placeholder="Password" />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.password && errors.password) ? "block" : "none",
                      }}
                    >
                      {errors.password}
                    </span>
                  </div>

                  {/* Manager */}
                  <div className="mb-4">
                    <div className="form-check form-switch">
                      <input
                        {...getFieldProps("manager")}
                        checked={values.manager}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Manager
                      </label>
                    </div>
                  </div>

                  {/* Reseller */}
                  <div className="mb-4 d-none">
                    <div className="form-check form-switch">
                      <input
                        {...getFieldProps("reseller")}
                        checked={values.reseller}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Reseller
                      </label>
                    </div>
                  </div>

                  {/* Crpto Payments */}
                  <div className="mb-4">
                    <div className="form-check form-switch">
                      <input
                        {...getFieldProps("cryptoPaymentProcessorEnabled")}
                        checked={values.cryptoPaymentProcessorEnabled}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault2"
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                        Crpto Payments
                      </label>
                    </div>
                  </div>

                  {/* Balance Payments */}
                  <div className="mb-4">
                    <div className="form-check form-switch">
                      <input
                        {...getFieldProps("balancePaymentProcessorEnabled")}
                        checked={values.balancePaymentProcessorEnabled}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault3"
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault3">
                        Balance Payments
                      </label>
                    </div>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  {/* Sign in */}
                  <div className="hstack gap-2">
                    <LoadingButton loading={isSubmitting} type="submit" className="btn btn-primary ">
                      Save Account
                    </LoadingButton>

                    <button
                      onClick={() => {
                        navigate("/admin/accounts");
                      }}
                      className="btn btn-outline-primary text-dark"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </div>
      </div>
    </div>
  );
}
