import React from "react";
import NavbarComponent from "../../Components/navbar/Navbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import dashboardItems from "../../Components/sidebar/dashboardItems";

const Statics = () => {
  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        Statistics
      </div>
    </div>
  );
};

export default Statics;
