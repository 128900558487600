import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../../../Components/common/LoadingButton";
import { createNewAccount } from "../../../redux/slices/adminSlice";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import NavbarComponent from "../../../Components/navbar/Navbar";

export default function CreateNewAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must contain at least 8 letters"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      await dispatch(createNewAccount({ ...values, navigate }));
      setSubmitting(false);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setValues,
  } = formik;

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <div className="px-4">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-primary my-3"
            style={{ fontSize: "1rem", width: "85px" }}
          >
            <i className="bi-chevron-left" />
            Back
          </button>
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title mt-3 mb-3">
                  Create New Account
                </h1>
              </div>
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}

          {/* Content */}
          <div className="page-content">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container-lg p-0 m-0">
                  {/* Username */}
                  <div className="mb-4">
                    <label className="form-label">Username</label>
                    <input
                      {...getFieldProps("username")}
                      className="form-control form-control-lg"
                      placeholder="Enter Username"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.username && errors.username)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.username}
                    </span>
                  </div>

                  {/* Email */}
                  <div className="mb-4">
                    <label className="form-label">Email</label>
                    <input
                      {...getFieldProps("email")}
                      className="form-control form-control-lg"
                      placeholder="email@gmail.com"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.email && errors.email)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.email}
                    </span>
                  </div>

                  {/* Password */}
                  <div className="mb-4">
                    <label className="form-label">Password</label>
                    <input
                      {...getFieldProps("password")}
                      className="form-control form-control-lg"
                      placeholder="Enter Password"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(touched.password && errors.password)
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.password}
                    </span>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  {/* Sign in */}
                  <div>
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      className="btn btn-primary "
                    >
                      Create New Account
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </div>
      </div>
    </div>
  );
}
