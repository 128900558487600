import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../Components/common/LoadingButton";
import { getMe, selectMe } from "../redux/slices/generalSlice";
import { topupBalance } from "../redux/slices/publicSlice";
import NavbarComponent from "../Components/navbar/Navbar";
import Sidebar from "../Components/sidebar/Sidebar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import AlertCard from "../Components/AlertCard";

export default function Topup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(getMe());
  }, []);

  const RegisterSchema = Yup.object().shape({
    balance: Yup.string().required("Topup Amount is required"),
  });

  const formik = useFormik({
    initialValues: {
      balance: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);

      setSubmitting(true);
      await dispatch(topupBalance({ ...values, navigate }));
      setSubmitting(false);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setValues,
  } = formik;

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />
        <>
          <Helmet>
            <title>Topup</title>
          </Helmet>

          {/* Content */}
          <div className="page-content ">
            <div className="container card p-4">
              <h1>Topup</h1>
              <p>
                Topup your balance. This balance can be used to purchase new
                plans and renew subscriptions.
              </p>

              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-3 col-12">
                      <div className="pt-2 pb-2">
                        <label className="form-label text-secondary">
                          Current Balance
                        </label>
                        <h3 className="text-success">
                          ${Math.round(me?.balance * 100) / 100}
                        </h3>
                      </div>

                      {/* Balance */}
                      <div className="mb-4">
                        <label className="form-label">Enter Topup Amount</label>
                        <input
                          type="number"
                          {...getFieldProps("balance")}
                          className="form-control form-control-lg"
                          placeholder="$0.00"
                        />
                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(touched.balance && errors.balance)
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.balance}
                        </span>
                      </div>

                      {/* Errors from server */}
                      {errors.afterSubmit && (
                        <div className="alert alert-danger" role="alert">
                          {errors.afterSubmit}
                        </div>
                      )}

                      {/* Sign in */}
                      <div>
                        <LoadingButton
                          loading={isSubmitting}
                          type="submit"
                          className="btn btn-primary text-center  w-100"
                        >
                          Pay using Cryptocurrency
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
          {/* End Content */}
        </>
      </div>
    </div>
  );
}
