import React from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import NavbarComponent from "../Components/navbar/Navbar";
import Sidebar from "../Components/sidebar/Sidebar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import AlertCard from "../Components/AlertCard";

const TrialBundle = () => {
  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div>
        <NavbarComponent />
        <AlertCard />
        <Row className="px-4">
          <Col md="6" lg="4" className=" mb-3">
            <Card className="text-center h-100">
              <Card.Body className="px-5 w-lg-50">
                <h4>Coupon Code (Optional)</h4>
                <Form className="text-start">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      className="topup-input mb-3"
                      type="text"
                      placeholder="Enter Coupon Code"
                    />
                    <Form.Label className="topup-sub-text me-3">
                      Payment Gateway:
                    </Form.Label>
                    <Form.Check
                      inline
                      checked={true}
                      type="radio"
                      name="group1"
                      id={`default-radio`}
                      label={`Cryptocurrency`}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="group1"
                      id={`default-radio`}
                      label={`Balance`}
                    />
                  </Form.Group>
                </Form>

                <div>
                  <Button type="submit" className="px-5">
                    Pay
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="d-flex px-4 mb-3">
          <h1>Trial Bundles</h1>
          {/* <div className="stat-alone">
              <Grid />
            </div> */}
        </div>
        <Row className="px-4">
          <Col md="6" lg="4" className="mb-3">
            <Card className="text-center h-100">
              <Card.Body className="d-flex flex-column">
                <div className="mb-4">
                  <h5>Residential 400MB</h5>
                  <span className="display-4">400MB</span>{" "}
                  <span>bandwidth</span>
                </div>
                <h6>Includes:</h6>
                <p>
                  A huge pool of 11 Million Proxies. User:Pass and IP
                  authentication. Supported Protocols : HTTP(S)/SOCKS
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">Proxy Type - Residential</li>
                  <li className="mb-2">Duration - 2months</li>
                  <li className="mb-2">Threads - Unlimited</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="4" className="mb-3">
            <Card className="text-center h-100">
              <Card.Body className="d-flex flex-column">
                <div className="mb-4">
                  <h5>Datacenter Mixed 1 DAY</h5>
                  <span className="display-4">Unlimited</span>{" "}
                  <span>bandwidth</span>
                </div>
                <h6>Includes:</h6>
                <p>
                  Pool has 15K Proxies Rotation on each request You will receive
                  only one proxy port through which the 15K proxies will be
                  rotated. Protocols:HTTP/SOCKS
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">Proxy Type - Datacenter Mixed</li>
                  <li className="mb-2">Duration - 1 Day</li>
                  <li className="mb-2">Threads - 250</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" lg="4" className="mb-3">
            <Card className="text-center h-100">
              <Card.Body className="d-flex flex-column mb-0 pb-0">
                <div className="mb-4">
                  <h5>Datacenter USA 1 DAY</h5>
                  <span className="display-4">Unlimited</span>{" "}
                  <span>bandwidth</span>
                </div>
                <h6>Includes:</h6>
                <p>
                  Pool has 15K Proxies Rotation on each request You will receive
                  only one proxy port through which the 15K proxies will be
                  rotated. Protocols:HTTP/SOCKS
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">Proxy Type - Datacenter US</li>
                  <li className="mb-2">Duration - 1 Day</li>
                  <li className="mb-2">Threads - 250</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TrialBundle;
