import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { getMe } from "./generalSlice";
import { UnicodeDecodeB64 } from "../../utils/helpers";

const initialState = {
  activeSubscriptions: [],
  userStatistics: null,
  generatorInfo: null,

  suggestionAllowed: null,
  plans: {},
  coupons: [],
  trialPlans: {},
  singlePlanInfo: null,
  customPlanInfo: [],

  allInvoices: [],
  invoicesPager: null,
  invoicesSearch: "",

  allRewards: [],
  rewardsPager: null,
  rewardStats: null,

  mfa: null,

  targetSubscriptionInfo: null,

  discordInfo: null,

  allReferralRewards: [],
  ReferralRewardsPager: null,
  ReferralRewardStats: null,

  allRates: null,

  allSubUsers: [],
  subUsersPager: null,
  subUsersSearch: "",
  resellerDashboardData: null,

  singleSubUserInfo: null,
  subUserSubscriptions: [],
  subUserSubscriptionsPager: null,
  subUserSubscriptionsSearch: "",
};

// Dashboard
export const getDashboard = createAsyncThunk("public/getDashboard", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/public/dashboard`, config);

  if (response.status === 200 && response.data.message === "Dashboard") {
    const data = JSON.parse(UnicodeDecodeB64(response.data.payload));
    thunkAPI.dispatch(updateActiveSubscriptions(data.activeSubscriptions));
    thunkAPI.dispatch(updateUserStatistics(data.userStatistics));
  }
});

export const activateSubscription = createAsyncThunk("public/activateSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/public/activate-subscription/${payload.subscriptionId}`, config);

  if (response.status === 200 && response.data.message === "Subscription updated successfully") {
    if (response.data.payload.active === true) {
      payload.navigate(`/dashboard/generator/${payload.subscriptionId}`);
    } else {
      payload.navigate("/dashboard");
    }
  }
});

export const getProxyInfo = createAsyncThunk("public/getProxyInfo", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { country: payload.country },
  };

  try {
    let response = await axios.get(`${backendServerBaseURL}/public/generator/${payload.subscriptionId}`, config);

    if (response.status === 200 && response.data.message === "Generator") {
      thunkAPI.dispatch(updateGeneratorInfo(JSON.parse(UnicodeDecodeB64(response.data.payload))));
    }
  } catch (err) {
    console.log(err.response);
    if (err.response.data.message == "This plan is expired") {
      window.open(`/dashboard`, "_self");
    }
  }
});

export const updateProxyConfig = createAsyncThunk("public/updateProxyConfig", async (payload, thunkAPI) => {
  payload.setErrorProxyConfig(null);
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    geoRegion: payload.geoRegion,
  };

  try {
    let response = await axios.patch(`${backendServerBaseURL}/public/proxy-config/${payload.subscriptionId}`, body, config);

    if (response.status === 200 && response.data.message === "Proxy config updated successfully") {
      payload.setsuccessfullyUpdatedProxyConfig(true);
      payload.notify("Updated Successfully");
      thunkAPI.dispatch(
        getProxyInfo({
          subscriptionId: payload.subscriptionId,
          country: payload.geoRegion,
        })
      );
    }
  } catch (err) {
    console.log(err.response);
    payload.setsuccessfullyUpdatedProxyConfig(false);
    payload.setErrorProxyConfig(err.response.data.message);
    thunkAPI.dispatch(getProxyInfo({ subscriptionId: payload.subscriptionId }));
  }
});

export const getSingleSubscription = createAsyncThunk("admin/getSingleSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/public/subscriptions/${payload.subscriptionId}`, config);

  if (response.status === 200 && response.data.message === "Subscription") {
    thunkAPI.dispatch(updateTargetSubscriptionInfo(response.data.payload.subscription));
  }
});

export const deleteResidential = createAsyncThunk("public/deleteResidential", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.delete(
    `${backendServerBaseURL}/public/residential/${thunkAPI.getState().public.generatorInfo?.subscriptionInfo?.id}`,
    config
  );

  if (response.status === 200 && response.data.message === "Subscription deleted successfully") {
    payload.navigate("/dashboard");
  }
});

// Renew
export const renewSubscription = createAsyncThunk("public/renewSubscription", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    bandwidth: payload.bandwidth,
    threads: payload.threads,
    days: payload.days,
    updatingType: payload.updatingType,
    coupon: payload.coupon,
    paymentMethod: payload.paymentMethod,
  };

  try {
    let response = await axios.patch(`${backendServerBaseURL}/public/subscriptions/${payload.subscriptionId}/renew`, body, config);

    if (response.status === 200 && response.data.message === "Renewed successfully" && !Object.keys(response.data.payload).includes("linkData")) {
      payload.navigate(-1);
    }

    if (response.status === 200 && response.data.message === "Renewed successfully" && Object.keys(response.data.payload).includes("linkData")) {
      window.open(response.data.payload.linkData.hosted_url, "_self");
    }

    thunkAPI.dispatch(getMe());
  } catch (error) {
    if (error.response.status === 400) {
      payload.setErrors({ afterSubmit: error.response.data.payload.error });
    } else {
      payload.setErrors({ afterSubmit: "Something goes wrong" });
    }
  }
});

// Suggestions
export const createNewSuggestion = createAsyncThunk("public/createNewSuggestion", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/public/suggestions`,
    {
      suggestion: payload.Suggestion,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Suggestion created successfully") {
    // payload.navigate("/dashboard/public/accounts");
    window.location.reload();
  }
});

export const CheckIsSuggestionAllowed = createAsyncThunk("public/CheckIsSuggestionAllowed", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/suggestions/allowed`, config);

  if (response.status === 200 && response.data.message === "Suggestion allowed") {
    thunkAPI.dispatch(updateSuggestionAllowed(response.data.payload.allowed));
  }
});

// Reseller
export const submitBecomeResellerRequest = createAsyncThunk("public/submitBecomeResellerRequest", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/public/reseller`,
    {
      resellerRequestFormResponses: JSON.stringify(payload.resellerRequestFormResponses),
    },
    config
  );

  if (response.status === 200 && response.data.message === "Reseller request sent successfully") {
    // payload.navigate("/dashboard/public/accounts");
    window.location.reload();
  }
});

// Topup balance
export const topupBalance = createAsyncThunk("public/topupBalance", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/public/topup`,
    {
      balance: payload.balance,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Topup") {
    window.open(response.data.payload.checkoutUrl, "_self");
  }
});

// Plans
export const getAllPlans = createAsyncThunk("public/getAllPlans", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/plans`, config);

  if (response.status === 200 && response.data.message === "Plans list") {
    const data = JSON.parse(UnicodeDecodeB64(response.data.payload));
    thunkAPI.dispatch(updatePlans(data));
  }
});

export const getAllTrialPlans = createAsyncThunk("public/getAllTrialPlans", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/trial-plans`, config);

  if (response.status === 200 && response.data.message === "Trial plans list") {
    thunkAPI.dispatch(updateTrialPlans(response.data.payload));
  }
});

export const purchasePlan = createAsyncThunk("public/purchasePlan", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response = await axios.post(
      `${backendServerBaseURL}/public/plans/${payload.planId}/purchase`,
      {
        coupon: payload.coupon,
        paymentMethod: payload.paymentMethod,
        region: payload.region,
        bandwidth: payload.bandwidth,
        threads: payload.threads,
        days: payload.days,
      },
      config
    );

    if (response.status === 200 && response.data.message === "Plan purchase" && !Object.keys(response.data.payload).includes("linkData")) {
      payload.navigate("/dashboard");
    }

    if (response.status === 200 && response.data.message === "Plan purchase" && Object.keys(response.data.payload).includes("linkData")) {
      window.open(response.data.payload.linkData.hosted_url, "_self");
    }

    thunkAPI.dispatch(getMe());
  } catch (error) {
    if (error.response.status === 400) {
      payload.setErrors({ afterSubmit: error.response.data.payload.error });
    } else {
      payload.setErrors({ afterSubmit: "Something goes wrong" });
    }
  }
});

export const getCustomPlanInfo = createAsyncThunk("public/getCustomPlanInfo", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/custom-plans/prices`, config);

  if (response.status === 200 && response.data.message === "Custom plan info") {
    thunkAPI.dispatch(updateCustomPlanInfo(response.data.payload.settings));
  }
});

export const getSinglePlan = createAsyncThunk("public/getSinglePlan", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/plans/${payload.planId}`, config);

  if (response.status === 200 && response.data.message === "Plan") {
    const data = JSON.parse(UnicodeDecodeB64(response.data.payload));
    thunkAPI.dispatch(updateSinglePlanInfo(data));
  }
});

export const getCoupons = createAsyncThunk("public/getCoupons", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/coupons`, config);

  if (response.status === 200 && response.data.message === "Coupons list") {
    thunkAPI.dispatch(updateCoupons(response.data.payload.coupons));
  }
});

export const addWhitelistedIp = createAsyncThunk("public/addWhitelistedIp", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    ip: payload.ip,
  };

  try {
    let response = await axios.patch(`${backendServerBaseURL}/public/generator/${payload.subscriptionId}/add-whitelist-ip`, body, config);

    if (response.status === 200) {
      payload.notify("Updated Successfully");
      thunkAPI.dispatch(
        getProxyInfo({
          subscriptionId: payload.subscriptionId,
        })
      );
    }
  } catch (err) {
    console.log(err.response);
    thunkAPI.dispatch(getProxyInfo({ subscriptionId: payload.subscriptionId }));
    payload.notify(err.response.data.message);
    payload.setErrorProxyConfig(err.response.data.message);
  }

  payload.whitelistIpInpuRef.current.value = "";
});

export const removeWhitelistedIp = createAsyncThunk("public/removeWhitelistedIp", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    ip: payload.ip,
  };

  try {
    let response = await axios.patch(`${backendServerBaseURL}/public/generator/${payload.subscriptionId}/remove-whitelist-ip`, body, config);

    if (response.status === 200) {
      payload.notify("Updated Successfully");
      thunkAPI.dispatch(
        getProxyInfo({
          subscriptionId: payload.subscriptionId,
        })
      );
    }
  } catch (err) {
    console.log(err.response);
    payload.setErrorProxyConfig(err.response.data.message);
    thunkAPI.dispatch(getProxyInfo({ subscriptionId: payload.subscriptionId }));
  }

  payload.whitelistIpInpuRef.current.value = "";
});

// Invoices
export const getAllInvoices = createAsyncThunk("public/getAllInvoices", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
      search: thunkAPI.getState().public.invoicesSearch,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/invoices`, config);

  if (response.status === 200 && response.data.message === "Invoices list") {
    thunkAPI.dispatch(updateAllInvoices(response.data.payload.invoices));
    thunkAPI.dispatch(updateInvoicesPager(response.data.pager));
  }
});

// Rewards
export const getAllRewards = createAsyncThunk("public/getAllRewards", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/rewards`, config);

  if (response.status === 200 && response.data.message === "Rewards list") {
    thunkAPI.dispatch(updateallRewards(response.data.payload.rewards));
    thunkAPI.dispatch(updaterewardsPager(response.data.pager));
    thunkAPI.dispatch(updaterRewardStats(response.data.payload.stats));
  }
});

export const claimReward = createAsyncThunk("public/claimReward", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(`${backendServerBaseURL}/public/rewards/claim`, {}, config);

  if (response.status === 200 && response.data.message === "Reward claimed successfully") {
    thunkAPI.dispatch(getAllRewards({ page: 1 }));
    thunkAPI.dispatch(getMe());
  }
});

// Profile
export const sendEditToken = createAsyncThunk("public/sendEditToken", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    email: payload.email,
  };

  let response = await axios.post(`${backendServerBaseURL}/public/profile/edit-token`, body, config);

  if (response.status === 200 && response.data.message === "Edit Token") {
  }
});

export const updateBasicInfo = createAsyncThunk("public/updateBasicInfo", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    editToken: payload.editToken,
    email: payload.email,
    username: payload.username,
  };

  try {
    let response = await axios.patch(`${backendServerBaseURL}/public/profile/basic-info`, body, config);

    if (response.status === 200 && response.data.message === "Basic info updated successfully") {
      localStorage.setItem("user", JSON.stringify(response.data.payload.user));
      payload.closeUpdateBasicInfoDialog.current.click();
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.seteditTokenError(error.response.data.message);
    }
  }
});

export const updatePassowrd = createAsyncThunk("public/updatePassowrd", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    currentPassword: payload.currentPassword,
    newPassword: payload.newPassword,
  };

  try {
    let response = await axios.patch(`${backendServerBaseURL}/public/profile/password`, body, config);

    if (response.status === 200 && response.data.message === "Password updated successfully") {
      payload.resetForm();
      payload.navigate(`/password-changed-successfully`);
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.setErrors({ afterSubmit: error.response.data.message });
    }
  }
});

// MFA
export const getMFA = createAsyncThunk("public/getMFA", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/auth/mfa`, config);

  if (response.status === 200 && response.data.message === "Multi Factor Authentication") {
    thunkAPI.dispatch(updateMFA(response.data.payload));
  }
});

export const enable2FA = createAsyncThunk("public/enable2FA", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response = await axios.post(
      `${backendServerBaseURL}/auth/mfa`,
      {
        totp: payload.totp,
      },
      config
    );

    if (response.status === 200 && response.data.message === "Multi Factor Authentication enabled successfully") {
      localStorage.setItem("user", JSON.stringify(response.data.payload.user));
      window.location.reload();
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.setErrors({ afterSubmit: error.response.data.message });
    }
  }
});

export const disable2FA = createAsyncThunk("public/disable2FA", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response = await axios.delete(`${backendServerBaseURL}/auth/mfa`, config);

    if (response.status === 200 && response.data.message === "Multi Factor Authentication disabled successfully") {
      localStorage.setItem("user", JSON.stringify(response.data.payload.user));
      window.location.reload();
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.setErrors({ afterSubmit: error.response.data.message });
    }
  }
});

// Discord
export const getDiscordInfo = createAsyncThunk("public/getDiscordInfo", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response = await axios.get(`${backendServerBaseURL}/public/discord`, config);

    if (response.status === 200 && response.data.message === "Discord") {
      thunkAPI.dispatch(updateDiscordInfo(response.data.payload));
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.setErrors({ afterSubmit: error.response.data.message });
    }
  }
});

export const updateDiscordCode = createAsyncThunk("public/updateDiscordCode", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response = await axios.post(`${backendServerBaseURL}/public/discord/code`, { code: payload.code }, config);

    if (response.status === 200 && response.data.message === "Discord") {
      // thunkAPI.dispatch(updateDiscordInfo(response.data.payload));
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.setErrors({ afterSubmit: error.response.data.message });
    }
  }
});

export const removeDiscord = createAsyncThunk("public/removeDiscord", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    let response = await axios.delete(`${backendServerBaseURL}/public/discord`, config);

    if (response.status === 200 && response.data.message === "Discord deleted successfully") {
      window.open(thunkAPI.getState().public.discordInfo.discordDisconnectUrl, "_self");
    }
  } catch (error) {
    if (error.response.status === 400 && error.response.data.message !== "Validation Error") {
      payload.setErrors({ afterSubmit: error.response.data.message });
    }
  }
});

// Rates
export const getAllRewardRatesForRewardsAndReferrals = createAsyncThunk(
  "public/getAllRewardRatesForRewardsAndReferrals",
  async (payload, thunkAPI) => {
    const token = await localStorage.getItem("accessToken", "");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let response = await axios.get(`${backendServerBaseURL}/public/reward-rates`, config);

    if (response.status === 200 && response.data.message === "Reward Rates") {
      thunkAPI.dispatch(updateallRates(response.data.payload));
    }
  }
);

// Rewards
export const getAllReferralRewards = createAsyncThunk("public/getAllReferralRewards", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: payload.page,
      size: 20,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/referral-rewards`, config);

  if (response.status === 200 && response.data.message === "Referral Rewards list") {
    thunkAPI.dispatch(updateallReferralRewards(response.data.payload.rewards));
    thunkAPI.dispatch(updateReferralRewardsPager(response.data.pager));
    thunkAPI.dispatch(updateReferralRewardStats(response.data.payload.stats));
  }
});

export const claimReferralReward = createAsyncThunk("public/claimReferralReward", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(`${backendServerBaseURL}/public/referral-rewards/claim`, {}, config);

  if (response.status === 200 && response.data.message === "Referral Reward claimed successfully") {
    thunkAPI.dispatch(getAllReferralRewards({ page: 1 }));
    thunkAPI.dispatch(getMe());
  }
});

// Reseller Dashboard
export const createSubUser = createAsyncThunk("public/createSubUser", async (payload, thunkAPI) => {
  payload.setsuccessMsg(null);
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": `${payload.xApiKey}`,
    },
  };

  try {
    let response = await axios.post(
      `${backendServerBaseURL}/reseller/sub-users`,
      {
        username: payload.username,
        email: payload.email,
      },
      config
    );

    if (response.status === 200 && response.data.message === "Sub-User created successfully") {
      payload.setsuccessMsg("Sub User Created Successfully");
      payload.resetForm();
      thunkAPI.dispatch(getAllSubUsers({ page: 1, xApiKey: payload.xApiKey }));
      thunkAPI.dispatch(getResellerDashboard({ xApiKey: payload.xApiKey }));
    }
  } catch (err) {
    payload.setErrors({ afterSubmit: err.response.data.message });
  }
});

export const getAllSubUsers = createAsyncThunk("public/getAllSubUsers", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": `${payload.xApiKey}`,
    },
    params: {
      page: payload.page,
      size: 30,
      search: thunkAPI.getState().public.subUsersSearch,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/reseller/sub-users`, config);

  if (response.status === 200 && response.data.message === "Sub-Users list") {
    thunkAPI.dispatch(updateallSubUsers(response.data.payload));
    thunkAPI.dispatch(updatesubUsersPager(response.data.pager));
  }
});

export const getResellerDashboard = createAsyncThunk("public/getResellerDashboard", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": `${payload.xApiKey}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/public/reseller-dashboard`, config);

  if (response.status === 200 && response.data.message === "Reseller dashboard") {
    thunkAPI.dispatch(updateresellerDashboardData(response.data.payload));
  }
});

export const addResidentialPlanToResellerSubUser = createAsyncThunk("public/addResidentialPlanToResellerSubUser", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": `${payload.xApiKey}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/reseller/sub-users/${payload.subUserId}/plans`,
    {
      region: 0,
      bandwidth: payload.bandwidth,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Plan purchase") {
    // thunkAPI.dispatch(updateallSubUsers(response.data.payload));
    payload.navigate("/dashboard/reseller-dashboard");
  }
});

export const addDatacenterPlanToResellerSubUser = createAsyncThunk("public/addResidentialPlanToResellerSubUser", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": `${payload.xApiKey}`,
    },
  };

  let response = await axios.post(
    `${backendServerBaseURL}/reseller/sub-users/${payload.subUserId}/plans`,
    {
      region: payload.region,
      threads: payload.threads,
      days: payload.days,
    },
    config
  );

  if (response.status === 200 && response.data.message === "Plan purchase") {
    // thunkAPI.dispatch(updateallSubUsers(response.data.payload));
    payload.navigate("/dashboard/reseller-dashboard");
  }
});

export const getSingleSubUser = createAsyncThunk("public/getSingleSubUsers", async (payload, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": `${payload.xApiKey}`,
    },
  };

  let response = await axios.get(`${backendServerBaseURL}/reseller/sub-users/${payload.subUserId}`, config);

  if (response.status === 200 && response.data.message === "Sub-User") {
    thunkAPI.dispatch(updatesingleSubUserInfo(response.data.payload));
    thunkAPI.dispatch(updatesubUserSubscriptions(response.data.payload.activeSubscriptions));
  }
});

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    updateGeneratorInfo: (state, action) => {
      state.generatorInfo = action.payload;
    },
    updateActiveSubscriptions: (state, action) => {
      state.activeSubscriptions = action.payload;
    },
    updateUserStatistics: (state, action) => {
      state.userStatistics = action.payload;
    },

    updateSuggestionAllowed: (state, action) => {
      state.suggestionAllowed = action.payload;
    },
    updatePlans: (state, action) => {
      state.plans = action.payload;
    },
    updateCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    updateTrialPlans: (state, action) => {
      state.trialPlans = action.payload;
    },
    updateSinglePlanInfo: (state, action) => {
      state.singlePlanInfo = action.payload;
    },
    updateCustomPlanInfo: (state, action) => {
      state.customPlanInfo = action.payload;
    },

    updateAllInvoices: (state, action) => {
      state.allInvoices = action.payload;
    },
    updateInvoicesPager: (state, action) => {
      state.invoicesPager = action.payload;
    },
    updateInvoicesSearch: (state, action) => {
      state.invoicesSearch = action.payload;
    },

    updateallRewards: (state, action) => {
      state.allRewards = action.payload;
    },
    updaterewardsPager: (state, action) => {
      state.rewardsPager = action.payload;
    },
    updaterRewardStats: (state, action) => {
      state.rewardStats = action.payload;
    },

    updateMFA: (state, action) => {
      state.mfa = action.payload;
    },

    updateTargetSubscriptionInfo: (state, action) => {
      state.targetSubscriptionInfo = action.payload;
    },

    updateDiscordInfo: (state, action) => {
      state.discordInfo = action.payload;
    },

    updateallReferralRewards: (state, action) => {
      state.allReferralRewards = action.payload;
    },
    updateReferralRewardsPager: (state, action) => {
      state.ReferralRewardsPager = action.payload;
    },
    updateReferralRewardStats: (state, action) => {
      state.ReferralRewardStats = action.payload;
    },

    updateallRates: (state, action) => {
      state.allRates = action.payload;
    },

    updateallSubUsers: (state, action) => {
      state.allSubUsers = action.payload;
    },
    updatesubUsersPager: (state, action) => {
      state.subUsersPager = action.payload;
    },
    updatesubUsersSearch: (state, action) => {
      state.subUsersSearch = action.payload;
    },
    updateresellerDashboardData: (state, action) => {
      state.resellerDashboardData = action.payload;
    },

    updatesubUserSubscriptions: (state, action) => {
      state.subUserSubscriptions = action.payload;
    },
    updatesubUserSubscriptionsPager: (state, action) => {
      state.subUserSubscriptionsPager = action.payload;
    },
    updatesubUserSubscriptionsSearch: (state, action) => {
      state.subUserSubscriptionsSearch = action.payload;
    },
    updatesingleSubUserInfo: (state, action) => {
      state.singleSubUserInfo = action.payload;
    },
  },
});

export const {
  updateInvoicesSearch,
  updateGeneratorInfo,
  updateSuggestionAllowed,
  updatePlans,
  updateCoupons,
  updateTrialPlans,
  updateSinglePlanInfo,
  updateCustomPlanInfo,
  updateActiveSubscriptions,
  updateAllInvoices,
  updateInvoicesPager,
  updateallRewards,
  updaterewardsPager,
  updaterRewardStats,
  updateMFA,
  updateUserStatistics,
  updateTargetSubscriptionInfo,
  updateDiscordInfo,
  updateallReferralRewards,
  updateReferralRewardsPager,
  updateReferralRewardStats,
  updateallRates,

  updateallSubUsers,
  updatesubUsersPager,
  updatesubUsersSearch,
  updateresellerDashboardData,

  updatesingleSubUserInfo,
  updatesubUserSubscriptions,
  updatesubUserSubscriptionsPager,
  updatesubUserSubscriptionsSearch,
} = publicSlice.actions;

export const selectGeneratorInfo = (state) => state.public.generatorInfo;
export const selectActiveSubscriptions = (state) => state.public.activeSubscriptions;
export const selectUserStatistics = (state) => state.public.userStatistics;

export const selectSuggestionAllowed = (state) => state.public.suggestionAllowed;

export const selectPlans = (state) => state.public.plans;
export const selectCoupons = (state) => state.public.coupons;
export const selectTrialPlans = (state) => state.public.trialPlans;
export const selectSinglePlanInfo = (state) => state.public.singlePlanInfo;
export const selectCustomPlanInfo = (state) => state.public.customPlanInfo;

export const selectAllInvoices = (state) => state.public.allInvoices;
export const selectInvoicesPager = (state) => state.public.invoicesPager;
export const selectInvoicesSearch = (state) => state.public.invoicesSearch;

export const selectallRewards = (state) => state.public.allRewards;
export const selectrewardsPager = (state) => state.public.rewardsPager;
export const selectRewardStats = (state) => state.public.rewardStats;

export const selectMFA = (state) => state.public.mfa;

export const selectTargetSubscriptionInfo = (state) => state.public.targetSubscriptionInfo;

export const selectDiscordInfo = (state) => state.public.discordInfo;

export const selectallReferralRewards = (state) => state.public.allReferralRewards;
export const selectReferralRewardsPager = (state) => state.public.ReferralRewardsPager;
export const selectReferralRewardStats = (state) => state.public.ReferralRewardStats;

export const selectallRates = (state) => state.public.allRates;

export const selectAllSubUsers = (state) => state.public.allSubUsers;
export const selectsubUsersPager = (state) => state.public.subUsersPager;
export const selectsubUsersSearch = (state) => state.public.subUsersSearch;
export const selectresellerDashboardData = (state) => state.public.resellerDashboardData;

export const selectsingleSubUserInfo = (state) => state.public.singleSubUserInfo;
export const selectsubUserSubscriptions = (state) => state.public.subUserSubscriptions;
export const selectsubUserSubscriptionsPager = (state) => state.public.subUserSubscriptionsPager;
export const selectsubUserSubscriptionsSearch = (state) => state.public.subUserSubscriptionsSearch;

export default publicSlice.reducer;
