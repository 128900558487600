import React from "react";
import { Container, Card } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";
import { Link } from "react-router-dom";
import { Form, FormikProvider } from "formik";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);

      await axios
        .post(`${backendServerBaseURL}/auth/forgot-password`, {
          email: values["email"],
        })
        .then((response) => {
          setSubmitting(false);
          console.log(response.data.data);
          if (
            response.status === 200 &&
            response.data.message === "Link sent successfully"
          ) {
            navigate(`/forgot-password-link-sent-successfully`);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          try {
            console.log(error);
            if (error.response.status === 400) {
              setErrors({ afterSubmit: error.response.data.message });
            }
          } catch (e) {
            console.log(e);
          }
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <Container className="d-flex flex-column justify-content-center align-items-center h-100">
        {/* <Helmet title="Sign In" /> */}
        <div className="text-center mt-4">
          <h2>Forgot Password?</h2>
          <p className="lead">
            Enter the email address you used when you joined and we'll send you
            instructions to reset your password.
          </p>
        </div>

        <Card className="col-sm-12 col-md-7 col-lg-5 m-4 p-4 text-start">
          <Card.Body>
            <div className="mx-sm-4 my-sm-2">
              <div className="d-flex flex-column justify-content-center align-items-center card-logo mb-2">
                <Logo />
              </div>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="mx-auto" style={{ maxWidth: "30rem" }}>
                      {/* Form */}

                      {/* Email */}
                      <div className="mb-4">
                        <label className="form-label">Email</label>
                        <input
                          {...getFieldProps("email")}
                          className="form-control form-control-lg"
                          placeholder="email@address.com"
                        />
                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(touched.email && errors.email)
                              ? "block"
                              : "none",
                          }}
                        >
                          {errors.email}
                        </span>
                      </div>

                      {/* Errors from server */}
                      {errors.afterSubmit && (
                        <div className="alert alert-danger" role="alert">
                          {errors.afterSubmit}
                        </div>
                      )}

                      {/* Submit */}
                      <div className="d-grid gap-2">
                        <button
                          type="submit"
                          className="btn btn-primary text-center"
                        >
                          Submit
                        </button>
                      </div>

                      {/* End Form */}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </Card.Body>
        </Card>

        <p className="signin-sub-text">
          <Link
            className="link-to-signup"
            to="/signin"
            style={{ fontWeight: "700" }}
          >
            <i className="bi-chevron-left" /> {" Back to Sign In"}
          </Link>{" "}
        </p>
      </Container>
    </>
  );
};

export default ForgotPassword;
