import React from "react";
import axios from "axios";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Container, Card, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";
import { Form, FormikProvider } from "formik";

export default function ChangePassword() {
  const params = useParams();
  const navigate = useNavigate();
  //   const [showPassword, setShowPassword] = useState(false);
  //   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const ValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password must contain at least 8 letters"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);

      await axios
        .patch(`${backendServerBaseURL}/auth/forgot-password`, {
          newPassword: values["newPassword"],
          resetPasswordToken: params.resetPasswordToken,
        })
        .then((response) => {
          setSubmitting(false);
          console.log(response.data.data);
          if (
            response.status === 200 &&
            response.data.message === "Password updated successfully"
          ) {
            navigate(`/password-changed-successfully`);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          try {
            console.log(error);
            if (error.response.status === 400) {
              setErrors({ afterSubmit: error.response.data.message });
            }
          } catch (e) {
            console.log(e);
          }
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {/* ========== MAIN CONTENT ========== */}
      <Container className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
        {/* Content */}

        <div className="text-center mt-4">
          <h1 className="h2">Change Password</h1>
          <p className="lead">Enter your new password</p>
        </div>
        {/* Card */}
        <Card className="col-sm-12 col-md-7 col-lg-5 m-4 p-4 text-start">
          <Card.Body>
            {/* Form */}
            {/* New Password */}
            <div className="d-flex flex-column justify-content-center align-items-center card-logo mb-2">
              <Logo />
            </div>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="mx-sm-4 my-sm-2">
                  <div className="mb-4">
                    <label className="form-label">New Password</label>
                    <input
                      {...getFieldProps("newPassword")}
                      className="form-control form-control-lg"
                      placeholder="Enter your new password here"
                    />
                    <span
                      className="invalid-feedback"
                      style={{
                        display: Boolean(
                          touched.newPassword && errors.newPassword
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      {errors.newPassword}
                    </span>
                  </div>
                </div>
                {/* Errors from server */}
                {errors.afterSubmit && (
                  <div className="alert alert-danger" role="alert">
                    {errors.afterSubmit}
                  </div>
                )}

                <div className="text-center mt-3">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </FormikProvider>
            {/* End Form */}
          </Card.Body>
        </Card>
        {/* End Card */}

        <p className="signin-sub-text">
          <Link className="link-to-signup" to="/signin">
            {" "}
            <i className="bi-chevron-left" /> Back to Sign in
          </Link>{" "}
        </p>

        {/* End Content */}
      </Container>
      {/* ========== END MAIN CONTENT ========== */}
    </>
  );
}
