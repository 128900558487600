import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import arrow from "../../Assets/images/arrow-up-icon.svg";
import LandingFooter from "../../Components/LandingFooter";
import LandingNavbar from "../../Components/LandingNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Pricing = () => {
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const getPrices = () => {
      axios
        .get(`${backendServerBaseURL}/landing`)
        .then((data) => {
          setPrices(data.data.payload.Plans);
        })
        .catch((error) => console.log(error));
    };

    getPrices();
  }, []);
  console.log(prices);
  return (
    <div>
      <LandingNavbar />

      {/* pricing section start */}
      <section className="pricing-section pa-y4">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h6>PRICING</h6>
                <h2 style={{ fontSize: "3.2rem" }}>Best Price for the Best Proxies</h2>
                <p>
                  In eu mollis nibh. Donec efficitur nunc purus, in vehicula odio ultricies eget. Praesent laoreet libero ac <br /> lorem dictum
                  viverra. Donec porta lectus mi, sed maximus orci pulvinar vel.
                </p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row">
            <div className="col-lg-12">
              <div className="pricing-filter-head">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="residential-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#residential"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="true"
                    >
                      Rotating Residential
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="sneaker-proxy-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#sneaker-proxy"
                      type="button"
                      role="tab"
                      aria-controls="sneaker-proxy"
                      aria-selected="false"
                    >
                      Sneaker Proxies
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="residential" role="tabpanel" aria-labelledby="residential-tab" tabIndex={0}>
                  <div className="row">
                    {prices.length &&
                      prices
                        .filter((item) => item.provider === "netnut")
                        .map((item) => (
                          <>
                            {/* pricing single item start */}
                            <div className="col-lg-4 col-md-6" key={item._id}>
                              <div className="pricing-box-wrap">
                                <div className="d-flex">
                                  <h4>{item.title}</h4>
                                  <h3>
                                    <sup>$</sup>
                                    {item.price}
                                    <span>/per GB</span>
                                  </h3>
                                </div>
                                <ul>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> {item.bandwidth == "0" ? "Unlimited " : item.bandwidth + " GB "}
                                      Traffic Included
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Access Over 30M Residential IPs
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Rotating &amp; sticky
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Geo-targeting
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Unlimited threads
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> HTTP\HTTPS Protocols
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> 60 days expire time
                                    </p>
                                  </li>
                                </ul>
                                <div className="price-bttn">
                                  <Link to="/signup">
                                    Checkout <img src={arrow} alt="Arrow" className="img-fluid" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* pricing single item end */}
                          </>
                        ))}
                  </div>
                </div>

                <div className="tab-pane fade" id="sneaker-proxy" role="tabpanel" aria-labelledby="sneaker-proxy-tab" tabIndex={0}>
                  <div className="row">
                    {prices.length &&
                      prices
                        .filter((item) => item.provider === "smartproxy")
                        .map((item) => (
                          <>
                            {/* pricing single item start */}
                            <div className="col-lg-4 col-md-6" key={item._id}>
                              <div className="pricing-box-wrap">
                                <div className="d-flex">
                                  <h4>{item.title}</h4>
                                  <h3>
                                    <sup>$</sup>
                                    {item.price}
                                    <span>/per GB</span>
                                  </h3>
                                </div>
                                <ul>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> {item.bandwidth == "0" ? "Unlimited " : item.bandwidth + " GB "}
                                      Traffic Included
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Access Over 30M Residential IPs
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Rotating &amp; sticky
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Geo-targeting
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Unlimited threads
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> HTTP\HTTPS Protocols
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> 60 days expire time
                                    </p>
                                  </li>
                                </ul>
                                <div className="price-bttn">
                                  <Link to="/signup">
                                    Checkout <img src={arrow} alt="Arrow" className="img-fluid" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* pricing single item end */}
                          </>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* pricing section end */}
      {/* get start section start */}
      <section className="get-start py-4 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 col-sm-11 col-12">
              <div className="get-start-bg">
                <div className="common-heading mb-0">
                  <h2>Got Questions? Let’s Talk</h2>
                  <p>
                    In eu mollis nibh. Donec efficitur nunc purus, in vehicula odio ultricies eget. Praesent laoreet libero ac lorem dictum viverra.
                    Donec porta lectus mi, sed maximus orci pulvinar vel.
                  </p>
                  <a href="#">
                    Contact Us <img src={arrow} alt="a" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get start section end */}

      <LandingFooter />
    </div>
  );
};

export default Pricing;
