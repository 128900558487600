import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AlertCard from "../Components/AlertCard";
import NavbarComponent from "../Components/navbar/Navbar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import Sidebar from "../Components/sidebar/Sidebar";
import {
  getMe,
  getTypeConfig,
  selectMe,
  selectTypesConfig,
} from "../redux/slices/generalSlice";
import {
  getAllPlans,
  getCustomPlanInfo,
  purchasePlan,
  selectCustomPlanInfo,
  selectPlans,
} from "../redux/slices/publicSlice";
import { extraProcessHumanize } from "../utils/formatTime";

export default function UserPlans() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allPlans = useSelector(selectPlans);
  const [selectedType, setselectedType] = useState(0);
  const customPlanInfo = useSelector(selectCustomPlanInfo);
  const [targetTypeConfig, settargetTypeConfig] = useState(null);
  const [customPlanSubtotal, setCustomPlanSubtotal] = useState(0);
  const typesConfig = useSelector(selectTypesConfig);
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(getMe());
    dispatch(getCustomPlanInfo());
    dispatch(getAllPlans());
    dispatch(getTypeConfig());
  }, []);

  const getValueFromCustomPlanInfo = (key) => {
    let val = "";
    customPlanInfo?.map((cpi) => {
      if (cpi.name === key) {
        val = cpi.value;
      }
    });

    return val;
  };

  const RegisterSchema = Yup.object().shape({
    coupon: Yup.string(),
    region: Yup.number(),
    bandwidth: Yup.number()
      .min(getValueFromCustomPlanInfo("CustomBandwidthMin"))
      .max(getValueFromCustomPlanInfo("CustomBandwidthMax")),
    threads: Yup.number()
      .min(getValueFromCustomPlanInfo("CustomThreadsMin"))
      .max(getValueFromCustomPlanInfo("CustomThreadsMax")),
    days: Yup.number()
      .min(
        getValueFromCustomPlanInfo("CustomPrices").length === 0
          ? 0
          : getValueFromCustomPlanInfo("CustomPrices")[0].from
      )
      .max(
        getValueFromCustomPlanInfo("CustomPrices").length === 0
          ? 0
          : getValueFromCustomPlanInfo("CustomPrices")[
              getValueFromCustomPlanInfo("CustomPrices").length - 1
            ].to
      ),
    paymentMethod: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      coupon: "",
      region: 0,
      bandwidth: "",
      threads: "",
      days: "",
      paymentMethod: "cryptocurrency",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      let err = false;
      let errsDict = {};

      if (targetTypeConfig?.CustomPlanThreads) {
        if (values.threads === "") {
          err = true;
          errsDict["threads"] = "Threads value is required";
        }
      }

      if (targetTypeConfig?.CustomPlanBandwidth) {
        if (values.bandwidth === "") {
          err = true;
          errsDict["bandwidth"] = "Bandwidth value is required";
        }
      }

      if (targetTypeConfig?.CustomPlanDuration) {
        if (values.days === "") {
          err = true;
          errsDict["days"] = "Duration value is required";
        }
      }

      console.log(errsDict);
      setErrors(errsDict);

      if (err === false) {
        setSubmitting(true);
        await dispatch(
          purchasePlan({ ...values, planId: 0, navigate, setErrors })
        );
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setValues,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (targetTypeConfig && targetTypeConfig?.CustomPlanThreads) {
      const threadPrices = getValueFromCustomPlanInfo("CustomPrices");
      let perThreadPerDayPrice = 0;
      threadPrices.map((tp) => {
        if (tp.from <= values.days && values.days <= tp.to) {
          perThreadPerDayPrice = tp.price;
        }
      });

      if (values.days !== "" && values.threads !== "") {
        setCustomPlanSubtotal(
          perThreadPerDayPrice * values.threads * values.days
        );
      } else {
        setCustomPlanSubtotal(0);
      }
    }

    if (targetTypeConfig && !targetTypeConfig?.CustomPlanThreads) {
      const bandwidthPrices = getValueFromCustomPlanInfo(
        "CustomBandwidthPrices"
      );
      console.log(bandwidthPrices);
      let perGBBandwidthPrice = 0;
      bandwidthPrices.map((tp) => {
        if (tp.from <= values.bandwidth && values.bandwidth <= tp.to) {
          perGBBandwidthPrice = tp.price;
        }
      });

      if (values.bandwidth !== "") {
        setCustomPlanSubtotal(perGBBandwidthPrice * values.bandwidth);
      } else {
        setCustomPlanSubtotal(0);
      }
    }
  }, [formik.values]);

  useEffect(() => {
    if (typesConfig) {
      settargetTypeConfig(typesConfig[values.region]);
    }

    if (typesConfig !== null && targetTypeConfig === null) {
      settargetTypeConfig(typesConfig[0]);
    }
  }, [values.region, typesConfig]);

  return (
    <>
      <Helmet>
        <title>Purchase Plan - KocerRoxy</title>
      </Helmet>

      <div className="d-flex ">
        <Sidebar items={dashboardItems} />
        <div className="w-100">
          <NavbarComponent />
          <AlertCard />

          <>
            {Object.keys(allPlans).length === 0 && (
              <div
                className="w-100 d-flex flex-column align-items-center justify-content-center"
                style={{ height: "80%" }}
              >
                <div class="spinner-border text-primary mb-3" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <p>Please Wait</p>
              </div>
            )}

            {Object.keys(allPlans).length !== 0 && (
              <>
                {/* Content */}
                <div className="page-content container">
                  <div className="d-flex justify-content-center ">
                    <ul
                      class="nav nav-tabs border-xs-0 d-flex justify-content-center"
                      style={{ borderBottom: 0 }}
                    >
                      {Object.keys(allPlans).map((key, index) => {
                        return (
                          <>
                            <li
                              class="nav-item"
                              onClick={() => {
                                setselectedType(index);
                                settargetTypeConfig(typesConfig[index]);
                                setFieldValue("region", index);
                              }}
                            >
                              <a
                                class={
                                  selectedType === index
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                style={{
                                  color: "#FFFFFF",
                                  fontSize: "20px",
                                  border:
                                    selectedType === index
                                      ? "1.25px solid #027ef8"
                                      : "0.5px solid rgba(255, 255, 255, 0.25)",
                                  background:
                                    selectedType === index
                                      ? "rgba(2, 126, 248, 0.25)"
                                      : "",
                                }}
                                aria-current="page"
                                href="#"
                              >
                                {key}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>

                  <div
                    className={"border-xs-0 row p-lg-4 p-xs-1"}
                    style={{ borderRadius: 6, minHeight: "10vh" }}
                  >
                    {Object.keys(allPlans).map((key, index) => {
                      if (selectedType === index) {
                        return (
                          <>
                            {/* All other plans */}
                            {allPlans[key].map((singlePlan) => {
                              return (
                                <div className=" col-lg-4 col-12 mb-xs-2 mb-lg-5 mt-xs-2 ps-lg-3 pe-lg-3">
                                  <div className="planHover card p-4 mb-2 text-center">
                                    {/* Title */}
                                    <h3>{singlePlan.title}</h3>

                                    {/* Description */}
                                    <p className="text-secondary">
                                      {singlePlan.description}
                                    </p>

                                    {/* Bandwidth and Plan cost */}
                                    <div className="row">
                                      <div className="col-6">
                                        <h2>
                                          {singlePlan.bandwidth === 0
                                            ? "Unlimited"
                                            : `${singlePlan?.bandwidth} GB`}
                                        </h2>
                                        <p>Bandwidth</p>
                                      </div>

                                      <div className="col-6">
                                        <h2>${singlePlan.price}</h2>
                                        <p>Plan Cost</p>
                                      </div>
                                    </div>

                                    <hr />

                                    <div className="d-flex justify-content-between">
                                      {/* Proxy Type */}
                                      <div>
                                        <div
                                          className="text-start"
                                          style={{ minWidth: "8rem" }}
                                        >
                                          <p>Proxy Type</p>
                                        </div>

                                        <div
                                          className="text-start"
                                          style={{ minWidth: "8rem" }}
                                        >
                                          <p>Duration</p>
                                        </div>
                                      </div>

                                      {/* Duration */}
                                      <div>
                                        <div className="text-center">
                                          <p>-</p>
                                        </div>

                                        <div className="text-center">
                                          <p>-</p>
                                        </div>
                                      </div>

                                      {/* Threads */}
                                      <div>
                                        <div>
                                          <p>{singlePlan.readableType}</p>
                                        </div>

                                        <div>
                                          <p>
                                            {extraProcessHumanize(
                                              moment
                                                .duration(
                                                  singlePlan.duration * 1000
                                                )
                                                .humanize()
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Buy Button */}
                                    <div>
                                      <button
                                        className="btn btn-primary text-center"
                                        style={{
                                          minWidth: "4rem",
                                          marginTop: "1rem",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            `/purchase-plan/${singlePlan._id}`
                                          );
                                        }}
                                      >
                                        Buy
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
                {/* End Content */}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
}
