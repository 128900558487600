import React from "react";
import arrow from "../../Assets/images/arrow-up-icon.svg";
import top01 from "../../Assets/images/top-1.svg";
import top02 from "../../Assets/images/top-2.svg";
import top03 from "../../Assets/images/top-3.svg";
import top04 from "../../Assets/images/top-4.svg";
import top05 from "../../Assets/images/top-5.svg";
import top06 from "../../Assets/images/top-6.svg";
import top07 from "../../Assets/images/top-7.svg";
import top08 from "../../Assets/images/top-8.svg";
import top09 from "../../Assets/images/top-9.svg";
import LandingNavbar from "../../Components/LandingNavbar";
import LandingFooter from "../../Components/LandingFooter";

const UseCase = () => {
  return (
    <div>
      <div>
        {/* header section start */}
        <LandingNavbar />
        {/* header section end */}
        {/* how to section start */}
        <section className="how-to-sec cases-sec pa-y4 pt-5">
          <div className="container">
            {/* common heading start */}
            <div className="row">
              <div className="col-lg-12">
                <div className="common-heading">
                  <h6>USE CASES</h6>
                  <h2 style={{ fontSize: "3.2rem" }}>
                    What our proxies can do
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                    <br /> Maecenas quis nulla quis nibh laoreet dapibus.
                  </p>
                </div>
              </div>
            </div>
            {/* common heading end */}
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-3">
                  <div className="d-flex">
                    <img src={top01} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Price Comparison
                    </h6>
                  </div>
                  <p>
                    Analyze, compare and collect data from any sales channel
                    every day. Nunc vestibulum nulla sit amet mi rhoncus
                    euismod.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-3">
                  <div className="d-flex">
                    <img src={top02} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Sneaker Purchase
                    </h6>
                  </div>
                  <p>
                    Our High Quality pools are perfectly optimized for
                    super-limited cop sneakers. Happy copping chances.
                    Geo-Target.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-2 howto-box-wrap-3">
                  <div className="d-flex">
                    <img src={top03} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Data Collection
                    </h6>
                  </div>
                  <p>
                    Our residential proxies work perfect for collecting data
                    from any search engines. Start super fast scraping of
                    google, yahoo.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-3">
                  <div className="d-flex">
                    <img src={top04} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Ad Verification
                    </h6>
                  </div>
                  <p>
                    Our residential proxies work perfect for collecting data
                    from any search engines. Start super fast scraping of
                    google, yahoo.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-3">
                  <div className="d-flex">
                    <img src={top05} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      SEO
                    </h6>
                  </div>
                  <p>
                    Our High Quality pools are perfectly optimized for
                    super-limited cop sneakers. Happy copping chances.
                    Geo-Target.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-2 howto-box-wrap-3">
                  <div className="d-flex">
                    <img src={top06} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Social Networks
                    </h6>
                  </div>
                  <p>
                    Our residential proxies work perfect for collecting data
                    from any search engines. Start super fast scraping of
                    google, yahoo.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap ">
                  <div className="d-flex">
                    <img src={top07} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Cyber Security
                    </h6>
                  </div>
                  <p>
                    Analyze, compare and collect data from any sales channel
                    every day. Nunc vestibulum nulla sit amet mi rhoncus
                    euismod.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap ">
                  <div className="d-flex">
                    <img src={top08} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Data Collection
                    </h6>
                  </div>
                  <p>
                    Our High Quality pools are perfectly optimized for
                    super-limited cop sneakers. Happy copping chances.
                    Geo-Target.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="howto-box-wrap howto-box-wrap-2">
                  <div className="d-flex">
                    <img src={top09} alt="Top Icon" className="img-fluid" />
                    <h6>
                      Proxies for <br />
                      Brands
                    </h6>
                  </div>
                  <p>
                    Our High Quality pools are perfectly optimized for
                    super-limited cop sneakers. Happy copping chances.
                    Geo-Target.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* how to section end */}
        {/* get start section start */}
        <section className="get-start py-4 pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-9 col-sm-11 col-12">
                <div className="get-start-bg">
                  <div className="common-heading mb-0">
                    <h2>Got Questions? Let’s Talk</h2>
                    <p>
                      In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                      odio ultricies eget. Praesent laoreet libero ac lorem
                      dictum viverra. Donec porta lectus mi, sed maximus orci
                      pulvinar vel.
                    </p>
                    <a href="#">
                      Contact Us{" "}
                      <img src={arrow} alt="a" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* get start section end */}
        {/* footer section start */}
        <LandingFooter />
      </div>
    </div>
  );
};

export default UseCase;
