import React from "react";
import usePalette from "../../Hooks/usePalette";
import { Doughnut } from "react-chartjs-2";
import { Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const round2DecimalPlances = (num) => {
  return Math.round(num * 100) / 100;
};

const DoughnutChart = ({ remaining, total }) => {
  const palette = usePalette();

  const data = {
    labels: ["Remaining", "Used"],
    datasets: [
      {
        data: [round2DecimalPlances(remaining), round2DecimalPlances(total - remaining)],
        backgroundColor: [palette.primary, palette.success, "#E8EAED"],
        borderWidth: 2,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    legend: {
      display: false,
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <h3 className="mb-0">Bandwidth</h3>
      </Card.Header>
      <Card.Body className="d-flex pt-0">
        <div className="align-self-center w-100">
          <div>
            <div className="chart chart-xs">
              <Doughnut data={data} options={options} />
            </div>
          </div>

          <Table className="mb-0">
            <thead>
              <tr>
                <th className="text-start ps-5">Bandwidth</th>
                <th className="text">Data</th>
              </tr>
            </thead>
            <tbody>
              <tr className="px-4">
                <td className="text-start ps-5">
                  <FontAwesomeIcon icon={faSquare} className="text-primary" /> Used
                </td>
                <td className="text">{total && remaining && round2DecimalPlances(total - remaining)} GB</td>
              </tr>
              <tr>
                <td className="text-start ps-5">
                  <FontAwesomeIcon icon={faSquare} className="text-success" /> Remaining
                </td>
                <td className="text">{round2DecimalPlances(remaining)} GB</td>
              </tr>
              <tr>
                <td className="text-start ps-5">
                  <FontAwesomeIcon icon={faSquare} className="text-light" /> Total
                </td>
                <td className="text">{round2DecimalPlances(total)} GB</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DoughnutChart;
