import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
const Discord = () => {
  return <FontAwesomeIcon icon={faDiscord} />;
};

const SidebarFooter = () => {
  return (
    <div className="sidebar-cta">
      <div className="sidebar-cta-content">
        <strong className="d-inline-block mb-2">Social Links</strong>
        <div className="mb-3 text-sm">
          Connect with us at these social media platforms.
        </div>

        <div className="d-grid">
          <a
            // href="https://themes.getbootstrap.com/product/appstack-react-admin-dashboard-template/"
            className="btn btn-primary text-center"
            target="_blank"
            rel="noreferrer"
          >
            <Discord /> Discord
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
