import { Form, FormikProvider, useFormik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  getCoupons,
  getCustomPlanInfo,
  getSinglePlan,
  purchasePlan,
  selectCoupons,
  selectCustomPlanInfo,
  selectSinglePlanInfo,
} from "../redux/slices/publicSlice";
import moment from "moment";
import { extraProcessHumanize } from "../utils/formatTime";
import { getMe, getTypeConfig, selectMe, selectTypesConfig } from "../redux/slices/generalSlice";
import LoadingButton from "../Components/common/LoadingButton";
import { Helmet } from "react-helmet-async";
import AlertCard from "../Components/AlertCard";
import NavbarComponent from "../Components/navbar/Navbar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import Sidebar from "../Components/sidebar/Sidebar";

export default function PurchasePlan({ overwritePlanId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const planId = overwritePlanId ? overwritePlanId : params.planId;
  const singlePlanInfo = useSelector(selectSinglePlanInfo);
  const customPlanInfo = useSelector(selectCustomPlanInfo);
  const typesConfig = useSelector(selectTypesConfig);
  const [targetTypeConfig, settargetTypeConfig] = useState(null);
  const [customPlanSubtotal, setCustomPlanSubtotal] = useState(0);
  const allCoupons = useSelector(selectCoupons);
  const [couponDiscounPercent, setcouponDiscounPercent] = useState(0);
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(getMe());

    if (planId?.toString() !== "0") {
      dispatch(
        getSinglePlan({
          planId: params.planId,
        })
      );
    } else {
      dispatch(getTypeConfig());
      dispatch(getCustomPlanInfo());
    }

    dispatch(getCoupons());
  }, []);

  const getValueFromCustomPlanInfo = (key) => {
    let val = [];
    customPlanInfo?.map((cpi) => {
      if (cpi.name === key) {
        val = cpi.value;
      }
    });

    return val;
  };

  const RegisterSchema = Yup.object().shape({
    coupon: Yup.string(),
    region: Yup.number(),
    bandwidth: Yup.number().min(getValueFromCustomPlanInfo("CustomBandwidthMin")).max(getValueFromCustomPlanInfo("CustomBandwidthMax")),
    threads: Yup.number().min(getValueFromCustomPlanInfo("CustomThreadsMin")).max(getValueFromCustomPlanInfo("CustomThreadsMax")),
    days: Yup.number()
      .min(getValueFromCustomPlanInfo("CustomPrices").length === 0 ? 0 : getValueFromCustomPlanInfo("CustomPrices")[0].from)
      .max(
        getValueFromCustomPlanInfo("CustomPrices").length === 0
          ? 0
          : getValueFromCustomPlanInfo("CustomPrices")[getValueFromCustomPlanInfo("CustomPrices").length - 1].to
      ),
    paymentMethod: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      coupon: "",
      region: 0,
      bandwidth: "",
      threads: "",
      days: "",
      paymentMethod: "cryptocurrency",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      let err = false;
      let errsDict = {};

      if (planId == 0 && targetTypeConfig?.CustomPlanThreads) {
        if (values.threads === "") {
          err = true;
          errsDict["threads"] = "Threads value is required";
        }
      }

      if (planId == 0 && targetTypeConfig?.CustomPlanBandwidth) {
        if (values.bandwidth === "") {
          err = true;
          errsDict["bandwidth"] = "Bandwidth value is required";
        }
      }

      if (planId == 0 && targetTypeConfig?.CustomPlanDuration) {
        if (values.days === "") {
          err = true;
          errsDict["days"] = "Duration value is required";
        }
      }

      setErrors(errsDict);

      if (err === false) {
        setSubmitting(true);
        await dispatch(purchasePlan({ ...values, planId, navigate, setErrors }));
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues, setFieldValue } = formik;

  useEffect(() => {
    if (targetTypeConfig && targetTypeConfig?.CustomPlanThreads) {
      const threadPrices = getValueFromCustomPlanInfo("CustomPrices");
      let perThreadPerDayPrice = 0;
      threadPrices.map((tp) => {
        if (tp.from <= values.days && values.days <= tp.to) {
          perThreadPerDayPrice = tp.price;
        }
      });

      if (values.days !== "" && values.threads !== "") {
        setCustomPlanSubtotal(perThreadPerDayPrice * values.threads * values.days);
      } else {
        setCustomPlanSubtotal(0);
      }
    }

    if (targetTypeConfig && !targetTypeConfig?.CustomPlanThreads) {
      const bandwidthPrices = getValueFromCustomPlanInfo("CustomBandwidthPrices");
      console.log(bandwidthPrices);
      let perGBBandwidthPrice = 0;
      bandwidthPrices.map((tp) => {
        if (tp.from <= values.bandwidth && values.bandwidth <= tp.to) {
          perGBBandwidthPrice = tp.price;
        }
      });

      if (values.bandwidth !== "") {
        setCustomPlanSubtotal(perGBBandwidthPrice * values.bandwidth);
      } else {
        setCustomPlanSubtotal(0);
      }
    }

    let couponFound = false;
    allCoupons.map((c) => {
      if ((c.code === values.coupon && customPlanSubtotal > 0) || (c.code === values.coupon && planId?.toString() !== "0")) {
        const discountedPrice = customPlanSubtotal - (customPlanSubtotal * c.discount) / 100;
        setCustomPlanSubtotal(Math.round(discountedPrice * 100) / 100);
        setcouponDiscounPercent(c.discount);
        couponFound = true;
      }
    });

    if (!couponFound) {
      // setCustomPlanSubtotal(customPlanSubtotal);
      setcouponDiscounPercent(0);
    }
  }, [formik.values]);

  useEffect(() => {
    if (typesConfig) {
      settargetTypeConfig(typesConfig[values.region]);
    }
  }, [values.region, typesConfig]);

  return (
    <>
      <Helmet>
        <title>Purchase Plan - KocerRoxy</title>
      </Helmet>

      <div className="d-flex ">
        <Sidebar items={dashboardItems} />
        <div className="w-100">
          <NavbarComponent />
          <AlertCard />

          {/* Content */}
          <div className="page-content">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container p-0 col-lg-8 col-12">
                  <div className="card p-4">
                    {planId?.toString() !== "0" && (
                      <>
                        {/* Title */}
                        <h3 className="text-center">{singlePlanInfo?.title}</h3>

                        {/* Description */}
                        <p className="text-center text-secondary">{singlePlanInfo?.description}</p>

                        <h2 className="text-center">{singlePlanInfo?.bandwidth === 0 ? "Unlimited" : `${singlePlanInfo?.bandwidth} GB`}</h2>
                        <p className="text-center">Bandwidth</p>

                        <hr />

                        {/* Proxy Type */}
                        <div className="row">
                          <div className="col-6 text-start">
                            <p>Proxy Type</p>
                          </div>

                          <div className="col-1 text-center">
                            <p>-</p>
                          </div>

                          <div className="col-5 text-end">
                            <p>{singlePlanInfo?.readableType}</p>
                          </div>
                        </div>

                        {/* Duration */}
                        <div className="row">
                          <div className="col-6 text-start">
                            <p>Duration</p>
                          </div>

                          <div className="col-1 text-center">
                            <p>-</p>
                          </div>

                          <div className="col-5 text-end">
                            <p>{extraProcessHumanize(moment.duration(singlePlanInfo?.duration * 1000).humanize())}</p>
                          </div>
                        </div>

                        {/* Threads */}
                        <div className="row">
                          <div className="col-6 text-start">
                            <p>Threads</p>
                          </div>

                          <div className="col-1 text-center">
                            <p>-</p>
                          </div>

                          <div className="col-5 text-end">
                            <p>{singlePlanInfo?.threads === 0 ? "Unlimited" : singlePlanInfo?.threads}</p>
                          </div>
                        </div>
                      </>
                    )}

                    {planId?.toString() === "0" && (
                      <>
                        <h3 className="text-center">Custom Plan</h3>

                        {targetTypeConfig && targetTypeConfig?.CustomPlanThreads && (
                          <div className="text-center" dangerouslySetInnerHTML={{ __html: getValueFromCustomPlanInfo("DatacenterDescription") }} />
                        )}

                        {targetTypeConfig && !targetTypeConfig?.CustomPlanThreads && (
                          <div className="text-center" dangerouslySetInnerHTML={{ __html: getValueFromCustomPlanInfo("ResidentialDescription") }} />
                        )}

                        {/* region */}
                        <div className="mb-4">
                          <label className="form-label">Type</label>
                          <select {...getFieldProps("region")} className="form-select form-control form-control-lg" placeholder="Type">
                            {typesConfig &&
                              Object.keys(typesConfig).map((typeID) => {
                                return (
                                  <option key={`type_${typeID}`} value={typesConfig[typeID].ID}>
                                    {typesConfig[typeID].Name}
                                  </option>
                                );
                              })}
                          </select>
                          <span className="invalid-feedback" style={{ display: Boolean(errors.region) ? "block" : "none" }}>
                            {errors.region}
                          </span>
                        </div>

                        {targetTypeConfig && targetTypeConfig?.CustomPlanBandwidth && (
                          <>
                            {/* Bandwidth */}
                            <div className="mb-4">
                              <label className="form-label">
                                Bandwidth{" "}
                                <span className="text-secondary">
                                  (Range: {getValueFromCustomPlanInfo("CustomBandwidthMin")} to {getValueFromCustomPlanInfo("CustomBandwidthMax")} GB)
                                </span>
                              </label>
                              <input type="number" {...getFieldProps("bandwidth")} className="form-control form-control-lg" placeholder="0 GB" />
                              <span className="invalid-feedback" style={{ display: Boolean(errors.bandwidth) ? "block" : "none" }}>
                                {errors.bandwidth}
                              </span>
                            </div>
                          </>
                        )}

                        {targetTypeConfig && !targetTypeConfig?.CustomPlanDuration && (
                          <>
                            {/* Default Duration */}
                            <div className="mb-4">
                              <label className="form-label">Days </label>

                              <input
                                className="form-control form-control-lg"
                                placeholder="Days"
                                value={getValueFromCustomPlanInfo("CustomResidentialDays")}
                                disabled
                              />
                            </div>
                          </>
                        )}

                        {targetTypeConfig && targetTypeConfig?.CustomPlanThreads && (
                          <>
                            {/* Threads */}
                            <div className="mb-4">
                              <label className="form-label">
                                Threads{" "}
                                <span className="text-secondary">
                                  (Range: {getValueFromCustomPlanInfo("CustomThreadsMin")} to {getValueFromCustomPlanInfo("CustomThreadsMax")}{" "}
                                  Threads)
                                </span>
                              </label>
                              <input
                                type="number"
                                {...getFieldProps("threads")}
                                className="form-control form-control-lg"
                                placeholder="Number of threads"
                              />
                              <span className="invalid-feedback" style={{ display: Boolean(errors.threads) ? "block" : "none" }}>
                                {errors.threads}
                              </span>
                            </div>
                          </>
                        )}

                        {targetTypeConfig && targetTypeConfig?.CustomPlanDuration && (
                          <>
                            {/* Days */}
                            <div className="mb-4">
                              <label className="form-label">Days</label>
                              <input type="number" {...getFieldProps("days")} className="form-control form-control-lg" placeholder="Number of days" />
                              <span className="invalid-feedback" style={{ display: Boolean(errors.days) ? "block" : "none" }}>
                                {errors.days}
                              </span>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    <div className="mb-4">
                      <label className="form-label">Coupon Code (Optional)</label>
                      <input {...getFieldProps("coupon")} className="form-control form-control-lg" placeholder="Enter coupon code" />
                      <span className="invalid-feedback" style={{ display: Boolean(errors.coupon) ? "block" : "none" }}>
                        {errors.coupon}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="mb-4">
                          <label className="form-label">Payment Gateway</label>
                          <Field
                            name="gender"
                            render={({ field }) => (
                              <>
                                {me?.cryptoPaymentProcessorEnabled && (
                                  <div className="radio-item mb-2">
                                    <input
                                      {...getFieldProps("paymentMethod")}
                                      className="form-check-input"
                                      value="cryptocurrency"
                                      checked={values.paymentMethod === "cryptocurrency"}
                                      type="radio"
                                    />

                                    <label htmlFor="paymentMethod" className="form-check-label" style={{ marginLeft: "0.4rem" }}>
                                      Cryptocurrency
                                    </label>
                                  </div>
                                )}

                                {me?.balancePaymentProcessorEnabled && (
                                  <div className="radio-item">
                                    <input
                                      {...getFieldProps("paymentMethod")}
                                      className="form-check-input"
                                      value="balance"
                                      checked={values.paymentMethod === "balance"}
                                      type="radio"
                                    />
                                    <label htmlFor="paymentMethod" className="form-check-label" style={{ marginLeft: "0.4rem" }}>
                                      Balance
                                    </label>
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>

                        {/* Errors from server */}
                        {errors.afterSubmit && (
                          <div className="alert alert-danger" role="alert">
                            {errors.afterSubmit}
                          </div>
                        )}

                        {/* Sign in */}
                        <div className="d-flex align-items-center">
                          <LoadingButton loading={isSubmitting} type="submit" className="btn btn-primary " style={{ minWidth: "5rem" }}>
                            Pay
                          </LoadingButton>

                          {planId?.toString() === "0" && (
                            <h1 style={{ marginLeft: "1rem", marginBottom: 0, paddingBottom: 0 }}>${customPlanSubtotal}</h1>
                          )}
                          {planId?.toString() !== "0" && (
                            <h1 style={{ marginLeft: "1rem", marginBottom: 0, paddingBottom: 0 }}>
                              ${singlePlanInfo?.price - (singlePlanInfo?.price ? (singlePlanInfo?.price * couponDiscounPercent) / 100 : 0)}
                            </h1>
                          )}
                        </div>
                      </div>

                      <div className="d-none d-lg-block">
                        <label className="form-label mb-2">Supported Cryptocurrencies</label>
                        <div className="row pr-5">
                          <div className="col-6" style={{ paddingRight: "3rem" }}>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/bitcoin.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>BTC = BTC Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/ethereum.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>ETH = ERC20 Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/usd-coin-usdc-logo.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>USDC = ERC20 Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/dogecoin.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>Dogecoin = Dogecoin Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/litecoin.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>LTC = LTC Network</span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/multi-collateral-dai.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>DAI = ERC20 Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/bitcoin-cash.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>BCH = Bitcoin Cash Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/shiba-inu-shib-logo.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>SHIBA = ERC20 Network</span>
                            </div>
                            <div className="d-flex">
                              <img
                                src="/static/svg/logos/tether-usdt.svg"
                                alt=""
                                style={{ maxHeight: "1rem", marginRight: "0.3rem", marginTop: "0.1rem" }}
                              />
                              <span style={{ whiteSpace: "nowrap" }}>USDT = ERC20 Network</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </div>
      </div>
    </>
  );
}
