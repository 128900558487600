import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/images/logo.svg";
import arrow from "../Assets/images/arrow-up-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../redux/slices/generalSlice";

const LandingNavbar = () => {
  const [toggle, setToggle] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const me = useSelector(selectMe);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
    if (token) {
      setLoginStatus(true);
    }
  }, []);

  const token = localStorage.getItem("accessToken");

  return (
    <div>
      {" "}
      <header className="header_section pt-0">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light custom-header">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="Logo" className="img-fluid" />
              </Link>
              <button
                className={`navbar-toggler ${!toggle ? "collapsed" : ""}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded={toggle}
                aria-label="Toggle navigation"
                onClick={() => setToggle(!toggle)}
              >
                <FontAwesomeIcon icon={faBars} style={{ color: "white" }} />
              </button>
              <div
                className={`collapse navbar-collapse  ${
                  !toggle ? "d-none" : "d-block"
                }`}
                // id="navbarText"
              >
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/use-case">
                      Use Case
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/faq">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <form className="d-flex">
                  {!loginStatus ? (
                    <Link to="/signin"> Log In </Link>
                  ) : (
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#FFFFFF",
                        fontWeight: 700,
                        padding: "14px 22px",
                      }}
                    >
                      {me?.username}
                    </p>
                  )}

                  <Link to="/signup">
                    Get Started{" "}
                    <img src={arrow} alt="Arrow" className="img-fluid" />
                  </Link>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default LandingNavbar;
