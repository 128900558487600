import React from "react";
import arrow from "../../Assets/images/arrow-up-icon.svg";
import con01 from "../../Assets/images/con-1.svg";
import con02 from "../../Assets/images/con-2.svg";
import con03 from "../../Assets/images/con-3.svg";
import con04 from "../../Assets/images/con-4.svg";
import LandingNavbar from "../../Components/LandingNavbar";
import LandingFooter from "../../Components/LandingFooter";

const ContactUs = () => {
  return (
    <div>
      {" "}
      {/* header section start */}
      <LandingNavbar />
      {/* header section end */}
      {/* contact section start */}
      <section className="contact-section pa-y4">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h6>CONTACT US</h6>
                <h2 style={{ fontSize: "3.2rem" }}>Got a Query</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                  <br /> Maecenas quis nulla quis nibh laoreet dapibus.
                </p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="contact-box-wrap">
                <img src={con01} alt="Con" className="img-fluid" />
                <a href="#">Skype</a>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-box-wrap">
                <img src={con02} alt="Con" className="img-fluid" />
                <a href="#">proxyside@sj.ms</a>
              </div>
            </div>
          </div>
          <div className="row mt-md-4 justify-content-center">
            <div className="col-lg-3">
              <div className="contact-box-wrap">
                <img src={con03} alt="Con" className="img-fluid" />
                <a href="#">support@proxyside.io</a>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-box-wrap">
                <img src={con04} alt="Con" className="img-fluid" />
                <a href="#">t.me/proxyside</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact section end */}
      {/* get start section start */}
      <section className="get-start py-4 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 col-sm-11 col-12">
              <div className="get-start-bg">
                <div className="common-heading mb-0">
                  <h2>Got Questions? Let’s Talk</h2>
                  <p>
                    In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                    odio ultricies eget. Praesent laoreet libero ac lorem dictum
                    viverra. Donec porta lectus mi, sed maximus orci pulvinar
                    vel.
                  </p>
                  <a href="#">
                    Contact Us <img src={arrow} alt="a" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get start section end */}
      {/* footer section start */}
      <LandingFooter />
      {/* footer section end */}
    </div>
  );
};

export default ContactUs;
