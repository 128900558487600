import { Form, FormikProvider } from "formik";
import React, { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "../../../Components/common/LoadingButton";
import { updateAccountBalance } from "../../../redux/slices/adminSlice";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import NavbarComponent from "../../../Components/navbar/Navbar";

export default function EditAccountBalance() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const balanceInputRef = useRef();
  const [error, seterror] = useState(null);

  const addBalance = () => {
    try {
      if (balanceInputRef.current.value.length === 0) {
        throw "Enter valid positive number";
      }

      const balanceInNumber = parseFloat(balanceInputRef.current.value);

      if (balanceInNumber <= 0) {
        throw "Enter valid positive number";
      } else {
        dispatch(
          updateAccountBalance({
            balanceToAddOrSub: balanceInNumber,
            userId: params.userId,
            navigate,
          })
        );
      }
    } catch (e) {
      console.log(e);
      seterror("Something goes wrong");
    }
  };
  const subtractBalance = () => {
    try {
      if (balanceInputRef.current.value.length === 0) {
        throw "Enter valid positive number";
      }

      const balanceInNumber = parseFloat(balanceInputRef.current.value);

      if (balanceInNumber <= 0) {
        throw "Enter valid positive number";
      } else {
        dispatch(
          updateAccountBalance({
            balanceToAddOrSub: balanceInNumber * -1,
            userId: params.userId,
            navigate,
          })
        );
      }
    } catch (e) {
      console.log(e);
      seterror("Something goes wrong");
    }
  };

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <div className="px-4">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-primary my-2"
            style={{ fontSize: "1rem", width: "85px" }}
          >
            <i className="bi-chevron-left" />
            Back
          </button>
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title my-3">Account Balance</h1>
              </div>
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}

          {/* Content */}
          <div className="page-content">
            <div className="container-lg p-0 m-0">
              {/* Username */}
              <div className="mb-4">
                <label className="form-label">Balance</label>
                <input
                  type="number"
                  ref={balanceInputRef}
                  className="form-control form-control-lg"
                  placeholder="Enter Balance Here"
                />
                <span
                  className="invalid-feedback"
                  style={{ display: error ? "block" : "none" }}
                >
                  {error}
                </span>
              </div>

              {/* Sign in */}
              <div className="hstack gap-2">
                <LoadingButton className="btn btn-success" onClick={addBalance}>
                  Add Balance
                </LoadingButton>
                <LoadingButton
                  className="btn btn-danger"
                  onClick={subtractBalance}
                >
                  Subtract Balance
                </LoadingButton>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    navigate("/admin/accounts");
                  }}
                >
                  Cancel Balance
                </button>
              </div>
            </div>
          </div>
          {/* End Content */}
        </div>
      </div>
    </div>
  );
}
