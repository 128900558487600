import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavbarComponent from "../Components/navbar/Navbar";
import Sidebar from "../Components/sidebar/Sidebar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import AlertCard from "../Components/AlertCard";

// import Toolbar from "../components/common/Toolbar";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { enable2FA, disable2FA, getMFA, selectMFA, sendEditToken, updateBasicInfo, updatePassowrd } from "../redux/slices/publicSlice";
import { useEffect } from "react";
import { selectMe } from "../redux/slices/generalSlice";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [planToDelete, setplanToDelete] = useState(0);
  const openUpdateBasicInfoDialog = useRef("");
  const closeUpdateBasicInfoDialog = useRef("");
  const editTokenInput = useRef("");
  const dispatch = useDispatch();
  const [editTokenError, seteditTokenError] = useState(null);
  const mfa = useSelector(selectMFA);
  const me = useSelector(selectMe);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMFA());
  }, []);

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: me?.username,
      email: me?.email,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      dispatch(sendEditToken({ email: values.email }));
      openUpdateBasicInfoDialog.current.click();
      setSubmitting(false);
      //   await dispatch(editPlan(values));
      setSubmitting(true);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues, setErrors } = formik;

  useEffect(() => {
    setValues({
      username: me?.username,
      email: me?.email,
    });
  }, [me]);
  const resetPasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string().required("New Password is required"),
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);

      dispatch(updatePassowrd({ ...values, setErrors, resetForm, navigate }));

      setSubmitting(false);
      //   await dispatch(editPlan(values));
      setSubmitting(true);
    },
  });

  const twoFAFormSchema = Yup.object().shape({
    totp: Yup.string().required("OTP is required"),
  });

  const twoFAForm = useFormik({
    initialValues: {
      totp: "",
    },
    validationSchema: twoFAFormSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);

      dispatch(enable2FA({ ...values, setErrors, resetForm }));
    },
  });

  console.log(me);
  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />
        <>
          <Helmet>
            <title>My Profile - KocerRoxy</title>
          </Helmet>

          {/* Save basic info modal */}
          <button ref={openUpdateBasicInfoDialog} className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#saveBasicInfoModal">
            Save Basic Info
          </button>

          <div>
            <div className="modal fade" id="saveBasicInfoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title text-success" id="staticBackdropLabel">
                      Save changes
                    </h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className="modal-body">
                    <p>We have sent you the edit token on your email. Please enter the token to save your changes.</p>

                    <div className="row mb-4">
                      <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                        Edit Token{" "}
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-sm-vertical">
                          <input ref={editTokenInput} type="text" className="form-control" placeholder="Enter Token here" />
                        </div>
                      </div>
                    </div>

                    {/* Errors from server */}
                    {editTokenError && (
                      <div className="alert alert-danger" role="alert">
                        {editTokenError}
                      </div>
                    )}
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        seteditTokenError(null);

                        dispatch(
                          updateBasicInfo({
                            editToken: editTokenInput.current.value,
                            email: values.email,
                            username: values.username,
                            seteditTokenError,
                            closeUpdateBasicInfoDialog,
                          })
                        );
                        console.log(editTokenInput.current.value);
                        // closeUpdateBasicInfoDialog.current.click();
                        // dispatch(deletePlan({ editToken: editTokenInput.current.value }));
                      }}
                    >
                      Submit
                    </button>
                    <button ref={closeUpdateBasicInfoDialog} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Page Header */}
          {/* <Toolbar heading="My Profile" /> */}
          {/* End Page Header */}

          <div className="page-content px-4">
            {/* Basic Information and reset password */}
            <div className="container">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Basic information</h3>
                </div>

                <div className="card-body" style={{ borderRadius: "0px" }}>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      {/* ID */}
                      <div className="row mb-4">
                        <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                          ID{" "}
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-vertical">
                            <input type="text" className="form-control" value={me?.id} disabled />
                          </div>
                        </div>
                      </div>

                      {/* Username */}
                      <div className="row mb-4">
                        <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                          Username{" "}
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-vertical">
                            <input {...getFieldProps("username")} type="text" className="form-control" placeholder="Enter Username" />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(touched.username && errors.username) ? "block" : "none",
                              }}
                            >
                              {errors.username}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Email */}
                      <div className="row mb-4">
                        <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                          Email{" "}
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-vertical">
                            <input {...getFieldProps("email")} type="text" className="form-control" placeholder="Enter Email" />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(touched.email && errors.email) ? "block" : "none",
                              }}
                            >
                              {errors.email}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex">
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </Form>
                  </FormikProvider>
                </div>

                <div className="card-header" style={{ borderRadius: "0px" }}>
                  <h3 className="card-title">Change your password</h3>
                </div>

                <div className="card-body" style={{ borderRadius: "0px" }}>
                  <FormikProvider value={resetPasswordFormik}>
                    <Form autoComplete="off" noValidate onSubmit={resetPasswordFormik.handleSubmit}>
                      {/* Current Password */}
                      <div className="row mb-4">
                        <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                          Current Password{" "}
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-vertical">
                            <input
                              {...resetPasswordFormik.getFieldProps("currentPassword")}
                              type="text"
                              className="form-control"
                              placeholder="Enter Current Password "
                            />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(resetPasswordFormik.touched.currentPassword && resetPasswordFormik.errors.currentPassword)
                                  ? "block"
                                  : "none",
                              }}
                            >
                              {resetPasswordFormik.errors.currentPassword}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* New Password */}
                      <div className="row mb-4">
                        <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
                          New Password{" "}
                        </label>
                        <div className="col-sm-9">
                          <div className="input-group input-group-sm-vertical">
                            <input
                              {...resetPasswordFormik.getFieldProps("newPassword")}
                              type="text"
                              className="form-control"
                              placeholder="Enter New Password "
                            />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(resetPasswordFormik.touched.newPassword && resetPasswordFormik.errors.newPassword)
                                  ? "block"
                                  : "none",
                              }}
                            >
                              {resetPasswordFormik.errors.newPassword}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Errors from server */}
                      {resetPasswordFormik.errors.afterSubmit && (
                        <div className="alert alert-danger" role="alert">
                          {resetPasswordFormik.errors.afterSubmit}
                        </div>
                      )}

                      <div className="d-flex">
                        <button type="submit" className="btn btn-primary">
                          Update Password
                        </button>
                      </div>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default MyProfile;
