import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DollarSign, ShoppingBag, User, Grid, Database } from "react-feather";
import { Badge, Col, Card, Row, Button, Container } from "react-bootstrap";
import NavbarComponent from "../../Components/navbar/Navbar";
import Sidebar from "../../Components/sidebar/Sidebar";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import illustration from "../../Assets/img/illustrations/customer-support.png";
import AlertCard from "../../Components/AlertCard";
import "../../Assets/css/dark.css";
import {
  getDashboard,
  selectActiveSubscriptions,
  selectUserStatistics,
} from "../../redux/slices/publicSlice";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../../redux/slices/generalSlice";
import { formatDate, formatTime } from "../../utils/formatTime";
import moment from "moment";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeSubscriptions = useSelector(selectActiveSubscriptions);
  const userStatistics = useSelector(selectUserStatistics);
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(getDashboard());
    dispatch(getMe());
  }, []);

  const formatToHrsAndMinutes = (duration) => {
    const totalMinutes = duration / 60;

    return Math.round(totalMinutes / 60) !== 0
      ? `${Math.round(totalMinutes / 60)} hours ${Math.round(
          totalMinutes % 60
        )} minutes`
      : `${Math.round(totalMinutes % 60)} minutes`;
  };

  return (
    <div className="d-flex">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />
        <Container fluid>
          <Row className="px-4">
            <Col md="6" xl="5" className="d-flex">
              <Card className="illustration flex-fill">
                <Card.Body className="p-0 d-flex flex-fill">
                  <Row className="g-0 w-100">
                    <Col xs="6">
                      <div className="illustration-text p-3 m-1">
                        <h4 className="illustration-text">
                          {"Welcome to ProxySide!"}
                        </h4>
                        {/* <p className="mb-0">Proxyside Dashboard</p> */}
                        <div className="flex-grow-1 ">
                          <p className="mb-2">User</p>
                          <p className="mb-2">{me?.username}</p>
                          <p className="mb-2">{me?.email}</p>
                          <p className="mb-2">
                            Since {formatDate(me?.createdAt)}
                          </p>
                          <Button onClick={() => navigate("/my-profile")}>
                            Change Settings
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="align-self-end text-end">
                      <img
                        src={illustration}
                        alt="Customer Support"
                        className="img-fluid illustration-img"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Row>
                {" "}
                <Col md="6" xl className="d-flex">
                  <Card className="flex-fill">
                    <Card.Body className=" py-4">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <h3 className="mb-2">
                            $ {userStatistics?.totalAmountSpent}
                          </h3>
                          <p className="mb-2">Amount Spent</p>
                        </div>
                        <div className="d-inline-block ms-3">
                          <div className="stat">
                            <DollarSign className="align-middle text-success" />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" xl className="d-flex">
                  <Card className="flex-fill">
                    <Card.Body className=" py-4">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <h3 className="mb-2">
                            {userStatistics?.totalPlansPurchased}
                          </h3>
                          <p className="mb-2">Plans Purchased</p>
                        </div>
                        <div className="d-inline-block ms-3">
                          <div className="stat">
                            <ShoppingBag className="align-middle text-success" />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6" xl className="d-flex">
                  <Card className="flex-fill">
                    <Card.Body className=" py-4">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <h3 className="mb-2">
                            {userStatistics?.totalGBLeft} GB
                          </h3>
                          <p className="mb-2">Data Left</p>
                        </div>
                        <div className="d-inline-block ms-3">
                          <div className="stat">
                            <Database className="align-middle text-success" />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" xl className="d-flex">
                  <Card className="flex-fill">
                    <Card.Body className=" py-4">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <h3 className="mb-2">
                            {userStatistics?.totalGBPurchased} GB
                          </h3>
                          <p className="mb-2">Data Purchased</p>
                        </div>
                        <div className="d-inline-block ms-3">
                          <div className="stat">
                            <Database className="align-middle text-success" />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex mb-3 px-4">
            <h1>Active Subscriptions</h1>
          </div>

          <Row className="px-4">
            {activeSubscriptions.map((singleSub) => {
              return (
                <Col md="6" xl="4" className="d-flex">
                  <Card className="flex-fill">
                    <Card.Body className=" py-4">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1 text-start">
                          <p className="mb-2">ID: {singleSub._id}</p>
                          <h3 className="mb-2 fw-bold">{singleSub.planName}</h3>

                          <div className="flex-fill mb-2">
                            Expiry Date:{" "}
                            {singleSub.renewalDate
                              ? formatTime(singleSub.renewalDate)
                              : "-"}
                            <br />
                            <span>
                              {singleSub?.renewalDate &&
                                moment(singleSub?.renewalDate).diff(
                                  moment().utc(),
                                  "seconds"
                                ) > 0 && (
                                  <span>
                                    (Ends in{" "}
                                    {formatToHrsAndMinutes(
                                      moment(singleSub?.renewalDate).diff(
                                        moment().utc(),
                                        "seconds"
                                      )
                                    )}
                                    )
                                  </span>
                                )}
                            </span>
                          </div>

                          <Link
                            to={`/dashboard/plangenerator/${singleSub._id}`}
                            className="w-100"
                          >
                            <Button>Go To Generator</Button>
                          </Link>
                        </div>
                        <div className="d-inline-block ms-3">
                          <div className="stat">
                            <Grid className="align-middle text-success" />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Dashboard;
