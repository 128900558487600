import { Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../../../Components/common/LoadingButton";
import { createPlan, editPlan, getSinglePlan, selectPlanToEdit } from "../../../redux/slices/adminSlice";
import { getTypeConfig, selectTypesConfig } from "../../../redux/slices/generalSlice";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import NavbarComponent from "../../../Components/navbar/Navbar";

export default function CreateNewPlan({ type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const planToEdit = useSelector(selectPlanToEdit);
  const typesConfig = useSelector(selectTypesConfig);
  const [targetTypeConfig, settargetTypeConfig] = useState(null);

  useEffect(() => {
    dispatch(getTypeConfig());

    if (type === "edit") {
      dispatch(
        getSinglePlan({
          planId: params.planId,
        })
      );
    }
  }, []);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    type: Yup.string().required("Type is required"),
    provider: Yup.string(),
    price: Yup.number().required("Price is required"),
    bandwidthPrices: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required("From is required"),
        to: Yup.string().required("To is required"),
        price: Yup.string().required("Price is required"),
      })
    ),
    threadPrices: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required("From is required"),
        to: Yup.string().required("To is required"),
        price: Yup.string().required("Price is required"),
      })
    ),
    duration: Yup.number().min(1, "Enter value greater than 0").required("Duration is required"),
    bandwidth: Yup.number().min(0, "Enter value greater than 0. Leave empty for unlimited bandwidth."),
    minRenewDays: Yup.string().min(0, "No negative values are allowed"),
    maxRenewDays: Yup.string().min(0, "No negative values are allowed"),
    threads: Yup.number().min(0, "No negative values are allowed"),
    status: Yup.bool().required("Status is required"),
    trial: Yup.bool().required("Trial is required"),
    order: Yup.number().required("Order is required").min(1, "Enter value greater than 0"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: 0,
      provider: "netnut",
      price: "",
      duration: "",
      bandwidth: "",
      threads: "",
      minRenewDays: "",
      maxRenewDays: "",
      status: true,
      trial: false,
      bandwidthPrices: [],
      threadPrices: [],

      order: 1,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);

      if (type === "edit") {
        values["planId"] = params.planId;
        setSubmitting(true);
        await dispatch(editPlan({ ...values, navigate }));
        setSubmitting(false);
      } else {
        setSubmitting(true);
        await dispatch(createPlan({ ...values, navigate }));
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues } = formik;

  useEffect(() => {
    if (typesConfig) {
      settargetTypeConfig(typesConfig[values.type]);
    }
  }, [values.type, typesConfig]);

  useEffect(() => {
    if (type === "edit" && planToEdit !== null) {
      setValues({
        title: planToEdit.title,
        description: planToEdit.description,
        type: planToEdit.type,
        provider: planToEdit.provider,
        price: planToEdit.price,
        duration: planToEdit.duration,
        bandwidth: planToEdit.bandwidth === 0 ? "" : planToEdit.bandwidth,
        threads: planToEdit.threads,
        status: planToEdit.enabled,
        trial: planToEdit.trial,
        minRenewDays: planToEdit.minRenewDays,
        maxRenewDays: planToEdit.maxRenewDays,
        bandwidthPrices: planToEdit.bandwidthPrices,
        threadPrices: planToEdit.threadPrices,
        order: planToEdit.order,
      });
    }
  }, [planToEdit]);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />

        <>
          {/* Content */}
          <div className="page-content">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container-lg">
                  <div className="page-header">
                    <div className="row align-items-center">
                      <div className="col-sm mb-2 mb-sm-0 pt-3 pb-3">
                        <button onClick={() => navigate(-1)} className="btn btn-primary my-3" style={{ fontSize: "1rem", width: "85px" }}>
                          <i className="bi-chevron-left" />
                          Back
                        </button>
                        <h1 className="page-header-title">{type === "edit" ? "Edit Plan" : "Create New Plan"} </h1>
                      </div>
                    </div>
                    {/* End Row */}
                  </div>

                  {/* Plan basic info */}
                  <div className="card mb-3">
                    <div className="card-header">
                      <h2 className="card-title h4">Plan Information</h2>
                    </div>

                    <div className="card-body">
                      {/* Title */}
                      <div className="mb-4">
                        <label className="form-label">Title</label>
                        <input {...getFieldProps("title")} className="form-control form-control-lg" placeholder="Enter Plan Title" />
                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(touched.title && errors.title) ? "block" : "none",
                          }}
                        >
                          {errors.title}
                        </span>
                      </div>

                      {/* Description */}
                      <div className="mb-4">
                        <label className="form-label">Description</label>
                        <textarea
                          rows={7}
                          {...getFieldProps("description")}
                          className="form-control form-control-lg"
                          placeholder="Plan Description"
                        />
                        <span
                          className="invalid-feedback"
                          style={{
                            display: Boolean(touched.description && errors.description) ? "block" : "none",
                          }}
                        >
                          {errors.description}
                        </span>
                      </div>

                      <div className="row">
                        {/* Type */}
                        <div className="mb-4 col-4">
                          <label className="form-label">Type</label>
                          <select {...getFieldProps("type")} className="form-select form-control form-control-lg" placeholder="Type">
                            {typesConfig &&
                              Object.keys(typesConfig).map((typeID) => {
                                return (
                                  <option key={`type_${typeID}`} value={typesConfig[typeID].ID}>
                                    {typesConfig[typeID].Name}
                                  </option>
                                );
                              })}
                          </select>
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.type && errors.type) ? "block" : "none",
                            }}
                          >
                            {errors.type}
                          </span>
                        </div>

                        {formik.values.type == 0 && (
                          <>
                            {/* Provider */}
                            <div className="mb-4 col-4">
                              <label className="form-label">Provider</label>
                              <select {...getFieldProps("provider")} className="form-select form-control form-control-lg" placeholder="Provider">
                                <option key={`netnut`} value={"netnut"}>
                                  Netnut
                                </option>

                                <option key={`smartproxy`} value={"smartproxy"}>
                                  SmartProxy
                                </option>

                                <option key={`kerio`} value={"kerio"}>
                                  Kerio
                                </option>
                              </select>
                              <span
                                className="invalid-feedback"
                                style={{
                                  display: Boolean(touched.provider && errors.provider) ? "block" : "none",
                                }}
                              >
                                {errors.provider}
                              </span>
                            </div>
                          </>
                        )}

                        {/* Price */}
                        <div className="mb-4 col-4">
                          <label className="form-label">Price ($)</label>
                          <input type="number" {...getFieldProps("price")} className="form-control form-control-lg" placeholder="$0.00" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.price && errors.price) ? "block" : "none",
                            }}
                          >
                            {errors.price}
                          </span>
                        </div>

                        {/* Duration */}
                        <div className="mb-4 col-4">
                          <label className="form-label">Duration (seconds)</label>
                          <input type="number" {...getFieldProps("duration")} className="form-control form-control-lg" placeholder="0 Sec" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.duration && errors.duration) ? "block" : "none",
                            }}
                          >
                            {errors.duration}
                          </span>
                        </div>

                        {/* bandwidth */}
                        <div className="mb-4">
                          <label className="form-label">
                            Bandwidth <span className="text-secondary">(Leave empty for unlimited bandwidth)</span>
                          </label>
                          <input type="number" {...getFieldProps("bandwidth")} className="form-control form-control-lg" placeholder="0.00 GB" />
                          <span
                            className="invalid-feedback"
                            style={{
                              display: Boolean(touched.bandwidth && errors.bandwidth) ? "block" : "none",
                            }}
                          >
                            {errors.bandwidth}
                          </span>
                        </div>

                        {targetTypeConfig && targetTypeConfig?.HasThreads && (
                          <>
                            {/* Threads */}
                            <div className="mb-4">
                              <label className="form-label">Threads</label>
                              <input
                                type="number"
                                {...getFieldProps("threads")}
                                className="form-control form-control-lg"
                                placeholder="Number of Threads"
                              />
                              <span
                                className="invalid-feedback"
                                style={{
                                  display: Boolean(touched.threads && errors.threads) ? "block" : "none",
                                }}
                              >
                                {errors.threads}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Extend plan */}
                  <div className="card mb-3">
                    <div className="card-header">
                      <h2 className="card-title h4">Plan Renew</h2>
                    </div>

                    <div className="card-body">
                      {targetTypeConfig && targetTypeConfig?.RenewBandwidth && (
                        <>
                          {/* BandWidth prices */}
                          <div className="mb-4">
                            <label className="form-label">
                              Bandwidth Prices <span className="text-secondary">($ per GB)</span>
                            </label>

                            <FieldArray
                              name="bandwidthPrices"
                              {...getFieldProps("bandwidthPrices")}
                              render={(arrayHelpers) => (
                                <div>
                                  {values.bandwidthPrices.map((option, index) => (
                                    <div key={index}>
                                      <div className="row" style={{ marginBottom: "0.5rem" }}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`From`}
                                                name={`bandwidthPrices.${index}.from`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("bandwidthPrices") &&
                                                    errors[`bandwidthPrices`]?.length != 0 &&
                                                    errors[`bandwidthPrices`][index]?.from
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                From is required
                                              </span>
                                            </div>
                                            <div className="col-4">
                                              <Field
                                                placeholder={`To`}
                                                name={`bandwidthPrices.${index}.to`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("bandwidthPrices") &&
                                                    errors[`bandwidthPrices`]?.length != 0 &&
                                                    errors[`bandwidthPrices`][index]?.to
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                To is required
                                              </span>
                                            </div>
                                            <div className="col-4">
                                              <Field
                                                placeholder={`Price`}
                                                name={`bandwidthPrices.${index}.price`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("bandwidthPrices") &&
                                                    errors[`bandwidthPrices`]?.length != 0 &&
                                                    errors[`bandwidthPrices`][index]?.price
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Price is required
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2">
                                          <button
                                            className="btn btn-danger"
                                            style={{ minHeight: "2.8rem" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  <button onClick={() => arrayHelpers.push("")} className="btn btn-primary">
                                    + Add Bandwidth Range
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        </>
                      )}

                      {targetTypeConfig && targetTypeConfig?.RenewThread && (
                        <>
                          {/* Thread prices */}
                          <div className="mb-4">
                            <label className="form-label">
                              Duration Price <span className="text-secondary">($ per day per thread when applicable)</span>
                            </label>

                            <FieldArray
                              name="threadPrices"
                              {...getFieldProps("threadPrices")}
                              render={(arrayHelpers) => (
                                <div>
                                  {values.threadPrices.map((option, index) => (
                                    <div key={index}>
                                      <div className="row" style={{ marginBottom: "0.5rem" }}>
                                        <div className="col-10">
                                          <div className="row">
                                            <div className="col-4">
                                              <Field
                                                placeholder={`From`}
                                                name={`threadPrices.${index}.from`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("threadPrices") &&
                                                    errors[`threadPrices`]?.length != 0 &&
                                                    errors[`threadPrices`][index]?.from
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                From is required
                                              </span>
                                            </div>
                                            <div className="col-4">
                                              <Field placeholder={`To`} name={`threadPrices.${index}.to`} className="form-control form-control-lg" />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("threadPrices") &&
                                                    errors[`threadPrices`]?.length != 0 &&
                                                    errors[`threadPrices`][index]?.to
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                To is required
                                              </span>
                                            </div>
                                            <div className="col-4">
                                              <Field
                                                placeholder={`Price`}
                                                name={`threadPrices.${index}.price`}
                                                className="form-control form-control-lg"
                                              />
                                              <span
                                                className="invalid-feedback"
                                                style={{
                                                  display:
                                                    Object.keys(errors).includes("threadPrices") &&
                                                    errors[`threadPrices`]?.length != 0 &&
                                                    errors[`threadPrices`][index]?.price
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                Price is required
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-2">
                                          <button
                                            className="btn btn-danger"
                                            style={{ minHeight: "2.8rem" }}
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  <button onClick={() => arrayHelpers.push("")} className="btn btn-primary">
                                    + Add Threads Range
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        </>
                      )}

                      {targetTypeConfig && targetTypeConfig?.RenewDuration && (
                        <>
                          {/* Min days */}
                          <div className="mb-4">
                            <label className="form-label">Minimum Days</label>
                            <input
                              type="number"
                              {...getFieldProps("minRenewDays")}
                              className="form-control form-control-lg"
                              placeholder="Minimum number of days"
                            />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(touched.minRenewDays && errors.minRenewDays) ? "block" : "none",
                              }}
                            >
                              {errors.minRenewDays}
                            </span>
                          </div>

                          {/* Max days */}
                          <div className="mb-4">
                            <label className="form-label">Maximum Days</label>
                            <input
                              type="number"
                              {...getFieldProps("maxRenewDays")}
                              className="form-control form-control-lg"
                              placeholder="Maximum number of days"
                            />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(touched.maxRenewDays && errors.maxRenewDays) ? "block" : "none",
                              }}
                            >
                              {errors.maxRenewDays}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Plan Order */}
                  <div className="card mb-3">
                    <div className="card-header">
                      <h2 className="card-title h4">Plan Order</h2>
                      <p className="mb-0">Plans will appear according to this order on purchase plan page</p>
                    </div>

                    <div className="card-body">
                      {/* Order */}
                      <label className="form-label">Order</label>
                      <input
                        type="number"
                        {...getFieldProps("order")}
                        className="form-control form-control-lg"
                        placeholder="Maximum number of days"
                      />
                      <span
                        className="invalid-feedback"
                        style={{
                          display: Boolean(touched.order && errors.order) ? "block" : "none",
                        }}
                      >
                        {errors.order}
                      </span>
                    </div>
                  </div>

                  {/* Plan status */}
                  <div className="card mb-3">
                    <div className="card-header">
                      <h2 className="card-title h4">Plan Status</h2>
                    </div>

                    <div className="card-body">
                      {/* Status */}
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <input
                            {...getFieldProps("status")}
                            checked={values.status}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                          />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            Status (Enabled/Disabled)
                          </label>
                        </div>
                      </div>

                      {/* Trial */}
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <input
                            {...getFieldProps("trial")}
                            checked={values.trial}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                          />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            Trial
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  {/* Sign in */}
                  <div className="text-center pb-4 pt-2">
                    <LoadingButton loading={isSubmitting} type="submit" className="btn btn-primary" style={{ minWidth: "6rem" }}>
                      Save
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </>
      </div>
    </div>
  );
}
