import React from "react";
import { Button, Col } from "react-bootstrap";
import NavbarComponent from "../Components/navbar/Navbar";
import Sidebar from "../Components/sidebar/Sidebar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import AlertCard from "../Components/AlertCard";

const Discord = () => {
  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div>
        <NavbarComponent />
        <AlertCard />
        <div className="mx-4 text-start">
          <Col className="col-lg-8 col-sm-12">
            <h1>Discord</h1>
            <p className="topup-sub-text">Connect to discord.</p>
            <h4 className="text-danger">
              {" "}
              Only customers can connect to our Discord community
            </h4>
            <p className="text-danger">
              You must purchase at least one subscription to enable this feature
            </p>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Discord;
