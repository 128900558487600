import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AlertCard from "../Components/AlertCard";
import LoadingButton from "../Components/common/LoadingButton";
import NavbarComponent from "../Components/navbar/Navbar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import Sidebar from "../Components/sidebar/Sidebar";
import { CheckIsSuggestionAllowed, createNewSuggestion, selectSuggestionAllowed } from "../redux/slices/publicSlice";

export default function Suggestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSuggestionAllowed = useSelector(selectSuggestionAllowed);

  useEffect(() => {
    dispatch(CheckIsSuggestionAllowed());
  }, []);

  const RegisterSchema = Yup.object().shape({
    Suggestion: Yup.string().required("Suggestion is required"),
  });

  const formik = useFormik({
    initialValues: {
      Suggestion: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      await dispatch(createNewSuggestion({ ...values, navigate }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues } = formik;

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />

        <>
          <Helmet>
            <title>Suggestion - KocerRoxy</title>
          </Helmet>

          {/* Content */}
          <div className="page-content container">
            {isSuggestionAllowed === null && (
              <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh" }}>
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {isSuggestionAllowed === false && (
              <div className="">
                <div className={`alert alert-success`} role="alert">
                  <h3 className="text-white mt-2">Thank you for your suggestion! You can suggest once every 3 days.</h3>
                </div>
              </div>
            )}

            {isSuggestionAllowed === true && (
              <div className="container">
                <h1>Add your suggestion</h1>
                <p>We would like your feedback to improve our website</p>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {/* Suggestion */}
                    <div className="mb-4">
                      <textarea
                        rows={10}
                        {...getFieldProps("Suggestion")}
                        className="form-control form-control-lg"
                        placeholder="Enter your suggestion here"
                      />
                      <span className="invalid-feedback" style={{ display: Boolean(touched.Suggestion && errors.Suggestion) ? "block" : "none" }}>
                        {errors.Suggestion}
                      </span>
                    </div>

                    {/* Errors from server */}
                    {errors.afterSubmit && (
                      <div className="alert alert-danger" role="alert">
                        {errors.afterSubmit}
                      </div>
                    )}

                    {/* Sign in */}

                    <LoadingButton loading={isSubmitting} type="submit" className="btn btn-primary ">
                      Submit Suggestion
                    </LoadingButton>
                  </Form>
                </FormikProvider>
              </div>
            )}
          </div>
          {/* End Content */}
        </>
      </div>
    </div>
  );
}
