import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../Assets/css/landing.css";
import mainbg from "../../Assets/images/main-bg.svg";
import arrow from "../../Assets/images/arrow-up-icon.svg";
import top01 from "../../Assets/images/top-1.svg";
import top02 from "../../Assets/images/top-2.svg";
import top03 from "../../Assets/images/top-3.svg";
import feat01 from "../../Assets/images/feat-01.svg";
import feat02 from "../../Assets/images/feat-02.svg";
import feat03 from "../../Assets/images/feat-03.svg";
import feat04 from "../../Assets/images/feat-04.svg";
import feat05 from "../../Assets/images/feat-05.svg";
import feat06 from "../../Assets/images/feat-06.svg";
import avt01 from "../../Assets/images/avt-1.png";
import avt02 from "../../Assets/images/avt-2.png";
import avt03 from "../../Assets/images/avt-3.png";
import avt04 from "../../Assets/images/avt-4.png";
import avatar from "../../Assets/images/avatar.png";
import LandingNavbar from "../../Components/LandingNavbar";
import LandingFooter from "../../Components/LandingFooter";

const Homepage = () => {
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const getPrices = () => {
      axios
        .get(`${backendServerBaseURL}/landing`)
        .then((data) => {
          setPrices(data.data.payload.Plans);
        })
        .catch((error) => console.log(error));
    };

    getPrices();
  }, []);
  return (
    <div>
      {" "}
      {/* hero section start */}
      <section className="hero-section">
        {/* header section start */}
        <LandingNavbar />
        {/* header section end */}
        <div
          className="container"
          style={{ backgroundImage: "url(mainbg) !important" }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="hero-txt-wrap">
                <h6>
                  <span>NEW</span> Introducing Special Monthly and Annual Plans
                </h6>
                <h1>
                  The Best Private <br />
                  Residential Proxy Network
                </h1>
                <p>
                  10+ million residential IPs. Try the fastest proxies for your
                  business.Buy <br /> residential rotating HTTP &amp; HTTPS
                  Anonymous private proxies!/p&gt;
                </p>
                <div className="hero_btn">
                  <Link to="/signup">
                    Get Started{" "}
                    <img src={arrow} alt="Grey" className="img-fluid" />
                  </Link>
                  <Link to="/pricing">View Pricing</Link>
                </div>
                <div className="hero-bottm-wrap">
                  <ul>
                    <li>
                      <a href="#">
                        <img src={avt01} alt="a" className="img-fluid" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={avt02} alt="a" className="img-fluid" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={avt03} alt="a" className="img-fluid" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={avt04} alt="a" className="img-fluid" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>+1k</span>
                      </a>
                    </li>
                  </ul>
                  <div className="hero-rgt">
                    <h5>
                      Rated 4.9
                      <FontAwesomeIcon icon={faStar} className="text-success" />
                      on TrustPilot
                    </h5>
                    <span>By 1000+ Customers</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* hero section end */}
      {/* how to section start */}
      <section className="how-to-sec pa-y4 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="border-top" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap">
                <div className="d-flex">
                  <img src={top01} alt="Top Icon" className="img-fluid" />
                  <h6>
                    Proxies for <br />
                    Price Comparison
                  </h6>
                </div>
                <p>
                  Analyze, compare and collect data from any sales channel every
                  day. Nunc vestibulum nulla sit amet mi rhoncus euismod.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap">
                <div className="d-flex">
                  <img src={top02} alt="Top Icon" className="img-fluid" />
                  <h6>
                    Proxies for <br />
                    Sneaker Purchase
                  </h6>
                </div>
                <p>
                  Our High Quality pools are perfectly optimized for
                  super-limited cop sneakers. Happy copping chances. Geo-Target.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap howto-box-wrap-2">
                <div className="d-flex">
                  <img src={top03} alt="Top Icon" className="img-fluid" />
                  <h6>
                    Proxies for <br />
                    Data Collection
                  </h6>
                </div>
                <p>
                  Our residential proxies work perfect for collecting data from
                  any search engines. Start super fast scraping of google,
                  yahoo.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="more-info">
                <a href="#">
                  See all Use Cases{" "}
                  <img src={arrow} alt="Arrow" className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* how to section end */}
      {/* feat section start */}
      <section className="feature-section pa-y4">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h2>Why we are the Best</h2>
                <p>
                  In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                  odio ultricies eget. Praesent laoreet libero ac <br /> lorem
                  dictum viverra. Donec porta lectus mi, sed maximus orci
                  pulvinar vel.
                </p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap feat-box-wrap">
                <div className="d-flex">
                  <img src={feat01} alt="Top Icon" className="img-fluid" />
                  <h6>Geo-target</h6>
                </div>
                <p>
                  Residential IPs from 180+ locations. By the way, we can sort
                  proxy lists by any country.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap feat-box-wrap">
                <div className="d-flex">
                  <img src={feat02} alt="Top Icon" className="img-fluid" />
                  <h6>Easy start</h6>
                </div>
                <p>
                  Residential IPs from 180+ locations. By the way, we can sort
                  proxy lists by any country.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap feat-box-wrap">
                <div className="d-flex">
                  <img src={feat03} alt="Top Icon" className="img-fluid" />
                  <h6>Rotating IP option</h6>
                </div>
                <p>
                  Automatic change IP addresses with each port request or every
                  10 mins.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap feat-box-wrap">
                <div className="d-flex">
                  <img src={feat04} alt="Top Icon" className="img-fluid" />
                  <h6>Increase business income</h6>
                </div>
                <p>
                  Perfect proxies for Web Scraping, Social, ad verification,
                  price comparison, SEO, market research, brand protection, and
                  any other targets.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap feat-box-wrap">
                <div className="d-flex">
                  <img src={feat05} alt="Top Icon" className="img-fluid" />
                  <h6>Various Pricing Plans</h6>
                </div>
                <p>
                  Choose proxy pools between fixed monthly pricing per Threads
                  limit with Socks5 private proxies or pay by the GB for
                  residential http-s service.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="howto-box-wrap feat-box-wrap">
                <div className="d-flex">
                  <img src={feat06} alt="Top Icon" className="img-fluid" />
                  <h6>Confidentiality</h6>
                </div>
                <p>
                  Privacy made easy with Wickr Me. Our customer support team
                  available only in Wickr Me or own encrypted email to
                  communicate with you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* feat section end */}
      {/* pricing section start */}
      <section className="pricing-section pa-y4">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h2>Best Price for the Best Proxies</h2>
                <p>
                  In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                  odio ultricies eget. Praesent laoreet libero ac <br /> lorem
                  dictum viverra. Donec porta lectus mi, sed maximus orci
                  pulvinar vel.
                </p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row">
            <div className="col-lg-12">
              <div className="pricing-filter-head">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="residential-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#residential"
                      type="button"
                      role="tab"
                      aria-controls="residential"
                      aria-selected="true"
                    >
                      Rotating Residential
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="sneaker-proxy-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#sneaker-proxy"
                      type="button"
                      role="tab"
                      aria-controls="sneaker-proxy"
                      aria-selected="false"
                    >
                      Sneaker Proxies
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="residential"
                  role="tabpanel"
                  aria-labelledby="residential-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    {prices.length &&
                      prices
                        .filter((item) => item.provider === "netnut")
                        .map((item) => (
                          <>
                            {/* pricing single item start */}
                            <div className="col-lg-4 col-md-6" key={item._id}>
                              <div className="pricing-box-wrap">
                                <div className="d-flex ">
                                  <h4>{item.title}</h4>
                                  <h3>
                                    <sup>$</sup>
                                    {item.price}
                                    <span>/per GB</span>
                                  </h3>
                                </div>
                                <ul>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      {item.bandwidth == "0"
                                        ? "Unlimited "
                                        : item.bandwidth + " GB "}
                                      Traffic Included
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Access
                                      Over 30M Residential IPs
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      Rotating &amp; sticky
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      Geo-targeting
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      Unlimited threads
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      HTTP\HTTPS Protocols
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> 60 days
                                      expire time
                                    </p>
                                  </li>
                                </ul>
                                <div className="price-bttn">
                                  <Link to="/signup">
                                    Checkout{" "}
                                    <img
                                      src={arrow}
                                      alt="Arrow"
                                      className="img-fluid"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* pricing single item end */}
                          </>
                        ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="sneaker-proxy"
                  role="tabpanel"
                  aria-labelledby="sneaker-proxy-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    {prices.length &&
                      prices
                        .filter((item) => item.provider === "smartproxy")
                        .map((item) => (
                          <>
                            {/* pricing single item start */}
                            <div className="col-lg-4 col-md-6" key={item._id}>
                              <div className="pricing-box-wrap">
                                <div className="d-flex">
                                  <h4>{item.title}</h4>
                                  <h3>
                                    <sup>$</sup>
                                    {item.price}
                                    <span>/per GB</span>
                                  </h3>
                                </div>
                                <ul>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      {item.bandwidth == "0"
                                        ? "Unlimited "
                                        : item.bandwidth + " GB "}
                                      Traffic Included
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> Access
                                      Over 30M Residential IPs
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      Rotating &amp; sticky
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      Geo-targeting
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      Unlimited threads
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} />{" "}
                                      HTTP\HTTPS Protocols
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <FontAwesomeIcon icon={faCheck} /> 60 days
                                      expire time
                                    </p>
                                  </li>
                                </ul>
                                <div className="price-bttn">
                                  <Link to="/signup">
                                    Checkout{" "}
                                    <img
                                      src={arrow}
                                      alt="Arrow"
                                      className="img-fluid"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {/* pricing single item end */}
                          </>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* pricing section end */}
      {/* customer say section start */}
      <section className="customer-say pa-y4">
        <div className="container">
          {/* common heading start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading">
                <h2>What our Customers say</h2>
                <p>
                  In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                  odio ultricies eget. Praesent laoreet libero ac <br /> lorem
                  dictum viverra. Donec porta lectus mi, sed maximus orci
                  pulvinar vel.
                </p>
              </div>
            </div>
          </div>
          {/* common heading end */}
          <div className="row">
            <div className="col-lg-12">
              <div className="customer-say-box-wrap">
                <div className="media">
                  <img src={avatar} alt="Avatar" className="img-fluid" />
                  <div className="media-body">
                    <h6>
                      John Doe
                      <span className="media-name">
                        {" "}
                        Customer Since Oct 2021
                      </span>
                    </h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vivamus dolor sapien, malesuada et dui a, egestas pharetra
                      diam. Vestibulum et dolor massa. Aliquam erat volutpat. In
                      tristique erat eget ornare molestie. Sed dapibus neque
                      urna, venenatis commodo nulla elementum at. Pellentesque
                      sit amet quam eget augue egestas scelerisque vel ac mi.
                      Vestibulum at nisi non ipsum rutrum sollicitudin vitae et
                      urna. Curabitur at facilisis orci, ut tempor sapien.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* customer say section end */}
      {/* get start section start */}
      <section className="get-start py-4 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 col-sm-11 col-12">
              <div className="get-start-bg">
                <div className="common-heading mb-0 get-start-content">
                  <h2>Got Questions? Let’s Talk</h2>
                  <p>
                    In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                    odio ultricies eget. Praesent laoreet libero ac lorem dictum
                    viverra. Donec porta lectus mi, sed maximus orci pulvinar
                    vel.
                  </p>
                  <a href="#">
                    Contact Us <img src={arrow} alt="a" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get start section end */}
      <LandingFooter />
    </div>
  );
};

export default Homepage;
