import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deletePlan,
  getAllPlans,
  selectAllPlans,
} from "../../../redux/slices/adminSlice";
import { formatTime } from "../../../utils/formatTime";
import ReactTooltip from "react-tooltip";
import {
  getTypeConfig,
  selectTypesConfig,
} from "../../../redux/slices/generalSlice";
import { Helmet } from "react-helmet-async";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import NavbarComponent from "../../../Components/navbar/Navbar";

const Plans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allPlans = useSelector(selectAllPlans);
  const [selectedPlan, setselectedPlan] = useState(null);
  const [planToDelete, setplanToDelete] = useState(0);
  const typesConfig = useSelector(selectTypesConfig);
  const [loading, setloading] = useState(true);

  useEffect(async () => {
    setloading(true);
    await dispatch(getTypeConfig());
    await dispatch(getAllPlans());
    setloading(false);
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />

        <Helmet>
          <title>Admin Plans</title>
        </Helmet>

        {loading && (
          <div
            className="w-100 d-flex flex-column align-items-center justify-content-center"
            style={{ height: "80%" }}
          >
            <div class="spinner-border text-primary mb-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p>Please Wait</p>
          </div>
        )}

        {!loading && (
          <>
            {/* View Plan Modal */}
            <div>
              <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title" id="staticBackdropLabel">
                        {selectedPlan?.title}
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <p>
                        <span style={{ fontWeight: "bold" }}>Title: </span>{" "}
                        {selectedPlan?.title}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Description:{" "}
                        </span>{" "}
                        {selectedPlan?.description}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Type: </span>{" "}
                        {selectedPlan?.readableType}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Price: </span> $
                        {selectedPlan?.price}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Duration: </span>{" "}
                        {selectedPlan?.duration} Sec
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Bandwidth: </span>{" "}
                        {selectedPlan?.bandwidth === 0
                          ? "Unlimited"
                          : `${selectedPlan?.bandwidth} GB`}
                      </p>

                      {selectedPlan &&
                        typesConfig[selectedPlan.type].RenewBandwidth && (
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              Bandwidth Prices:{" "}
                            </span>
                            {selectedPlan?.bandwidthPrices.map((bp) => {
                              return (
                                <>
                                  <br />
                                  <div className="row p-2">
                                    <div className="col-4 border pt-2 pb-2">
                                      <span style={{ paddingRight: 24 }}>
                                        From: {bp?.from} GB
                                      </span>
                                    </div>
                                    <div className="col-4 border pt-2 pb-2">
                                      <span style={{ paddingRight: 24 }}>
                                        To: {bp?.to} GB
                                      </span>
                                    </div>
                                    <div className="col-4 border pt-2 pb-2">
                                      <span style={{ paddingRight: 24 }}>
                                        Price: ${bp?.price} / GB
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </p>
                        )}

                      {selectedPlan &&
                        typesConfig[selectedPlan.type].RenewDuration && (
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              Thread Prices:{" "}
                            </span>
                            {selectedPlan?.threadPrices.map((dp) => {
                              return (
                                <>
                                  <br />
                                  <div className="row  p-2">
                                    <div className="col-3 border pt-2 pb-2">
                                      <span style={{ paddingRight: 24 }}>
                                        From: {dp?.from}
                                      </span>
                                    </div>
                                    <div className="col-3 border pt-2 pb-2">
                                      <span style={{ paddingRight: 24 }}>
                                        To: {dp?.to}
                                      </span>
                                    </div>
                                    <div className="col-6 border pt-2 pb-2">
                                      <span style={{ paddingRight: 24 }}>
                                        Price: ${dp?.price}/Day/Thread
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </p>
                        )}

                      {selectedPlan &&
                        typesConfig[selectedPlan.type].HasThreads && (
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              Threads:{" "}
                            </span>{" "}
                            {selectedPlan?.threads}
                          </p>
                        )}

                      <p>
                        <span style={{ fontWeight: "bold" }}>Enabled: </span>
                        {selectedPlan?.enabled ? (
                          <span className="legend-indicator bg-success" />
                        ) : (
                          <span className="legend-indicator bg-secondary" />
                        )}
                        {selectedPlan?.enabled ? "Enabled" : "Disabled"}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Trial: </span>{" "}
                        {selectedPlan?.trial}
                        {selectedPlan?.trial ? (
                          <span className="legend-indicator bg-success" />
                        ) : (
                          <span className="legend-indicator bg-secondary" />
                        )}
                        {selectedPlan?.trial ? "Enabled" : "Disabled"}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Create on: </span>{" "}
                        {formatTime(selectedPlan?.createdAt)}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Modified on:{" "}
                        </span>{" "}
                        {formatTime(selectedPlan?.updatedAt)}
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Delete Plan Modal */}
            <div>
              <div
                className="modal fade"
                id="deletePlanModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3
                        className="modal-title text-danger"
                        id="staticBackdropLabel"
                      >
                        You are permanently deleting this plan
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <p>
                        <span style={{ fontWeight: "bold" }}>Plan Name: </span>{" "}
                        {planToDelete?.title}
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          Plan Type:{" "}
                        </span>{" "}
                        {planToDelete?.readableType}
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          Enabled:{" "}
                        </span>{" "}
                        {planToDelete.enabled ? (
                          <span className="legend-indicator bg-success" />
                        ) : (
                          <span className="legend-indicator bg-secondary" />
                        )}
                        {planToDelete.enabled ? "Enabled" : "Disabled"}
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          dispatch(deletePlan({ planId: planToDelete._id }));
                        }}
                      >
                        Delete This Plan
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="page-content mt-4 px-4">
              <div class="card">
                <div
                  className="col-12 d-flex align-items-center justify-content-between"
                  style={{ padding: 16 }}
                >
                  <div className="col-6 d-flex align-items-center">
                    <i
                      class="bi bi-grid nav-icon"
                      style={{
                        fontSize: "1.8rem",
                        color: "#345ded",
                        marginRight: 8,
                      }}
                    ></i>
                    <h1 style={{ marginTop: 8 }}>All Plans</h1>
                  </div>

                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/admin/plans/new");
                    }}
                  >
                    Create New Plan
                  </button>
                </div>

                <div className="table-responsive datatable-custom">
                  <table
                    id="datatable"
                    className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Plan name</th>
                        <th>Type</th>
                        <th>Bandwidth</th>
                        <th>Duration</th>
                        <th>Threads</th>
                        <th>Order</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPlans.map((plan, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {plan.enabled ? (
                                <span className="legend-indicator bg-success" />
                              ) : (
                                <span className="legend-indicator bg-secondary" />
                              )}
                              {plan.enabled ? "Enabled" : "Disabled"}
                            </td>
                            <td>
                              {plan.trial && <> [Trial] </>}
                              {plan.title}
                            </td>
                            <td>{plan.readableType}</td>
                            <td>
                              {plan.bandwidth === 0
                                ? "Unlimited"
                                : `${plan.bandwidth} GB`}
                            </td>
                            <td>{plan.duration} Sec</td>
                            <td>{plan.threads}</td>
                            <td>{plan.order}</td>

                            <td>{formatTime(plan.createdAt)}</td>
                            <td>{formatTime(plan.updatedAt)}</td>
                            <td>
                              <i
                                data-tip
                                data-for="viewBtn"
                                class="bi bi-eye-fill icon-button text-primary"
                                style={{
                                  marginRight: "0.7rem",
                                  cursor: "pointer",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={() => {
                                  setselectedPlan(plan);
                                }}
                              ></i>
                              <ReactTooltip
                                id="viewBtn"
                                effect="float"
                                delayShow={400}
                              >
                                <span>View</span>
                              </ReactTooltip>

                              <i
                                data-tip
                                data-for="editBtn"
                                style={{
                                  marginRight: "0.7rem",
                                  cursor: "pointer",
                                }}
                                class="bi bi-pencil-fill icon-button text-warning"
                                onClick={() => {
                                  navigate(`/admin/plans/edit/${plan._id}`);
                                }}
                              ></i>
                              <ReactTooltip
                                id="editBtn"
                                effect="float"
                                delayShow={400}
                              >
                                <span>Edit</span>
                              </ReactTooltip>

                              <i
                                data-tip
                                data-for="deleteBtn"
                                class="bi bi-trash-fill icon-button text-danger"
                                style={{
                                  marginRight: "0.7rem",
                                  cursor: "pointer",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#deletePlanModal"
                                onClick={() => {
                                  setplanToDelete(plan);
                                }}
                              ></i>
                              <ReactTooltip
                                id="deleteBtn"
                                effect="float"
                                delayShow={400}
                              >
                                <span>Delete</span>
                              </ReactTooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* End Content */}
          </>
        )}
      </div>
    </div>
  );
};

export default Plans;
