import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import useSidebar from "../../Hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import { adminItems } from "../../Components/sidebar/dashboardItems";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../../redux/slices/generalSlice";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();
  const [finalItemsList, setfinalItemsList] = useState([...dashboardItems]);
  const me = useSelector(selectMe);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    setfinalItemsList([...dashboardItems]);
    if (me) {
      if (me.role == "Administrator") {
        setfinalItemsList([...dashboardItems, adminItems]);
      }
    }
  }, [me]);

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <div className="d-flex flex-column align-items-center h-100">
          <div>
            <a className="sidebar-brand p-0" href="/">
              <Logo />
            </a>

            <SidebarNav
              items={[
                {
                  title: "Pages",
                  pages: finalItemsList,
                },
              ]}
            />
          </div>

          {!!showFooter && <SidebarFooter />}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
