import { Field, Form, FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../Components/common/LoadingButton";
import {
  getMe,
  getTypeConfig,
  selectMe,
  selectTypesConfig,
} from "../redux/slices/generalSlice";
import {
  getCustomPlanInfo,
  getSingleSubscription,
  renewSubscription,
  selectCustomPlanInfo,
  selectTargetSubscriptionInfo,
} from "../redux/slices/publicSlice";
import { formatTime } from "../utils/formatTime";
import NavbarComponent from "../Components/navbar/Navbar";
import Sidebar from "../Components/sidebar/Sidebar";
import dashboardItems from "../Components/sidebar/dashboardItems";

export default function RenewBandwidth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const subscriptionId = params.subscriptionId;
  const updatingType = params.updatingType;
  const customPlanInfo = useSelector(selectCustomPlanInfo);
  const typesConfig = useSelector(selectTypesConfig);
  const targetSubscriptionInfo = useSelector(selectTargetSubscriptionInfo);
  const [totalPrice, setTotalPrice] = useState(0);
  const me = useSelector(selectMe);

  // min and max
  const [minBandwidth, setminBandwidth] = useState(0);
  const [maxBandwidth, setmaxBandwidth] = useState(0);
  const [minThreads, setminThreads] = useState(0);
  const [maxThreads, setmaxThreads] = useState(0);
  const [minRenewDays, setminRenewDays] = useState(0);
  const [maxRenewDays, setmaxRenewDays] = useState(0);

  useEffect(() => {
    dispatch(getMe());
    dispatch(getCustomPlanInfo());
    dispatch(getTypeConfig());
    dispatch(getSingleSubscription({ subscriptionId }));
  }, []);

  const getValueFromCustomPlanInfo = (key) => {
    let val = "";
    customPlanInfo?.map((cpi) => {
      if (cpi.name === key) {
        val = cpi.value;
      }
    });

    return val;
  };

  const RegisterSchema = Yup.object().shape({
    coupon: Yup.string(),
    bandwidth: Yup.number().min(minBandwidth).max(maxBandwidth),
    paymentMethod: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      coupon: "",
      bandwidth: "",
      paymentMethod: "cryptocurrency",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);

      let err = false;
      let errsDict = {};

      if (updatingType === "bandwidth") {
        if (values.bandwidth === "") {
          err = true;
          errsDict["bandwidth"] = "Bandwidth value is required";
        }
      }

      setErrors(errsDict);

      if (err === false) {
        setSubmitting(true);
        await dispatch(
          renewSubscription({
            ...values,
            updatingType,
            subscriptionId,
            navigate,
            setErrors,
          })
        );
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setValues,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (
      customPlanInfo &&
      targetSubscriptionInfo &&
      updatingType === "bandwidth"
    ) {
      let bandwidthPrices = [];
      let minBandwidth = 0;
      let maxBandwidth = 0;

      if (targetSubscriptionInfo.plan !== null) {
        bandwidthPrices = targetSubscriptionInfo.plan.bandwidthPrices;
        minBandwidth = Math.min(...bandwidthPrices.map((b) => b.from));
        maxBandwidth = Math.min(...bandwidthPrices.map((b) => b.to));
      } else {
        bandwidthPrices = getValueFromCustomPlanInfo("CustomBandwidthPrices");
        minBandwidth = getValueFromCustomPlanInfo("CustomBandwidthMin");
        maxBandwidth = getValueFromCustomPlanInfo("CustomBandwidthMax");
      }

      setminBandwidth(minBandwidth);
      setmaxBandwidth(maxBandwidth);

      let perGBBandwidthPrice = 0;
      bandwidthPrices.map((tp) => {
        if (tp.from <= values.bandwidth && values.bandwidth <= tp.to) {
          perGBBandwidthPrice = tp.price;
        }
      });

      if (values.bandwidth !== "") {
        setTotalPrice(perGBBandwidthPrice * values.bandwidth);
      } else {
        setTotalPrice(0);
      }
    }
  }, [
    targetSubscriptionInfo,
    customPlanInfo,
    values.bandwidth,
    values.threads,
    values.days,
  ]);

  return (
    <div className="d-flex">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />

        <>
          <Helmet>
            <title>Renew Subscription - KocerRoxy</title>
          </Helmet>

          {/* Content */}
          <div className="page-content">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container p-0 col-lg-8 col-12">
                  <div className="row">
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-primary my-3 mx-2"
                      style={{ fontSize: "1rem", width: "85px" }}
                    >
                      <i className="bi-chevron-left" />
                      Back
                    </button>
                    <div className="py-4">
                      <div className="card p-3">
                        <div className="text-center">
                          <p className="text-secondary">
                            ID {targetSubscriptionInfo?._id}
                          </p>
                          <h1>
                            {targetSubscriptionInfo?.trial && "[Trial] "}
                            {targetSubscriptionInfo?.planName}
                          </h1>
                          <p className="text-secondary">
                            {targetSubscriptionInfo?.bandwidth === 0
                              ? "Unlimited"
                              : `${targetSubscriptionInfo?.bandwidth} GB`}
                          </p>

                          <p className="text-secondary" pt={3} pb={3}>
                            {targetSubscriptionInfo?.planDescription}
                          </p>
                        </div>

                        <div className="pt-4 pb-4">
                          <div className="row">
                            <div className="col-6 text-start">
                              {targetSubscriptionInfo?.bandwidth !== 0
                                ? `${targetSubscriptionInfo?.bandwidthLeft} GB / ${targetSubscriptionInfo?.bandwidth} GB`
                                : "Unlimited"}
                            </div>
                            <div className="col-6 text-end">
                              {targetSubscriptionInfo?.bandwidth !== 0 &&
                                `${
                                  Math.round(
                                    (targetSubscriptionInfo?.bandwidthLeft /
                                      targetSubscriptionInfo?.bandwidth) *
                                      100 *
                                      100
                                  ) / 100
                                }%`}
                            </div>
                          </div>

                          <div
                            className="progress"
                            style={{ height: 6, marginTop: 10 }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width:
                                  targetSubscriptionInfo?.bandwidth !== 0
                                    ? `${
                                        (targetSubscriptionInfo?.bandwidthLeft /
                                          targetSubscriptionInfo?.bandwidth) *
                                        100
                                      }%`
                                    : "100%",
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 text-start">Expiry Date</div>
                          <div className="col-6 text-end">
                            {targetSubscriptionInfo?.renewalDate
                              ? formatTime(targetSubscriptionInfo?.renewalDate)
                              : "-"}
                          </div>
                        </div>

                        <div className="mt-2">
                          <hr />

                          {updatingType === "bandwidth" && (
                            <>
                              {/* Bandwidth */}
                              <div className="mb-4">
                                <label className="form-label">
                                  Bandwidth{" "}
                                  <span className="text-secondary">
                                    (Range:{" "}
                                    {`${minBandwidth} to ${maxBandwidth}`} GB)
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  {...getFieldProps("bandwidth")}
                                  className="form-control form-control-lg"
                                  placeholder="0 GB"
                                />
                                <span
                                  className="invalid-feedback"
                                  style={{
                                    display: Boolean(errors.bandwidth)
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {errors.bandwidth}
                                </span>
                              </div>
                            </>
                          )}

                          {updatingType === "threads" && (
                            <>
                              {/* Threads */}
                              <div className="mb-4">
                                <label className="form-label">
                                  Threads{" "}
                                  <span className="text-secondary">
                                    (Range: {`${minThreads} to ${maxThreads}`}{" "}
                                    Threads)
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  {...getFieldProps("threads")}
                                  className="form-control form-control-lg"
                                  placeholder="Number of threads"
                                />
                                <span
                                  className="invalid-feedback"
                                  style={{
                                    display: Boolean(errors.threads)
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {errors.threads}
                                </span>
                              </div>
                            </>
                          )}

                          {updatingType === "days" && (
                            <>
                              {/* Days */}
                              <div className="mb-4">
                                <label className="form-label">
                                  Days{" "}
                                  <span className="text-secondary">
                                    (Range: {minRenewDays} to {maxRenewDays}{" "}
                                    Days)
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  {...getFieldProps("days")}
                                  className="form-control form-control-lg"
                                  placeholder="Number of days"
                                />
                                <span
                                  className="invalid-feedback"
                                  style={{
                                    display: Boolean(errors.days)
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {errors.days}
                                </span>
                              </div>
                            </>
                          )}

                          {/* coupon */}
                          <div className="mb-4">
                            <label className="form-label">
                              Coupon Code (Optional)
                            </label>
                            <input
                              {...getFieldProps("coupon")}
                              className="form-control form-control-lg"
                              placeholder="Enter coupon code"
                            />
                            <span
                              className="invalid-feedback"
                              style={{
                                display: Boolean(errors.coupon)
                                  ? "block"
                                  : "none",
                              }}
                            >
                              {errors.coupon}
                            </span>
                          </div>

                          <div className="mb-4">
                            <label className="form-label">
                              Payment Gateway
                            </label>
                            <Field
                              name="gender"
                              render={({ field }) => (
                                <>
                                  {me?.cryptoPaymentProcessorEnabled && (
                                    <div className="radio-item mb-2">
                                      <input
                                        {...getFieldProps("paymentMethod")}
                                        className="form-check-input"
                                        value="cryptocurrency"
                                        checked={
                                          values.paymentMethod ===
                                          "cryptocurrency"
                                        }
                                        type="radio"
                                      />

                                      <label
                                        htmlFor="paymentMethod"
                                        className="form-check-label"
                                        style={{ marginLeft: "0.4rem" }}
                                      >
                                        Cryptocurrency
                                      </label>
                                    </div>
                                  )}

                                  {me?.balancePaymentProcessorEnabled && (
                                    <div className="radio-item">
                                      <input
                                        {...getFieldProps("paymentMethod")}
                                        className="form-check-input"
                                        value="balance"
                                        checked={
                                          values.paymentMethod === "balance"
                                        }
                                        type="radio"
                                      />
                                      <label
                                        htmlFor="paymentMethod"
                                        className="form-check-label"
                                        style={{ marginLeft: "0.4rem" }}
                                      >
                                        Balance
                                      </label>
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>

                          {/* Errors from server */}
                          {errors.afterSubmit && (
                            <div className="alert alert-danger" role="alert">
                              {errors.afterSubmit}
                            </div>
                          )}

                          {/* Sign in */}
                          <div className="d-flex align-items-center">
                            <LoadingButton
                              loading={isSubmitting}
                              type="submit"
                              className="btn btn-primary "
                              style={{ minWidth: "10rem", marginRight: "1rem" }}
                            >
                              Pay
                            </LoadingButton>

                            {/* Price */}
                            <div>
                              <h2 className="p-0">${totalPrice}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </>
      </div>
    </div>
  );
}
