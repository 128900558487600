import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./slices/adminSlice";
import generalReducer from "./slices/generalSlice";
import publicReducer from "./slices/publicSlice";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    general: generalReducer,
    public: publicReducer,
  },
});
