import React from "react";
import { Alert } from "react-bootstrap";
import "../Assets/css/dark.css";

const AlertCard = () => {
  return (
    <Alert className="d-flex flex-column text-start m-4 p-3 alert-card">
      <Alert.Heading className="alert-heading">
        If you have doubts about your target site or usage, kindly refer to the
        FAQ from the FAQ tab on the left or ask in live support.
      </Alert.Heading>
      <Alert.Link href="#">https://proxyside.com/faq</Alert.Link>
    </Alert>
  );
};

export default AlertCard;
