/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Badge, Collapse } from "react-bootstrap";

const CustomRouterLink = forwardRef((props, ref) => (
  <React.Fragment ref={ref}>
    <NavLink {...props} />
  </React.Fragment>
));

const SidebarNavListItem = (props) => {
  const { title, href, depth = 0, children, icon: Icon, badge, open: openProp = false } = props;
  let location = useLocation();
  const [open, setOpen] = React.useState(openProp);
  const [selectedMenu, setselectedMenu] = React.useState("Dashboard");

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  useEffect(() => {
    console.log(window.location.href);
    if (window.location.href.includes("/dashboard") == true) {
      setselectedMenu("Dashboard");
    }

    if (window.location.href.includes("/plans") == true) {
      setselectedMenu("Purchase Plan");
    }

    if (window.location.href.includes("/invoices") == true) {
      setselectedMenu("Invoices");
    }

    if (window.location.href.includes("/topup-balance") == true) {
      setselectedMenu("Topup Balance");
    }

    if (window.location.href.includes("/faq") == true) {
      setselectedMenu("FAQ");
    }

    if (window.location.href.includes("/tos") == true) {
      setselectedMenu("TOS");
    }

    if (window.location.href.includes("/suggestion") == true) {
      setselectedMenu("Suggestion");
    }

    if (window.location.href.includes("/admin/settings") == true) {
      setselectedMenu("Settings");
    }

    if (window.location.href.includes("/admin/accounts") == true) {
      setselectedMenu("Accounts");
    }

    if (window.location.href.includes("/admin/plans") == true) {
      setselectedMenu("Plans");
    }

    if (window.location.href.includes("/admin/subscriptions") == true) {
      setselectedMenu("Subscriptions");
    }

    if (window.location.href.includes("/admin/coupons") == true) {
      setselectedMenu("Coupons");
    }

    if (window.location.href.includes("/admin/suggestions") == true) {
      setselectedMenu("Suggestions");
    }
  }, [location]);

  if (children) {
    return (
      <li className={`sidebar-item ${open ? "active" : ""}`}>
        <a
          className={`sidebar-link ${open ? "" : "collapsed"}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? "true" : "false"}
          depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle" depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="" size={18}>
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className="sidebar-item" style={selectedMenu == title ? { backgroundColor: "#204075", borderRadius: "8px" } : {}}>
      <CustomRouterLink depth={depth} to={href} activeclassname="active" className="sidebar-link">
        {Icon && <Icon className="feather align-middle" />}{" "}
        <span className="align-middle" depth={depth}>
          {title}
        </span>
        {badge && (
          <Badge className="badge-sidebar-primary" bg="" size={18}>
            {badge}
          </Badge>
        )}
      </CustomRouterLink>
    </li>
  );
};

export default SidebarNavListItem;
