import React from "react";
import logo from "../Assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter, faYoutube, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

const LandingFooter = () => {
  return (
    <div>
      {" "}
      {/* footer section start */}
      <footer className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-8 col-sm-6 col-12">
              <div className="ftr-logo">
                <a href="index.html">
                  <img src={logo} alt="Logo" className="img-fluid" />
                </a>
                <p>
                  Aliquam hendrerit id sem non aliquam. Sed iaculis velit dui, a feugiat ante rhoncus auctor. Duis nec nulla ac metus malesuada
                  vehicula.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-6">
              <div className="ftr-links">
                <h6>Company</h6>
                <ul>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">Affiliate Program</a>
                  </li>
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ftr-links">
                <h6>Resources</h6>
                <ul>
                  <li>
                    <a href="#">FAQs</a>
                  </li>
                  <li>
                    <a href="#">Documentation</a>
                  </li>
                  <li>
                    <a href="#">Sales</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-8 col-sm-6 col-12">
              <div className="ftr-links">
                <h6>Connect with us</h6>
              </div>
              <div className="ftr-social">
                <ul>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faPaperPlane} />{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ftr-btm-menu">
                <a href="#">Copyright © 2022. ProxySide Ltd. All rights reserved.</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingFooter;
