import React from "react";
import { Route, Routes } from "react-router-dom";
import Accounts from "./Admin/Accounts/Accounts";
import CreateNewAccount from "./Admin/Accounts/CreateNewAccount";
import EditAccount from "./Admin/Accounts/EditAccount";
import EditAccountBalance from "./Admin/Accounts/EditAccountBalance";
import UseCase from "./LandingPages/UseCase";
import ViewAccount from "./Admin/Accounts/ViewAccounts";
import AdminSuggestions from "./Admin/AdminSuggestions";
import Coupons from "./Admin/Coupons/Coupons";
import CreateNewCoupon from "./Admin/Coupons/CreateNewCoupon";
import CreateNewPlan from "./Admin/Plans/CreateNewPlan";
import Plans from "./Admin/Plans/Plans";
import Settings from "./Admin/Settings";
import Statics from "./Admin/Statics";
import Subscriptions from "./Admin/Subscriptions";
import ProcessVerifyEmail from "./Authentication/ProcessVerifyEmail";
import Signin from "./Authentication/Signin";
import Signup from "./Authentication/Signup";
import VerifyEmail from "./Authentication/VerifyEmail";
import Dashboard from "./Dashboard/Dashboard";
import PlanGenerator from "./Dashboard/PlanGenerator";
import Discord from "./Discord";
import Faqs from "./LandingPages/Faqs";
import Homepage from "./LandingPages/Homepage";
import Invoices from "./Invoices";
import MyProfile from "./MyProfile";
import PurchasePlan from "./PurchasePlan";
import Suggestions from "./Suggestions";
import Topup from "./Topup";
import TrialBundle from "./TrialBundle";
import UserPlans from "./UserPlans";
import ContactUs from "./LandingPages/ContactUs";
import Pricing from "./LandingPages/Pricing";
import RenewBandwidth from "./RenewBandwidth";
import ForgotPassword from "./Authentication/ForgotPassword";
import ForgotPassworkLinkSuccess from "./Authentication/ForgotPassworkLinkSuccess";
import Tos from "./Tos";
import EditSubscription from "./Admin/EditSubscription";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import ChangePassword from "./Authentication/ChangePassword";
import PasswordChangedSuccessfully from "./Authentication/PasswordChangedSuccessfully";

const LandingTheme = React.lazy(() => import("../Theme/LandingTheme"));

const MainRoutes = () => {
  const LazyLandingTheme = ({ selectedThemeStr, children }) => {
    // const CHOSEN_THEME = localStorage.getItem("TYPE_OF_THEME") || TYPE_OF_THEME.DEFAULT;
    return (
      <>
        <React.Suspense fallback={<></>}>
          <LandingTheme />
        </React.Suspense>
        {children}
      </>
    );
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <LazyLandingTheme>
            <Homepage />
          </LazyLandingTheme>
        }
      />
      <Route path="/use-case" element={<UseCase />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/faq" element={<Faqs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/check-email" element={<VerifyEmail />} />
      <Route
        path="/verify-email/process/:verifyToken"
        element={<ProcessVerifyEmail />}
      />
      <Route path="/signin" element={<Signin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/forgot-password-link-sent-successfully"
        element={<ForgotPassworkLinkSuccess />}
      />
      <Route
        path="/change-password/:resetPasswordToken"
        element={<ChangePassword />}
      />
      <Route
        path="/password-changed-successfully"
        element={<PasswordChangedSuccessfully />}
      />
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/dashboard/plangenerator/:subscriptionId"
        element={<PlanGenerator />}
      />
      <Route path="/trial-bundle" element={<TrialBundle />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/purchase-plan/:planId" element={<PurchasePlan />} />
      <Route path="/dashboard/payment-success" element={<PaymentSuccess />} />
      <Route path="/dashboard/payment-failed" element={<PaymentFailed />} />
      <Route path="/plans" element={<UserPlans />} />
      <Route
        path="/plans/renew/:updatingType/:subscriptionId"
        element={<RenewBandwidth />}
      />
      <Route path="/topup-balance" element={<Topup />} />
      <Route path="/terms-of-service" element={<Tos />} />
      <Route path="/suggestion" element={<Suggestions />} />
      <Route path="/admin/settings" element={<Settings />} />
      <Route path="/admin/accounts" element={<Accounts />} />
      <Route path="/admin/accounts/new" element={<CreateNewAccount />} />
      <Route path="/admin/accounts/:userId" element={<ViewAccount />} />
      <Route path="/admin/accounts/:userId/edit" element={<EditAccount />} />
      <Route
        path="/admin/accounts/:userId/balance"
        element={<EditAccountBalance />}
      />
      <Route path="/admin/plans" element={<Plans />} />
      <Route path="/admin/plans/new" element={<CreateNewPlan type={"new"} />} />

      <Route
        path="/admin/plans/edit/:planId"
        element={<CreateNewPlan type={"edit"} />}
      />
      <Route path="/admin/subscriptions" element={<Subscriptions />} />
      <Route
        path="/admin/subscriptions/edit/:subscriptionId"
        element={<EditSubscription />}
      />
      <Route path="/admin/statistics" element={<Statics />} />
      <Route path="/admin/coupons" element={<Coupons />} />
      <Route
        path="/admin/coupons/new"
        element={<CreateNewCoupon type={"new"} />}
      />
      <Route
        path="/admin/coupons/edit/:couponId"
        element={<CreateNewCoupon type={"edit"} />}
      />
      <Route path="/admin/suggestions" element={<AdminSuggestions />} />
      <Route path="/discord" element={<Discord />} />
    </Routes>
  );
};

export default MainRoutes;
