import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PaymentLogo } from "../Assets/img/illustrations/oc-money-profits.svg";
import NavbarComponent from "../Components/navbar/Navbar";
import Sidebar from "../Components/sidebar/Sidebar";
import dashboardItems from "../Components/sidebar/dashboardItems";
import AlertCard from "../Components/AlertCard";

function PaymentSuccess() {
  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />
        <>
          <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "70vh" }}>
            <div className="text-center">
              <PaymentLogo style={{ maxHeight: "6rem", marginBottom: "2rem" }} />
              <h1 className="text-success mb-3" style={{ fontSize: "2.5rem" }}>
                Your payment is <br /> successful
              </h1>
              <Link to="/dashboard">
                <button className="btn btn-primary">Go To Dashboard</button>
              </Link>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default PaymentSuccess;
