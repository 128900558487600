import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Card } from "react-bootstrap";
import { ReactComponent as Logo } from "../../Assets/img/Main.svg";
import SignUp from "../../Components/auth/SignUp";

const Signup = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          defer
          async
        ></script>
      </Helmet>
      <Container className="d-flex flex-column justify-content-center align-items-center h-100 text-center">
        {/* <Helmet title="Sign Up" /> */}
        <div className="text-center mt-4">
          <h1 className="h2">Get started</h1>
          <p className="lead">
            Start creating the best possible user experience for you customers.
          </p>
        </div>

        <Card className="col-sm-12 col-md-7 col-lg-5 m-4 p-4 text-start">
          <Card.Body>
            <div className="mx-sm-4 my-sm-2">
              <div className="d-flex flex-column justify-content-center align-items-center card-logo mb-2">
                <Logo />
              </div>
              <SignUp />
            </div>
          </Card.Body>
        </Card>
        <p className="signin-sub-text">
          Already have an account?{" "}
          <Link className="link-to-signup" to="/signin">
            {" "}
            Log in
          </Link>{" "}
        </p>
      </Container>
    </>
  );
};

export default Signup;
