import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingButton from "../../Components/common/LoadingButton";
import { editSubscription, getSingleSubscription, selectSubscriptionToEdit } from "../../redux/slices/adminSlice";
import { formatTime } from "../../utils/formatTime";
import Sidebar from "../../Components/sidebar/Sidebar";
import dashboardItems from "../../Components/sidebar/dashboardItems";
import AlertCard from "../../Components/AlertCard";
import NavbarComponent from "../../Components/navbar/Navbar";

export default function EditSubscription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const subscriptionToEdit = useSelector(selectSubscriptionToEdit);

  useEffect(() => {
    dispatch(
      getSingleSubscription({
        subscriptionId: params.subscriptionId,
      })
    );
  }, []);

  const RegisterSchema = Yup.object().shape({
    duration: Yup.number().required("Duration is required"),
  });

  const formik = useFormik({
    initialValues: {
      duration: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log(values);

      setSubmitting(true);
      await dispatch(editSubscription({ ...values, subscriptionId: params.subscriptionId, navigate }));
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues } = formik;

  useEffect(() => {
    if (subscriptionToEdit) {
      setValues({
        duration: subscriptionToEdit?.duration,
      });
    }
  }, [subscriptionToEdit]);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />
        <>
          {/* Content */}
          <div className="page-content">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="container-lg">
                  {/* ID */}
                  <div className="mb-4">
                    <label className="form-label">ID</label>
                    <input className="form-control form-control-lg" placeholder="ID" value={subscriptionToEdit?._id} disabled />
                  </div>

                  {/* Duration */}
                  <div className="col-3">
                    <label className="form-label">Duration (sec)</label>

                    <div className=" mb-4 d-flex align-items-center">
                      <div style={{ marginTop: "0.1rem", marginRight: "1rem" }}>
                        <i
                          class="bi bi-dash-square-fill text-primary"
                          style={{ fontSize: "2rem", cursor: "pointer" }}
                          onClick={() => {
                            formik.setFieldValue("duration", formik.values.duration - 3600);
                          }}
                        ></i>
                      </div>
                      <div>
                        <input {...getFieldProps("duration")} className="form-control form-control-lg" placeholder="Enter Duration" />
                        <span className="invalid-feedback" style={{ display: Boolean(touched.duration && errors.duration) ? "block" : "none" }}>
                          {errors.duration}
                        </span>
                      </div>
                      <div style={{ marginTop: "0.1rem", marginLeft: "1rem" }}>
                        <i
                          class="bi bi-plus-square-fill text-primary"
                          style={{ fontSize: "2rem", cursor: "pointer" }}
                          onClick={() => {
                            formik.setFieldValue("duration", formik.values.duration + 3600);
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>

                  {/* Created At */}
                  <div className="mb-4">
                    <label className="form-label">Created At</label>
                    <input className="form-control form-control-lg" placeholder="ID" value={formatTime(subscriptionToEdit?.createdAt)} disabled />
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  {/* Sign in */}
                  <div>
                    <LoadingButton loading={isSubmitting} type="submit" className="btn btn-primary " style={{ minWidth: "5rem" }}>
                      Save
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
          {/* End Content */}
        </>
      </div>
    </div>
  );
}
