import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteCoupon,
  getAllCoupons,
  selectCoupons,
} from "../../../redux/slices/adminSlice";
import { formatTime } from "../../../utils/formatTime";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet-async";
import AlertCard from "../../../Components/AlertCard";
import NavbarComponent from "../../../Components/navbar/Navbar";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";

export default function Coupons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCoupons = useSelector(selectCoupons);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [couponToDelete, setCouponToDelete] = useState(0);

  useEffect(() => {
    dispatch(getAllCoupons({ page: 1 }));
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <AlertCard />
        <>
          <Helmet>
            <title>Admin Coupons</title>
          </Helmet>

          {/* Content */}
          <div className="page-content px-4">
            <div className="container p-0">
              <div class="card">
                <div
                  className="col-12 d-flex align-items-center justify-content-between"
                  style={{ padding: "1.5rem", paddingBottom: 16 }}
                >
                  <div className="col-6 d-flex align-items-center">
                    <h1 style={{ marginTop: 8 }}>All Coupon</h1>
                  </div>

                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/admin/coupons/new");
                    }}
                  >
                    Create New Coupon
                  </button>
                </div>

                <div className="table-responsive datatable-custom">
                  <table
                    id="datatable"
                    className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Code</th>
                        <th>Discount (%)</th>
                        <th>Usages</th>
                        <th>Times Used</th>
                        <th>Payment Processor</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allCoupons?.map((coupon) => {
                        return (
                          <tr>
                            <td>{coupon.code}</td>
                            <td>{coupon.discount}</td>
                            <td>{coupon.usages}</td>
                            <td>{coupon.timesUsed}</td>
                            <td>{coupon.paymentProcessor}</td>
                            <td>{formatTime(coupon.createdAt)}</td>
                            <td>{formatTime(coupon.updatedAt)}</td>

                            <td>
                              <i
                                data-tip
                                data-for="viewBtn"
                                class="bi bi-eye-fill icon-button text-primary"
                                style={{
                                  marginRight: "0.7rem",
                                  cursor: "pointer",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={() => {
                                  setSelectedCoupon(coupon);
                                }}
                              ></i>
                              <ReactTooltip
                                id="viewBtn"
                                effect="float"
                                delayShow={400}
                              >
                                <span>View</span>
                              </ReactTooltip>

                              <i
                                data-tip
                                data-for="editBtn"
                                style={{
                                  marginRight: "0.7rem",
                                  cursor: "pointer",
                                }}
                                class="bi bi-pencil-fill icon-button text-warning"
                                onClick={() => {
                                  navigate(`/admin/coupons/edit/${coupon._id}`);
                                }}
                              ></i>
                              <ReactTooltip
                                id="editBtn"
                                effect="float"
                                delayShow={400}
                              >
                                <span>Edit</span>
                              </ReactTooltip>

                              <i
                                data-tip
                                data-for="deleteBtn"
                                style={{
                                  marginRight: "0.7rem",
                                  cursor: "pointer",
                                }}
                                class="bi bi-trash-fill icon-button text-danger"
                                data-bs-toggle="modal"
                                data-bs-target="#deletePlanModal"
                                onClick={() => {
                                  setCouponToDelete(coupon);
                                }}
                              ></i>
                              <ReactTooltip
                                id="deleteBtn"
                                effect="float"
                                delayShow={400}
                              >
                                <span>Delete</span>
                              </ReactTooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* End Content */}

          {/* View Plan Modal */}
          <div>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="staticBackdropLabel">
                      {selectedCoupon?.code}
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>Code: </span>{" "}
                      {selectedCoupon?.code}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Discount: </span>{" "}
                      {selectedCoupon?.discount}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Usages: </span>{" "}
                      {selectedCoupon?.usages}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Used: </span>{" "}
                      {selectedCoupon?.timesUsed}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Payment Processor:{" "}
                      </span>{" "}
                      {selectedCoupon?.paymentProcessor !== ""
                        ? selectedCoupon?.paymentProcessor
                        : "-"}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Create on: </span>{" "}
                      {formatTime(selectedCoupon?.createdAt)}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Modified on: </span>{" "}
                      {formatTime(selectedCoupon?.updatedAt)}
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Delete Plan Modal */}
          <div>
            <div
              className="modal fade"
              id="deletePlanModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3
                      className="modal-title text-danger"
                      id="staticBackdropLabel"
                    >
                      You are permanently deleting this coupon
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <p>
                      <span style={{ fontWeight: "bold" }}>Code: </span>{" "}
                      {couponToDelete?.code}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Discount: </span>{" "}
                      {couponToDelete?.discount}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Usages: </span>{" "}
                      {couponToDelete?.usages}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Used: </span>{" "}
                      {couponToDelete?.timesUsed}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Payment Processor:{" "}
                      </span>{" "}
                      {couponToDelete?.paymentProcessor !== ""
                        ? couponToDelete?.paymentProcessor
                        : "-"}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Create on: </span>{" "}
                      {formatTime(couponToDelete?.createdAt)}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Modified on: </span>{" "}
                      {formatTime(couponToDelete?.updatedAt)}
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        dispatch(
                          deleteCoupon({ couponId: couponToDelete._id })
                        );
                      }}
                    >
                      Delete This Coupon
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
