import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { backendServerBaseURL } from "../utils/backendServerBaseURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import arrow from "../Assets/images/arrow-up-icon.svg";
import LandingFooter from "../Components/LandingFooter";
import LandingNavbar from "../Components/LandingNavbar";

const Tos = () => {
  const [tos, setTos] = useState([]);

  useEffect(() => {
    const getTos = () => {
      axios
        .get(`${backendServerBaseURL}/landing`)
        .then((data) => {
          setTos(data.data.payload.TOS);
        })
        .catch((error) => console.log(error));
    };

    getTos();
  }, []);
  console.log(tos);
  return (
    <div>
      <LandingNavbar />
      {/* contact section start */}
      <section className="contact-section pa-y4">
        <div className="container">
          {/* common heading start */}
          <div dangerouslySetInnerHTML={{ __html: tos }}></div>
        </div>
      </section>
      {/* contact section end */}
      {/* get start section start */}
      <section className="get-start py-4 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 col-sm-11 col-12">
              <div className="get-start-bg">
                <div className="common-heading mb-0">
                  <h2>Got Questions? Let’s Talk</h2>
                  <p>
                    In eu mollis nibh. Donec efficitur nunc purus, in vehicula
                    odio ultricies eget. Praesent laoreet libero ac lorem dictum
                    viverra. Donec porta lectus mi, sed maximus orci pulvinar
                    vel.
                  </p>
                  <Link to="/contact-us">
                    Contact Us <img src={arrow} alt="a" className="img-fluid" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get start section end */}
      <LandingFooter />
    </div>
  );
};

export default Tos;
