import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";

const initialState = {
  me: null,
  typesConfig: null,
  announcements: null,
};

// Get Me
export const getMe = createAsyncThunk("general/getMe", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/auth/me`, config);

  if (response.status === 200 && response.data.message === "Me") {
    thunkAPI.dispatch(updateMe(response.data.payload.user));
  }
});

// Types config
export const getTypeConfig = createAsyncThunk("admin/getTypeConfig", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/admin/types-config`, config);

  if (response.status === 200 && response.data.message === "Types config") {
    thunkAPI.dispatch(updateTypesConfig(response.data.payload));
  }
});

// Announcements
export const getAnnouncements = createAsyncThunk("admin/getAnnouncements", async (_, thunkAPI) => {
  const token = await localStorage.getItem("accessToken", "");

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {},
  };

  let response = await axios.get(`${backendServerBaseURL}/public/announcements`, config);

  if (response.status === 200 && response.data.message === "Announcements") {
    thunkAPI.dispatch(updateAnnouncements(response.data.payload.announcements));
  }
});

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    updateMe: (state, action) => {
      state.me = action.payload;
    },
    updateTypesConfig: (state, action) => {
      state.typesConfig = action.payload;
    },
    updateAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
  },
});

export const { updateMe, updateTypesConfig, updateAnnouncements } = generalSlice.actions;

export const selectMe = (state) => state.general.me;
export const selectTypesConfig = (state) => state.general.typesConfig;
export const selectAnnouncements = (state) => state.general.announcements;

export default generalSlice.reducer;
