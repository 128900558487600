import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  disable2FA,
  getSingleAccount,
  selectViewAccountInfo,
} from "../../../redux/slices/adminSlice";
import moment from "moment";
import Sidebar from "../../../Components/sidebar/Sidebar";
import dashboardItems from "../../../Components/sidebar/dashboardItems";
import NavbarComponent from "../../../Components/navbar/Navbar";
import ReactTooltip from "react-tooltip";
import { getMe, selectMe } from "../../../redux/slices/generalSlice";
import DeleteAccountDialog from "./DeleteAccountDialog";

export default function ViewAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const selectAccountInfo = useSelector(selectViewAccountInfo);
  const me = useSelector(selectMe);
  const [accountToDelete, setaccountToDelete] = useState(0);

  useEffect(() => {
    dispatch(getSingleAccount({ userId: params.userId }));
    dispatch(getMe());
  }, []);

  return (
    <div className="d-flex ">
      <Sidebar items={dashboardItems} />
      <div className="w-100">
        <NavbarComponent />
        <div className="px-4">
          <DeleteAccountDialog accountToDelete={accountToDelete} />
          <div className="row">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-primary m-2"
              style={{ fontSize: "1rem", width: "85px" }}
            >
              <i className="bi-chevron-left" />
              Back
            </button>
          </div>
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title mb-3 mt-3">
                  View Account Info
                </h1>
              </div>
            </div>
            {/* End Row */}
          </div>
          {/* End Page Header */}

          {/* Content */}
          <div className="page-content">
            <div className="container-lg p-0 m-0">
              <div className="row mb-4">
                <div className="col-lg-4 col-12 mb-3">
                  <div className="card p-4">
                    <div className="d-flex mb-3">
                      <h2 className="text-primary mb-0">Account Info</h2>
                    </div>

                    <p>
                      <span style={{ fontWeight: "bold" }}>ID: </span>{" "}
                      {selectAccountInfo?._id}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>Username: </span>{" "}
                      {selectAccountInfo?.username}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>Email: </span>{" "}
                      {selectAccountInfo?.email}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>Verified: </span>
                      {selectAccountInfo?.verified ? (
                        <span
                          className="legend-indicator bg-success"
                          style={{ marginLeft: "0.3rem" }}
                        />
                      ) : (
                        <span className="legend-indicator bg-secondary" />
                      )}
                      {selectAccountInfo?.verified
                        ? "Verified"
                        : "Not Verified"}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>Verify Token: </span>{" "}
                      {selectAccountInfo?.verifyToken
                        ? selectAccountInfo?.verifyToken
                        : "None"}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>Discord: </span>{" "}
                      {selectAccountInfo?.discord
                        ? selectAccountInfo?.discord
                        : "None"}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Balance: </span>{" "}
                      <span className="text-success">
                        ${Math.round(selectAccountInfo?.balance * 100) / 100}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Total Spent: </span>{" "}
                      <span className="text-success">
                        $
                        {Math.round(selectAccountInfo?.totalAmountSpent * 100) /
                          100}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Role: </span>{" "}
                      {selectAccountInfo?.role === "Administrator" && (
                        <>
                          <span
                            className="legend-indicator bg-success"
                            style={{ marginLeft: "0.3rem" }}
                          />{" "}
                          <span className="text-success">Administrator</span>
                        </>
                      )}
                      {selectAccountInfo?.role === "Manager" && (
                        <>
                          <span
                            className="legend-indicator bg-info"
                            style={{ marginLeft: "0.3rem" }}
                          />{" "}
                          <span className="text-info">Manager</span>
                        </>
                      )}
                      {selectAccountInfo?.role === "User" && (
                        <>
                          <span
                            className="legend-indicator bg-primary"
                            style={{ marginLeft: "0.3rem" }}
                          />{" "}
                          <span className="text-primary">User</span>
                        </>
                      )}
                      {/* {selectAccountInfo?.manager ? <span className="legend-indicator bg-success" style={{ marginLeft: "0.3rem" }} /> : <span className="legend-indicator bg-primary" />}
                  {selectAccountInfo?.manager ? "Manager" : "User"} */}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>2FA: </span>
                      {selectAccountInfo?.twoFactorEnabled ? (
                        <span
                          className="legend-indicator bg-success"
                          style={{ marginLeft: "0.3rem" }}
                        />
                      ) : (
                        <span
                          className="legend-indicator bg-secondary"
                          style={{ marginLeft: "0.3rem" }}
                        />
                      )}
                      {selectAccountInfo?.twoFactorEnabled
                        ? "Enabled"
                        : "Disabled"}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Register IP: </span>{" "}
                      {selectAccountInfo?.registerIP
                        ? selectAccountInfo?.registerIP
                        : "-"}
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}>
                        Payment Processors:{" "}
                      </span>{" "}
                      <br />
                      Crypto Payments:{" "}
                      {selectAccountInfo?.cryptoPaymentProcessorEnabled ? (
                        <span
                          className="legend-indicator bg-success"
                          style={{ marginLeft: "0.3rem" }}
                        />
                      ) : (
                        <span className="legend-indicator bg-secondary" />
                      )}
                      {selectAccountInfo?.cryptoPaymentProcessorEnabled
                        ? "Enabled"
                        : "Disabled"}
                      <br />
                      Balance Payments:{" "}
                      {selectAccountInfo?.balancePaymentProcessorEnabled ? (
                        <span
                          className="legend-indicator bg-success"
                          style={{ marginLeft: "0.3rem" }}
                        />
                      ) : (
                        <span className="legend-indicator bg-secondary" />
                      )}
                      {selectAccountInfo?.balancePaymentProcessorEnabled
                        ? "Enabled"
                        : "Disabled"}
                    </p>
                  </div>

                  <div>
                    <div className="mt-3">
                      <button
                        className="btn btn-primary m-2"
                        style={{ fontSize: "1rem" }}
                        onClick={() => {
                          navigate(
                            `/admin/subscriptions?search=${selectAccountInfo.username}`
                          );
                        }}
                      >
                        <i
                          style={{
                            color: "var(--bs-white)",
                            marginRight: "0.3rem",
                            paddingTop: "0.5rem",
                          }}
                          class="bi bi-list-ul"
                        ></i>
                        <b>Subscriptions</b>
                      </button>

                      <button
                        className="btn btn-success m-2"
                        style={{ fontSize: "1rem" }}
                        onClick={() => {
                          navigate(
                            `/admin/accounts/${selectAccountInfo._id}/balance`
                          );
                        }}
                      >
                        <i
                          style={{
                            color: "var(--bs-white)",
                            marginRight: "0.3rem",
                            paddingTop: "0.5rem",
                          }}
                          class="bi bi-currency-exchange"
                        ></i>
                        <b>Balance</b>
                      </button>

                      <button
                        className="btn m-2"
                        style={{
                          fontSize: "1rem",
                          backgroundColor: "var(--bs-yellow)",
                        }}
                        onClick={() => {
                          navigate(
                            `/admin/accounts/${selectAccountInfo._id}/edit`
                          );
                        }}
                      >
                        <i
                          style={{
                            color: "var(--bs-white)",
                            marginRight: "0.3rem",
                            paddingTop: "0.5rem",
                          }}
                          class="bi bi-pencil-fill"
                        ></i>
                        <b className="text-white">Edit</b>
                      </button>

                      {selectAccountInfo?.twoFactorEnabled && (
                        <button
                          className="btn btn-outline-danger m-2"
                          style={{ fontSize: "1rem" }}
                          onClick={() => {
                            dispatch(
                              disable2FA({ userId: selectAccountInfo._id })
                            );
                          }}
                        >
                          <i
                            style={{
                              color: "var(--bs-danger)",
                              marginRight: "0.3rem",
                              paddingTop: "0.5rem",
                            }}
                            class="bi bi-slash-circle"
                          ></i>
                          <b>Disable 2FA</b>
                        </button>
                      )}

                      <button
                        className="btn btn-danger m-2"
                        style={{ fontSize: "1rem" }}
                        data-bs-toggle="modal"
                        data-bs-target="#deletePlanModal"
                        onClick={() => {
                          setaccountToDelete(selectAccountInfo);
                        }}
                      >
                        <i
                          style={{
                            color: "var(--bs-white)",
                            marginRight: "0.3rem",
                            paddingTop: "0.5rem",
                          }}
                          class="bi bi-list-ul"
                        ></i>
                        <b>Delete</b>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-12">
                  <div className="card p-4">
                    <h2 className="text-primary mb-3">Active Subscriptions</h2>

                    <table
                      id="datatable"
                      className="table table-align-middle card-table"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Plan Name</th>
                          <th>Status</th>
                          <th>Bandwidth</th>
                          <th>Payment Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectAccountInfo?.subscriptions?.map(
                          (subscription, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{subscription.planName}</td>
                                <td>
                                  {/* Toptup */}
                                  {subscription.topup === true && (
                                    <>
                                      {subscription.paid && (
                                        <span
                                          style={{ color: "var(--bs-green)" }}
                                        >
                                          Paid
                                        </span>
                                      )}
                                      {!subscription.paid &&
                                        !subscription.pending && (
                                          <span
                                            style={{
                                              color: "var(--bs-danger)",
                                            }}
                                          >
                                            Unpaid
                                          </span>
                                        )}
                                      {!subscription.paid &&
                                        subscription.pending && (
                                          <span
                                            style={{ color: "var(--bs-info)" }}
                                          >
                                            Pending
                                          </span>
                                        )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {subscription.topup === false
                                    ? `${
                                        subscription.bandwidth === 0
                                          ? "Unlimited"
                                          : `${subscription.bandwidthLeft} GB / ${subscription.bandwidth} GB`
                                      }`
                                    : "-"}{" "}
                                </td>
                                <td>
                                  {subscription.paid ? (
                                    <span className="text-success">Paid</span>
                                  ) : (
                                    <span className="text-danger">Unpaid</span>
                                  )}
                                </td>
                                <td>
                                  <i
                                    data-tip
                                    data-for="viewBtn"
                                    class="bi bi-eye-fill icon-button text-primary"
                                    style={{ marginLeft: 0 }}
                                    onClick={() => {
                                      navigate(
                                        `/admin/subscriptions?search=${subscription.user.username}`
                                      );
                                    }}
                                  ></i>
                                  <ReactTooltip
                                    id="viewBtn"
                                    effect="float"
                                    delayShow={400}
                                  >
                                    <span>View</span>
                                  </ReactTooltip>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>

                  {false && (
                    <div className="card p-4 mt-3">
                      <h2 className="text-primary mb-3">
                        Expired Subscriptions
                      </h2>

                      <table
                        id="datatable"
                        className="table table-align-middle card-table"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>#</th>
                            <th>Plan Name</th>
                            <th>Status</th>
                            <th>Bandwidth</th>
                            <th>Payment Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectAccountInfo?.expiredSubscriptions?.map(
                            (subscription, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{subscription.planName}</td>
                                  <td>
                                    {/* Toptup */}
                                    {subscription.topup === true && (
                                      <>
                                        {subscription.paid && (
                                          <span
                                            style={{ color: "var(--bs-green)" }}
                                          >
                                            Paid
                                          </span>
                                        )}
                                        {!subscription.paid &&
                                          !subscription.pending && (
                                            <span
                                              style={{
                                                color: "var(--bs-danger)",
                                              }}
                                            >
                                              Unpaid
                                            </span>
                                          )}
                                        {!subscription.paid &&
                                          subscription.pending && (
                                            <span
                                              style={{
                                                color: "var(--bs-info)",
                                              }}
                                            >
                                              Pending
                                            </span>
                                          )}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {subscription.topup === false
                                      ? `${
                                          subscription.bandwidth === 0
                                            ? "Unlimited"
                                            : `${subscription.bandwidthLeft} GB / ${subscription.bandwidth} GB`
                                        }`
                                      : "-"}{" "}
                                  </td>
                                  <td>
                                    {subscription.paid ? (
                                      <span className="text-success">Paid</span>
                                    ) : (
                                      <span className="text-danger">
                                        Unpaid
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <i
                                      data-tip
                                      data-for="viewBtn"
                                      class="bi bi-eye-fill icon-button text-primary"
                                      style={{ marginLeft: 0 }}
                                      onClick={() => {
                                        navigate(
                                          `/admin/subscriptions?search=${subscription.user.username}`
                                        );
                                      }}
                                    ></i>
                                    <ReactTooltip
                                      id="viewBtn"
                                      effect="float"
                                      delayShow={400}
                                    >
                                      <span>View</span>
                                    </ReactTooltip>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End Content */}
        </div>
      </div>
    </div>
  );
}
