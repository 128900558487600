import React, { useState, useEffect } from "react";

const Time = () => {
  const [currentTime, setcurrentTime] = useState("");

  const formatTime = (time) => {
    let date = formatDate(new Date(time));

    let hours = new Date(time).getHours();

    if (hours.toString().length == 1) {
      hours = "0" + hours.toString();
    }

    hours = hours.toString();

    let minutes = new Date(time).getMinutes();

    if (minutes.toString().length == 1) {
      minutes = "0" + minutes.toString();
    }

    minutes = minutes.toString();

    return date + " " + hours + ":" + minutes;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const interval = setInterval(function () {
      setcurrentTime(formatTime(new Date()));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        marginRight: "1.5rem",
        backgroundColor: "#027EF81A",
      }}
      className="d-none d-lg-block rounded p-2"
    >
      {/* <img
        src="/static/svg/Date.svg"
        alt="Date"
        style={{ marginRight: "0.3rem" }}
      /> */}

      {currentTime?.length === 0 ? formatTime(new Date()) : currentTime}
    </div>
  );
};

export default Time;
